import React, { useState, useContext, useEffect } from "react"

import OrderBuyGenerate from './OrderBuyGenerate.js'

import UserSideBar from '../../business/UserSideBar.js'
import Footer from '../../business/Footer';
import OnboardingHook from '../onboarding/OnboardingHook.js'
import RibbonStats from '../fields/RibbonStats.js'

import OrderConfirm from './OrderConfirm.js'

import DFINDissclaimer from './DFINDissclaimer.js'

import { useParams } from 'react-router-dom';

import ProfileContext from "../../context/Profile/ProfileContext.js";

import usddollar from './../../images/usddollar.png';

const OperateBuy = () => {

    let { source } = useParams();

    const {userProfile} = useContext(ProfileContext)
    const [open, setOpen] = useState(false)

    const [step, setstep] = useState(1);
    const nextStep = () => {
        setstep(step + 1);
    }
    
      // function for going to previous step by decreasing step state by 1
      const prevStep = () => {
        setstep(step - 1);
      };

    const [context, setContext] = useState(null)
    const [operationId, setOperationId] = useState(null)
    const [responsehook, setResponsehook] = useState(null)

    
     switch (step) {
        case 1:
        return (

            <div className="relative min-h-screen">
            <div className="relative pt-14 min-h-screen">
                <header className="absolute inset-x-0 top-0 z-50">
                    <UserSideBar  open={open} setOpen={setOpen} />
                </header>
                


                {userProfile?.has_account === false ?

                    <OnboardingHook />

                    :


                    <RibbonStats pp={userProfile?.pp} />
                }
            <div className="min-h-full p-5 text-center lg:justify-center lg:p-5">

                    <OrderBuyGenerate
                        context={context}
                        source={source}

                        setContext={setContext}
                        setResponsehook={setResponsehook}
                        setOperationId={setOperationId}
                        nextStep={nextStep}
                    >
                    </OrderBuyGenerate>

            </div>

            </div>
            <DFINDissclaimer></DFINDissclaimer>
            <Footer logedIn={true} />
        </div>
        )

        case 2:
        return (

            <div className="relative min-h-screen">
            <div className="relative pt-14 min-h-screen">
                <header className="absolute inset-x-0 top-0 z-50">
                    <UserSideBar  open={open} setOpen={setOpen} />
                </header>
                
            <div className="min-h-full px-5 mt-12 text-center lg:justify-center ">
                <div className="mx-auto max-w-xl md:px-2 text-left ">


                {context &&
                    <div className="my-5 flex flex-col" >
                    <img src={usddollar} className="mx-auto" style={{maxWidth:"200px"}}/> 
    
                        <div style={{ color: context.COLOR }}>{context.MESSAGE}</div>
                    </div>        
                }


                    <OrderConfirm
                        context={context}
                        operationId={operationId}
                        side={false}
                        prevStep={prevStep}
                    ></OrderConfirm>
                </div>
            </div>

        </div>

        <DFINDissclaimer></DFINDissclaimer>
        <Footer logedIn={true} />
        </div>
        )
    }

}

export default OperateBuy
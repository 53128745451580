import React, {useEffect, useState} from "react";
import axios from "axios";
import {
    BrowserRouter as Router,
    useParams,
    Link
  } from "react-router-dom";

import Logo from '../business/Logo'

import AccessInput from './fields/AccessInput';
import AccessButton from './fields/AccessButton';


export default function Reset() {

    let { resetToken } = useParams();

    const [loginForm, setloginForm] = useState({
      email: "",
      password: ""
    })
    
    const [processing, setProcessing] = useState(false)
    const [message, setMessage] = useState('')

    const resetPassword = () => {

        axios({
          method: "POST",
          url:"/broker/api/reset_validation/"+resetToken,
          data:{
            "password":loginForm.password
          }
        })
        .then((response) => {
          if (response.data?.hook == "user_password_updated") {
            window.location.replace('/useraccount/acceso');
          }
          setMessage(response.data?.msg)
        }).catch((error) => {
          if (error.response) {
            setMessage(error.response?.data?.msg)
            }
        })
      
      }

      //useEffect(()=>resetPassword(),[])

      function handleChange(event) {
        setMessage('') 
        const {value, name} = event.target
        setloginForm(prevNote => ({
            ...prevNote, [name]: value})
        )}

    return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 lg:px-8 py-5 md:py-12 ">
    <div className="sm:mx-auto sm:w-full sm:max-w-sm  content-center">

      <div className="sm:mx-auto sm:w-full sm:max-w-sm  content-center">
        <Link to="/useraccount/">
          <Logo classLogo="text-2xl flex items-center"/>
        </Link>
      </div>
      
      <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
        Establecer una nueva contraseña
      </h2>
    </div>

      {message ?
          <div className="sm:mx-auto sm:w-full sm:max-w-sm  content-center">
            <p className="text-red-500" data-testid="reset.context">{message}</p>
          </div>
          :
          <div data-testid="reset.context.remove"></div>
      }

    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">

        <div>
          <div className="flex items-center justify-between">
            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
              Contraseña
            </label>
            <div className="text-sm">

            <Link to="/useraccount/reestablecer_acceso">
              <div className="font-semibold tefxt-emerald-600 hover:text-green-500">
                ¿Olvidaste tu contraseña?
              </div>
            </Link>
  
            </div>
          </div>
          <div className="mt-2">
            <AccessInput
              tag={'password'}
              text={loginForm.password} 
              ftype={'password'}
              fieldaction={handleChange} 
              processing={processing}
            />
            
          </div>
        </div>


        <div>
                  <AccessButton
                    text={'Resetear contraseña'}
                    fieldaction={resetPassword}
                    processing={processing}
                    >
                  </AccessButton>
              </div>
      
      <p className="mt-10 text-center text-sm text-gray-500">
        ¿Ya estás registrado?{' '}
          <Link to="/useraccount/acceso">
            <span className="font-semibold leading-6 text-emerald-600 hover:text-green-500">
              Acceder
            </span>
          </Link>
      <br></br>
        ¿No estás registrado?{' '}
          <Link to="/useraccount/registro">
            <div className="font-semibold leading-6 text-emerald-600 hover:text-green-500">
              Registrate ahora
            </div>
          </Link>
      </p>
    </div>
  </div>)

}
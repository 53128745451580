import { useState, useEffect } from 'react'
import { RadioGroup } from '@headlessui/react'

import { Transition } from '@headlessui/react'


import step1 from './../../images/step1.jpeg'
import step2 from './../../images/step2.jpeg'
import step3 from './../../images/step3.jpeg'
import step4 from './../../images/step4.jpeg'

const flows = [
  {
    head: '1| Crea tu cuenta',
    step: '',
    explain: 'Registrate y crea tu Cuenta Comitente CNV.',
    note: '',
    img: step1,
    tab: 1,
  },
  {
    head: '2| Acreditá fondos',
    step: '',
    explain: 'Ingresa tus fondos por transferencia bancaria.',
    note: '',
    img: step2,
    tab: 2,
  },
  {
    head: '3| Comprá tus dólares',
    step: '',
    explain: 'Cargá la orden siguiendo los precios estimativos.',
    note: '',
    img: step3,
    tab: 3,
  },
  {
    head: '4| Retirá tus dólares',
    step: '',
    explain: 'Cuando están los dólares acreditados, pedís el retiro a tu Banco.',
    note: '',
    img: step4,
    tab: 4,
  }
]

export default function ShowWeb() {
  const [selected, setSelected] = useState(flows[0])
  const [imgOB, setImgOB] = useState(step1)
  const heightFix = () => { return '500px'}
  useEffect(()=> {
    setImgOB(selected.img)
  },[selected])

 

  return (
    <div>
      <div className='mx-auto  text-center '>
        <h1 className="my-4 mx-auto text-4xl leading-none tracking-tight text-gray-900 md:text-4xl lg:text-4xl ">Seguí estos <span className="text-green-600">simples pasos</span></h1>
      </div>
      <div className="w-full px-4 py-16 ">
          <div className="grid md:grid-cols-2 gap-2">
              <div className="ml-auto md:w-full/2 max-w-md pt-5">
                  <RadioGroup value={selected} onChange={setSelected}>
                  <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
                  <div className="space-y-2">
                      {flows.map((flow) => (
                      <RadioGroup.Option
                          key={flow.head}
                          value={flow}
                          className={({ active, checked }) =>
                          `${
                              active
                              ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-emerald-300'
                              : ''
                          }
                          ${
                              checked ? 'bg-emerald-900 bg-opacity-75 text-white' : 'bg-white'
                          }
                              relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
                          }
                      >
                          {({ active, checked }) => (
                          <>
                              <div className="flex w-full items-center justify-between">
                              <div className="flex items-center">
                                  <div className="text-sm">
                                  <RadioGroup.Label
                                      as="p"
                                      className={`font-medium  ${
                                      checked ? 'text-white' : 'text-lime-900'
                                      }`}
                                  >
                                      {flow.head}
                                  </RadioGroup.Label>
                                  <RadioGroup.Description
                                      as="span"
                                      className={`inline ${
                                      checked ? 'text-emerald-100' : 'text-lime-500'
                                      }`}
                                  >
                                      <span>
                                      {flow.step}/{flow.explain}
                                      </span>{' '}
                                      <span aria-hidden="true">&middot;</span>{' '}
                                      <span>{flow.note}</span>
                                  </RadioGroup.Description>
                                  </div>
                              </div>
                              {checked && (
                                  <div className="shrink-0 text-white">
                                  <CheckIcon className="h-6 w-6" />
                                  </div>
                              )}
                              </div>
                          </>
                          )}
                      </RadioGroup.Option>
                      ))}
                  </div>
                  </RadioGroup>
              </div>
              <div className=" md:w-full/2 max-w-md px-10 pt-5">
              <div className="mx-auto" style={{maxWidth:"200px"}}>
                  {flows.map(flow => {
                      return(
                          <Transition
                              key={flow.step  }
                              show={imgOB == flow.img}
                              appear={false}
                              //className="w-full"
                              enter="transition ease-in-out duration-300 transform"
                              enterFrom="opacity-0 translate-y-16"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in-out duration-300 transform absolute"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 -translate-y-16"
                              //beforeEnter={() => heightFix()}
                              unmount={false}                     
                          >
                              <img src={imgOB} className="rounded mx-auto border-solid border-2 border-green-600" style={{maxWidth:"200px"}}></img>
                          </Transition>)
                  })}
                  </div>
              </div>
          </div>
      </div>
    </div>
  )
}

function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

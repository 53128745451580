import React, { useState, useContext, useEffect } from "react"
import axios from "axios";
import UserSideBar from './../../business/UserSideBar.js'
import OnboardingHook from './../onboarding/OnboardingHook.js'
import OrdersContext from "./../../context/Orders/OrdersContext.js";
import ProfileContext from "./../../context/Profile/ProfileContext.js";
import YourOrdersMep from './YourOrdersMep.js'
import YourOrdersRaw from './YourOrdersRaw.js'

const YourOrders = () => {
  
  const {userProfile} = useContext(ProfileContext)

  const {mepOrders, opOrders} = useContext(OrdersContext)
  
  const [open, setOpen] = useState(false)

  const [toggle, setToggle] = useState(true)

    return (

          <div className="relative pt-14 min-h-screenlg:px-8">
            <header className="absolute inset-x-0 top-0 z-50">
              <UserSideBar open={open} setOpen={setOpen}/>
            </header>

            {userProfile?.has_account === false &&
              <OnboardingHook />
            }
            
            <div className="w-full h-full">
              <div className="container  mx-auto px-4 mt-5">
              <div className='mx-auto flex m-15 px-5 flex-col'>
              <h2 className="font-semibold text-green-700">Operaciones de Compra y Venta de Dólar </h2>

              {toggle || 1 === 1 ? 
              <>
                { 0 == 1
                  && 
                  <>
                    <div className="flex">
                      <button onClick={()=>setToggle(true)} className="mr-3  flex mt-10 block rounded-md bg-emerald-600 hover:bg-green-400 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        Operaciones - Compra Dólar
                      </button>

                      <button onClick={()=>setToggle(false)} className="flex mt-10 block rounded-md bg-gray-600 hover:bg-gray-400} px-3 py-2 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        Operaciones - Detalle
                      </button>
                    </div>
                  </>

                }

                <YourOrdersMep mepOrders={mepOrders} ></YourOrdersMep>
              
              </>
              
              :
              <>
              
                <YourOrdersRaw opOrders={opOrders}></YourOrdersRaw>
                </>


              }
       
            </div>

            </div>

        </div>
      </div>

    )
}

export default YourOrders
import React, { useState, useReducer, useEffect } from "react";

import Terms from "./Terms"
import FormField from "./FormField"

import useToken from '../../context/Profile/useToken';

import axios from "axios";

// creating functional component ans getting props from app.js and destucturing them
const OwnershipTwo = ({ nextStep, handleFormData, values }) => {
  
  const { getProfile} = useToken()

  //creating error state for validation
  const [error, setError] = useState(false)
  const [processing, setProcessing] = useState(null)
  const [context, setContext] = useState(undefined)

  const validateState = async() => {

    setProcessing(true)

    axios({
      method: "POST",
      url:"/broker/api/validate_ownership",
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      },
      data:{
        CodigoComitente:values.CodigoComitente

      }
    })
    .then((response)=>{
      
      nextStep()
    })
    .catch((error)=>{
      
      setProcessing(null)

      if (error.response.data?.msg) {
        
        setContext({
          KIND:'ALERT',
          COLOR:'RED',
          MESSAGE:error.response.data.msg
        })

      } else {        

      }}
      )



  }

  const submitFormData = async() => {

    if (
      values.CodigoComitente
    ) {
        validateState()
        //dispatch({type:ACTION_TYPES.CONFIRMED_CHANGES, payload:values})
        //nextStep();
    } else {
      setError(true);
    }
  }

  return (
    
    <div className="relative">
        
    <div className="border-t-4 border-green-500 text-emerald-700 p-4 " role="alert">
        <h3 className="font-bold">Confirmar solicitud de Validación</h3>

        {context &&
            <p className="my-5">
              <div style={{ color: context.COLOR }}>{context.MESSAGE}</div>
            </p>        
        }

        <FormField title={'Cuenta Comitente IEB'} value={values.CodigoComitente} tag='CodigoComitente' handleFormData={handleFormData} error={error}/>

      </div>
        
      <div className="p-4 grid grid-cols-1 ">
          <div className="gap-x-6 gap-y-8">
          

            
            <h3 className="mt-10 font-bold">Un paso más... Lee los términos y confirmá tu solicitud</h3>

            <Terms/>

            <p className="text-right">
              La respuesta tiene carácter de declaración jurada. Al darle click a <b>Confirmar</b> estás aceptando dichas respuestas.
            </p>
            
            <div className="mt-6 flex items-center justify-end gap-x-6 mr-6">
              
                { processing ?
                  <button variant="primary" type="submit" className="mt-10 block rounded-md bg-emerald-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600">
                    <svg aria-hidden="true" role="status" className="inline mr-3 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"></path>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"></path>
                    </svg>
                    Confirmar
                  </button>
                      :                
                  <button variant="primary" type="submit" className="mt-10 block rounded-md bg-emerald-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                    onClick={submitFormData}>
                    Confirmar
                  </button>
                  }
            </div>
        </div>
      </div>
    </div>

  );
};

export default OwnershipTwo;
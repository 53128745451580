const OperateDissclaimer = ({showTerms, setShowTerms, side}) => {
    return (
    
        showTerms && 
        <div className="mt-10 p-5 rounded-2xl bg-gray-50 text-left ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center w-full">
          <h1 className="font-bold">Condiciones de la operación</h1>

        {side ? 
          <div className="max-h-60 overscroll-y-auto overflow-scroll my-10 border border-slate-300 p-3 rounded-sm">
          El precio final de la compra de dólar es el resultado de una operación a perfeccionarse en un día hábil posterior y se muestra el precio actual solo a fines estimativos. Al confirmar aceptás remitir una orden de AL30 en pesos y de efectuarse la compra los títulos serán revendidos a precio de mercado en dólares en la próxima rueda al precio disponible en el plazo . Sobre cada operación en pesos y en dólares aplican las comisiones arriba detalladas.
          </div>
          :
          <div className="max-h-60 overscroll-y-auto overflow-scroll my-10 border border-slate-300 p-3 rounded-sm">
          El precio final de la compra de dólar es el resultado de una operación a perfeccionarse en un día hábil posterior y se muestra el precio actual solo a fines estimativos. Al confirmar aceptás remitir una orden de AL30 en pesos y de efectuarse la compra los títulos serán revendidos a precio de mercado en dólares en la próxima rueda al precio disponible en el plazo . Sobre cada operación en pesos y en dólares aplican las comisiones arriba detalladas.
          </div>
        }
      </div>
      )
}
export default OperateDissclaimer
import React, { useState, useEffect } from "react"
import { Link } from 'react-router-dom'

const RibbonStats = ({pp}) => {

    // const [stats, setStats] = useState([{name:'Pesos disponibles para operar', currency:'ARS', value: null}
    // ,{name:'Dólares en cuenta',currency:'USD',value: null}
    // ,{name:'Valor total de tu cuenta',currency:'ARS', value: null}]
    // )

    const [stats, setStats] = useState([
    //{name:'Pesos disponibles para operar', currency:'ARS', value: null},
    //{name:'Dólares en cuenta',currency:'USD',value: null},
    {name:'Valor de tu cuenta',currency:'ARS', value: null}]
    )


    useEffect(()=>{
      //  setStats([{name:'Pesos disponibles para operar', currency:'ARS', value:pp.pesos_0},{name:'Dólares en cuenta',currency:'USD',value: pp.dolarmep_0},{name:'Valor total de tu cuenta',currency:'ARS',value: pp?.total_posicion}])
      setStats([{name:'Valor de tu cuenta',currency:'ARS',value: pp?.total_posicion}])
      
      },[
        pp
  
      ])

    return (
        <div className="w-full py-2 bg-lime-50 border-b-2 border-lime-300 mt-1">
            <div className="max-w-4xl mx-auto flex flex-row justify-between text-right">

                            {stats.map((stat) => (
                                <div key={stat?.name} className=" flex flex-row text-base  text-gray-600  pb-1 mx-auto">
                                        <Link to="/useraccount/tus-activos" className="flex justify-end">
                                            <button className="hidden md:block relative text-xl w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-black after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center">
                                                {stat?.name}:
                                            </button>
                                            <button className="md:hidden blockrelative text-xl w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-black after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" />
                                                </svg>
                                            </button>
                                        </Link>
                                    <dd className="text-3xl font-semibold tracking-tight text-gray-900 sm:text-2xl ml-3">
                                        <span className="w-10">{stat.value}</span>
                                        <span className="w-10 text-xs">{stat.currency}</span>
                                    </dd>
                                </div>
                            ))}
            </div>
        </div>
    )
}

export default RibbonStats
import React, { useState, useReducer, useEffect } from "react";

import FormSelect from "./FormSelect"
import AccessButton from "./../fields/AccessButton"


import StepOnePopUp from './StepOnePopUp';

import axios from "axios";

// creating functional component ans getting props from app.js and destucturing them
const StepOne = ({ nextStep, handleFormData, values, setFormData }) => {

  const [openPopup, setOpenPopup] = useState(false);

  const HandleRemovePopUp = () => setOpenPopup(false);

  const [processing, setProcessing] = useState(false)

  const [context, setContext] = useState(false)
   
  //creating error state for validation
  const [error, setError] = useState(false)

  const validateRenaper = async() => {

    setProcessing(true)

    axios({
      method: "POST",
      url:"/broker/api/validate_dni",
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      },
      data:{
        TipoDocumento: 2,
        NroDocumento:values.NroDocumento,
        Genero:values.Genero,
        EstadoCivil:values.EstadoCivil
      }
    })
    .then((response)=>{
    
          Object.keys(response.data).forEach(function(key) {
      
          setFormData(prevState => (
            {
            ...prevState,
            [key]: response.data[key]
          }));
      })
    nextStep()
      
    })
    .catch((error)=>{
      setProcessing(null)
      if (error.response.data?.msg) {
          setContext({
              KIND:'ALERT',
              COLOR:'RED',
              MESSAGE:error.response.data.msg
            })
      } else {
          setContext({
            KIND:'ALERT',
            COLOR:'RED',
            MESSAGE:"Tuvimos problemas al procesar tu información. Volvé a intentarlo más tarde."
            })
      }
    })

  }

  const submitFormData = async() => {

    console.log("submitFormData", values)


    if (
      // (values.TipoDocumento !== null && values.TipoDocumento  > -1 ) &&
      (values.NroDocumento !== null &&  values.NroDocumento  > 9999) &&
      (values.Genero !== null && values.Genero > -1)  &&
      (values.EstadoCivil !== null && values.EstadoCivil > -1 )
      // || (values.civil_state) || (values.date_of_birth)
    ) {
      setError(false);
      setContext(false)

      if (values?.ValidoDatosRenaper)
        {
          validateRenaper()
        }
      else
        {
          setOpenPopup(true)
        }
    } else {
      setError(true);
    }
  }


  const consentFormData = async() => {

    if (
      // (values.TipoDocumento !== null && values.TipoDocumento  > -1 ) &&
      (values.NroDocumento !== null &&  values.NroDocumento  > 9999) &&
      (values.Genero !== null && values.Genero > -1)  &&
      (values.EstadoCivil !== null && values.EstadoCivil > -1 )
      // || (values.civil_state) || (values.date_of_birth)
    ) {
      validateRenaper()
      //setOpenPopup(false)
    } else {
      setError(true);
    }
  }

  return (
    
    <div className="relative">
      

      {openPopup ? 
        <>
          <div className="border-t-4 border-green-500 text-emerald-700 p-4 " role="alert">
            <h3 className="font-bold">Apertura de Cuenta</h3>
          </div>
          <p>
            {error ? (<div style={{ color: "red" }}>Todos los campos son requeridos</div>) : ("")}
          </p>
          {context &&
              <p className="my-5">
                <div style={{ color: context.COLOR }}>{context.MESSAGE}</div>
              </p>        
          }
          <StepOnePopUp openPopUp={openPopup} closePopUp={HandleRemovePopUp} processing={processing} consentFormData={consentFormData}></StepOnePopUp>
        </>
        
        
        :
        <>
          <div className="border-t-4 border-green-500 text-emerald-700 p-4 " role="alert">
          <h3 className="font-bold">Validación de datos personales</h3>
          <p>
            {error ? (<div style={{ color: "red" }}>Todos los campos son requeridos</div>) : ("")}
          </p>        
          {context &&
              <p className="my-5">
                <div style={{ color: context.COLOR }}>{context.MESSAGE}</div>
              </p>        
          }
          </div>
          <div className="p-4 grid grid-cols-1 " >

            <div className="gap-x-6 gap-y-8" style={{minHeight:"300px"}}>
              <div className="pt-5">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                Documento Nacional de Identidad
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <span className="flex select-none items-center pl-3 pr-2 text-gray-500 sm:text-sm">DNI</span>
                  <input
                    type="text"
                    name="NroDocumento"
                    defaultValue={values.NroDocumento}
                    onChange={handleFormData("NroDocumento")}
                    placeholder="tú número de DNI"
                    
                    autoComplete="NroDocumento"
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    style={{ border: (error && values.NroDocumento < 9999 )? "2px solid red" : "" }}

                />
                </div>
              </div>
              </div>

            <FormSelect 
              title={'Género según documento'}
              value={values.Genero}
              tag={'Genero'}
              enum_tag={'GENERO'}
              handleFormData={handleFormData}
              error={error}/>

            <FormSelect 
              title={'Estado Civil'}
              value={values.EstadoCivil}
              tag={'EstadoCivil'}
              enum_tag={'ESTADO_CIVIL'}
              handleFormData={handleFormData}
              error={error}/>
          </div>
          
              <hr className="mt-10"></hr>
              
              <div className="mt-6 flex items-center justify-end gap-x-6 mr-6">
      
                <AccessButton
                    text={'Confirmar'}
                    fieldaction={submitFormData}
                    processing={processing}
                    >
                </AccessButton>
              </div>
          </div>
        </>
      }
    </div>
  );
};

export default StepOne;

const AssetsRow = ({userAssetsList}) => {



    return (
        <div>
            {userAssetsList.map(asset => {

                return (
                    <div className="overflow-x-auto	w-full" key={asset.Ticker}>
                        <ul className="mt-5">
                            <hr></hr>
                            <li>
                                <div className="flex flex-row justify-between">
                                <span>{asset.Ticker}</span>
                                    <span><b className="break-keep"> AR$ {asset.Importe} </b></span>
                                </div>
                            </li>
                            <li>Especie: {asset.Especie} </li>
                            <li>Costo: {asset.Costo} </li>
                            <li>Tenencia: {asset.Cantidad} </li>
                            <li>Rendimiento: {asset.Precio} ({asset.Variacion} %) </li>

                        </ul>
                    </div>
                    )
            })}

        </div>
    )
}


export default AssetsRow
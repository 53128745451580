export const buyEstimation = (bidArs, askUsd, cashAvailable, dm=0.0002, buffer=0.004, comisell=0.0081) => {
    /**
     * bidArs px * 100
     */

    let side1Q = 0
    let side1Amount = 0
    let totalExpense = 0
    let side2Q = 0

    if (dm > 0.001|| buffer > 0.005 || comisell > 0.01) {
        return {side1Q, side1Amount, totalExpense, side2Q}
    }
    
    if (bidArs && askUsd && cashAvailable) {
        let ppc = Math.round(cashAvailable / (1+dm) * 100) / 100
        bidArs = Math.round(bidArs * (1+buffer) ) / 100
        side1Q = Math.floor(ppc / bidArs)
        side1Amount = Math.floor( side1Q * bidArs * 100) / 100
        totalExpense = Math.floor(side1Amount * (1 + dm) * 100) / 100
        askUsd = Math.floor((askUsd  / (1+comisell)) * 10) / 1000
	    side2Q =  Math.floor(side1Q * askUsd * 100) / 100
    }
    
    return {side1Q, side1Amount, totalExpense, side2Q}

}

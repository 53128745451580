import React, {useState} from "react";

import { Link } from 'react-router-dom'

const NavBar = () => {
    return (
      <nav className="bg-gray-100 border-gray-900 py-3 right-0 shadow-md">
        <div className="max-w-screen-xl flex flex-row items-center justify-between mx-auto">
          <span>
            
          </span>
            <ul className="font-medium flex flex-row border border-gray-100 rounded-lg md:flex-row md:space-x-8 md:border-0 mr-5">
            <li>
                <Link to="/useraccount/registro">
                <div className="block rounded-md text-emerald-600 click:bg-green-500 px-3 py-2 text-center text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600 flex flex-row" aria-current="page">
                    Crear Cuenta
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" dataSlot="icon" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z" />
                    </svg>
                  </div>
                </Link>
              </li>
              <li>
                <Link to="/useraccount/acceso">
                <div className="block rounded-md bg-emerald-600 hover:bg-green-500 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600 flex flex-row
                  " aria-current="page">
                    Acceder
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"  style={{width:"25px",height:"25px"}}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                    </svg>
                  </div>
                </Link>
              </li>
            </ul>
        </div>
      </nav>

    )
}

export default NavBar
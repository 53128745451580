import React, { useState, useContext, useEffect } from "react"

import { Link } from 'react-router-dom'

import AssetsRow from './AssetsRow';
import UserSideBar from './../../business/UserSideBar.js';
import RibbonStats from './../fields/RibbonStats.js';
import OnboardingHook from './../onboarding/OnboardingHook.js';
import ProfileContext from "./../../context/Profile/ProfileContext.js";
import AssetsContext from "./../../context/Assets/AssetsContext.js";



const YourAssets = () => {
  
    const {userProfile} = useContext(ProfileContext)
    const {assets} = useContext(AssetsContext)

    const [open, setOpen] = useState(false)


    return (
        <div className="relative pt-14 min-h-screen">
          <header className="absolute inset-x-0 top-0 z-50">
                <UserSideBar open={open} setOpen={setOpen}/>
            </header>
        
            {userProfile?.has_account === false ?       
              <OnboardingHook />
            :
              <RibbonStats pp={userProfile?.pp} />
            }

        <div className="w-full  h-full">
          <div className="container  mx-auto px-4 mt-5">
            <div className='mx-auto flex m-15 px-5 flex-col'>
              <h2 className="font-semibold text-green-700">Disponibilidades</h2>
                  <table className="table-auto w-full text-left m-5 max-w-md">
                      <thead>
                          <tr>
                              <th>Plazo</th>
                              <th>Pesos</th>
                              <th>MEP</th>
                              <th className="hidden">Cable</th>
                          </tr>
                      </thead>
                      <tbody>
                              <tr className="border-solid border-b-2 ">
                                  <td>Disponible para retiro</td>
                                  <td>{(userProfile?.pp.retiro_pesos )} </td>
                                  <td>{userProfile?.pp.retiro_mep}</td>
                                  <td  className="hidden">{userProfile?.pp.retiro_ccl}</td>
                              </tr>
                              <tr>
                                  <td>{(userProfile?.pp.plazo_0 )} </td>
                                  <td>{userProfile?.pp.pesos_0}</td>
                                  <td>{userProfile?.pp.dolarmep_0}</td>
                                  <td className="hidden">{userProfile?.pp.dolarcable_0}</td>
                              </tr>
                              <tr>
                                  <td>{(userProfile?.pp.plazo_1)} </td>
                                  <td>{userProfile?.pp.pesos_1}</td>
                                  <td>{userProfile?.pp.dolarmep_1}</td>
                                  <td className="hidden">{userProfile?.pp.dolarcable_1}</td>
                              </tr>
                              <tr>
                                  <td>{(userProfile?.pp.plazo_2)} </td>
                                  <td>{userProfile?.pp.pesos_2}</td>
                                  <td>{userProfile?.pp.dolarmep_2}</td>
                                  <td className="hidden">{userProfile?.pp.dolarcable_2}</td>
                              </tr>
                  </tbody>
                  </table>
              <div className="mt-5"></div>
              <h2 className="font-semibold text-green-700">Tus Activos</h2>
                  {assets.assets_list ?
                    <AssetsRow userAssetsList={assets.assets_list}></AssetsRow> :
                    <p>No tenés activos en este momento.</p>
                  }   
                </div>
              </div>

              {/* userAssets &&
                <div className="mx-auto columns-1">
                  <div className="rounded-2xl  py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">

                    {
                      userAssets.Totales &&
                      <div className="table-auto w-full text-left m-5 max-w-md md:mx-auto">
                      <h3 className="font-bolder">Tu Posición ARS{userAssets.Totales.TotalPosicion}</h3>
                        <ul className="mb-5">

                        {userAssets.Totales.Detalle.map(
                          row => {

                            return <li id={row.Detalle}>
                                    {row.Detalle}: ARS {row.Importe}
                                  </li>
                          }
                        )}

                        </ul>
                        <hr></hr>


                        <ul className="mt-5">

                        {userAssets.Activos.map(
                          row => {

                            return <li key={row.TipoActivo} id={row.TipoActivo}>
                                    {row.TipoActivo}: ARS {row.Importe}
                                  </li>
                          }
                        )}

                        </ul>

                      </div>
                    }
                  
                  </div>
                </div>                                
                  */}
          </div>
        </div>

    )
}

export default YourAssets
import React, { useState, useEffect } from "react"

import axios from "axios";

import BackendSideBar from './BackendSideBar.js'

import { Link } from 'react-router-dom'

import { useParams } from "react-router-dom";

import ReactJson from 'react-json-view';


  const FieldFormat = ({label, value }) => {
    return (
        <div className="md:flex md:items-center mb-6">
            <div className="md:w-1/3">
            <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                {label}
            </label>
            </div>
            <div className="md:w-2/3">
                <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name" type="text" value={value} disabled="true"/>
            </div>
        </div>
    )
}

const User = () => {

    const user_hash = useParams()

    const [open, setOpen] = useState(false) 

    let { resetToken } = useParams();

    const [processing, setProcessing] = useState(false)

    const [user, setUser] = useState([])

    const [assets, setAssets] = useState([])

    const [context, setContext] = useState(null)

    const getUser = () => {
        axios({method:"POST",
            url:"/broker/api/backend/userdetail/",
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token')
            },
            data: {
                ...user_hash
            }
        }).then((response) => {
            setUser(response.data.response.user)      
            setAssets(response.data.response.assets)
        }).catch((error)=> {
            setContext({COLOR:"red",MESSAGE:error.response.data.msg})
            setProcessing(false)
            if (user.codigo_comitente && user.codigo_comitente > 0) {
                setAssets(user.codigo_comitente)
            }
        }).catch((error)=> {
            setContext({COLOR:"red",MESSAGE:error.response.data.msg})
            setProcessing(false)
        })
    }

    const OnboardingReview = () => {

        axios({
            method:"POST",
            url:"/broker/backend/onboarding_review",
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
              },  
            data:{
                ... user_hash
            }
        }).then((response) => {
            setContext({COLOR:"green",MESSAGE:response.data.msg})
            setProcessing(false)
        }).catch((error)=> {
            setContext({COLOR:"red",MESSAGE:error.response.data.msg})
            setProcessing(false)
        })
    }

    useEffect(() => {
        getUser()
    }, [])


    return (
        <div className="relative pt-14 min-h-screenlg:px-8">

            
            <header className="absolute inset-x-0 top-0 z-50">
                <BackendSideBar open={open} setOpen={setOpen}/>
            </header>


            <hr></hr>

            {context &&
                <div className="my-5 flex flex-row" >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-12 h-12">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z" />
                </svg>

                    <div style={{ color: context.COLOR }}>{context.MESSAGE}</div>
                </div>        
            }


            <div className="container mx-auto">

                <Link to="/useraccount/backend/users">
                    <span className="p-5 ml-auto ">VOLVER</span>
                </Link>

                <FieldFormat label="User Hash" value={user?.user_hash} />

                <FieldFormat label="Cuenta Comitente" value={user?.codigo_comitente} />

                <FieldFormat label="Cuenta Comitente" value={user?.email} />
                

                {!processing ?
                <button onClick={()=> OnboardingReview()}>Onboarding Review</button>
                :
                    <>...</>
                }

                <div className="max-w-3xl mx-auto"> 
                    <h1>Assets</h1>
                    <ReactJson src={assets} theme="monokai" />
                </div>
            
            </div>


        </div>
        
    )


}

export default User
const BankRibbon = ({setBankPage}) => {

    return(
        <div className="w-full mx-auto pt-2 bg-lime-50 border-b-2 border-lime-300" style={{minHeigth:"70px"}}>
            <div className="max-w-md mx-auto flex flex-row justify-between text-center">
                <div>

                    <button
                        onClick={()=>setBankPage('retirar')}

                        className="rounded-md m-2 flex flex-col bg-white-600 px-3 text-center text-sm font-semibold text-indigo shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 11.25l-3-3m0 0l-3 3m3-3v7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <span>Retirar</span>
                    </button>

                </div>
                <div>
                    <button 
                        onClick={()=>setBankPage('depositar')}
                        className="rounded-md m-2 flex flex-col bg-white-600 px-3 text-center text-sm font-semibold text-indigo shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75l3 3m0 0l3-3m-3 3v-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <span>Depositar</span>
                    </button>

                </div>
                <div>
                    <button 
                        onClick={()=>setBankPage('registrarcbu')}
                         className="rounded-md m-2 flex flex-col bg-white-600 px-3 text-center text-sm font-semibold text-indigo shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z"></path>
                        </svg>
                        <span>Cuentas</span>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default BankRibbon
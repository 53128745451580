import React, {useState} from "react";
import useToken from '../context/Profile/useToken';
import axios from "axios";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
  } from "react-router-dom";

import Logo from '../business/Logo'

import AccessInput from './fields/AccessInput';
import AccessButton from './fields/AccessButton';
  
export default function Regain() {

    let { resetToken } = useParams();

    const [loginForm, setloginForm] = useState({
      email: ""
    })
    
    const [processing, setProcessing] = useState(false)
    const [message, setMessage] = useState('')

    const resetPassword = () => {

      setProcessing(true)

        axios({
          method: "POST",
          url:"/broker/api/reset_password",
          data:{
            "email":loginForm.email
          }
        })
        .then((response) => {
            
          setProcessing(false)
          setMessage(response.data.msg)
          ////window.location.replace('/useraccount/Home-user');

        }).catch((error) => {

          setProcessing(false)
          setMessage(error.response.data.msg)
          if (error.response) {


            }
        })
      
      }

      function handleChange(event) {
        setMessage('') 
        const {value, name} = event.target
        setloginForm(prevNote => ({
            ...prevNote, [name]: value})
        )}

    return (
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 lg:px-8 py-5 md:py-12 ">
    <div className="sm:mx-auto sm:w-full sm:max-w-sm  content-center">

      <div className="sm:mx-auto sm:w-full sm:max-w-sm  content-center">
        <Link to="/useraccount/">
          <Logo classLogo="text-2xl flex items-center"/>
        </Link>
      </div>

      <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
        Recuperar Acceso a tu cuenta
      </h2>
    </div>

    <div className="sm:mx-auto sm:w-full sm:max-w-sm  content-center">
            <p className="text-red-500">{message}</p>
          </div>

    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">

        <div>
          <div className="flex items-center justify-between">
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
              Email
            </label>

          </div>
          <div className="mt-2">
                    <AccessInput
                      tag={'email'}
                      text={loginForm.email}  
                      ftype={'email'}
                      fieldaction={handleChange} 
                      processing={processing}
                    />
          </div>
        </div>

        <div>
          <AccessButton
                text={'Recuperar'}
                fieldaction={resetPassword}
                processing={processing}
                >
          </AccessButton>

          
        </div>
      
      <p className="mt-10 text-center text-sm text-gray-500">
              ¿Ya estás registrado?{' '}
              <Link to="/useraccount/acceso">
                <span className="font-semibold leading-6 text-emerald-600 hover:text-green-500">
                  Acceder
                </span>
              </Link>
      <br></br>
        ¿No estás registrado?{' '}
        <Link to="/useraccount/registro">
          <span className="font-semibold leading-6 text-emerald-600 hover:text-green-500">
            Registrate ahora
          </span>
        </Link>
      </p>
    </div>
  </div>)

}
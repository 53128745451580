import React, { useReducer } from "react";
import axios from "axios";

import AssetsReducer from "./AssetsReducer";

import { GET_ASSETS } from "../types";

const AssetsState = () => {

    const initialState = {
        assets: {assets:{
            Activos:[],
            Totales:{
                TotalPosicion: 0
              }
            },
        assets_list: []

        }}


    const [state, dispatch] = useReducer(AssetsReducer, initialState);

    const getAssets = async () => {
        let token = localStorage.getItem('token')

        if (!token) {
          return false
        }

        axios({
          method: "GET",
          url:"/broker/api/user_assets",
          headers: {
            Authorization: 'Bearer ' + token
          }
        })
        .then((response) => {
          //response.data.access_token && setToken(response.data.access_token)
          dispatch({ type: GET_ASSETS, payload: response.data });
        }).catch((error) => {
          // if (error.response.status == 401) {
          //   console.log('desconecta por inactividad', error.response.data)
          // }
  
          // setUserProfile(initialProfile)
          // FETCH_DATA_FAILURE
          //   setUserProfile(profTemplate)
          //
        }) 
      
      }


    return (
        {
            assets: state.assets,
            getAssets,
        }
    );
};

export default AssetsState;
import React, { useState, useEffect } from "react"
import BackendSideBar from './BackendSideBar.js'

import axios from "axios";

const ShowCall = ({jsonMessage}) => {
    const [show, setShow] = useState(false)
    return (<>
    <div>
        {show ? <> {JSON.stringify(jsonMessage,null,2)} </> :  <></>}
        <p>
            <button onClick={()=> setShow(!show)}>
                toogle
            </button>
        </p>
    </div>
    </>)
}

const BackendCalls = ({j}) => {


    const [message, setMessage] = useState("")
    const [content, setContent] = useState({data:[]})
    const [open, setOpen] = useState(false)

    function getApiCalls(endpoint) {
        axios({
          method: "GET",
          url:"/broker/api/backendapi_calls/" + endpoint,
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
        .then((response) => {

            if (response?.data) {
                setContent(response.data)
            }
  
        }).catch((error) => {
            setMessage("error retrieving data")
            setContent({data:[]})
        })
      
      }

    

    return (
        <div className="relative pt-14 min-h-screenlg:px-8">
            <header className="absolute inset-x-0 top-0 z-50">
                <BackendSideBar open={open} setOpen={setOpen}/>
            </header>
            <div className="mt-16 max-wid">

            </div>
            <h1>BackendOrders</h1>

            <div className="flex flex-row">
                <button
                    className="rounded-md bg-emerald-600 flex mx-2 p-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                    onClick={()=>getApiCalls('IngresaOrden')}>
                    Ver ordenes ingresadas
                </button>
                <button 
                    className="rounded-md bg-emerald-600 flex mx-2 p-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                    onClick={()=>getApiCalls('AnulaOrden')}>
                    Ver ordenes anuladas
                </button>
                <button 
                    className="rounded-md bg-emerald-600 flex mx-2 p-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                    onClick={()=>getApiCalls('EstadoOrden')}>
                    Ver Estado Orden
                </button>
                <button 
                    className="rounded-md bg-emerald-600 flex mx-2 p-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                    onClick={()=>getApiCalls('PortafolioReducido')}>
                    Ver Activos
                </button>
                <button 
                    className="rounded-md bg-emerald-600 flex mx-2 p-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                    onClick={()=>getApiCalls('PoderCompra')}>
                    Ver Liquidez
                </button>

                <button 
                    className="rounded-md bg-emerald-600 flex mx-2 p-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                    onClick={()=>getApiCalls('AltaTitularCondomino')}>
                    Ver Altas
                </button>

                <button 
                    className="rounded-md bg-emerald-600 flex mx-2 p-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                    onClick={()=>getApiCalls('Renaper')}>
                    Ver Renaper
                </button>

                <button 
                    className="rounded-md bg-emerald-600 flex mx-2 p-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                    onClick={()=>getApiCalls('DatosCuenta')}>
                    Ver Datos Cuenta
                </button>

                <button 
                    className="rounded-md bg-emerald-600 flex mx-2 p-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                    onClick={()=>getApiCalls('CuentasBancarias')}>
                    Bancos : Cuentas Bancarias
                </button>


                <button 
                    className="rounded-md bg-emerald-600 flex mx-2 p-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                    onClick={()=>getApiCalls('AltaCBU')}>
                    Bancos : AltaCBU
                </button>

                <button 
                    className="rounded-md bg-emerald-600 flex mx-2 p-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                    onClick={()=>getApiCalls('BajaCBU')}>
                    Bancos : BajaCBU
                </button>

                <button 
                    className="rounded-md bg-emerald-600 flex mx-2 p-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                    onClick={()=>getApiCalls('PagoaClientes')}>
                    Bancos : Retiros de Efectivo
                </button>


                
            </div>

            {
                content.data.map(c => {return (
                    <div key={c.id} className="bg-slate-200 rounded-2xl px-3 md:p-10 bg-gray-50 py-10 text-left ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center my-10" key={c.id} >    
                        <ul>
                            <li>
                                {c.id} 
                                { JSON.stringify(c.body,null,2)} 
                            </li>
                            <li><ShowCall jsonMessage={c.json}></ShowCall></li>
                        </ul>
                    </div>

                )})

            }

        
        </div>
    )

}

export default BackendCalls
import React, { useReducer } from "react";
import axios from "axios";

import OrdersReducer from "./OrdersReducer";

import { GET_ORDERS, GET_MEP_ORDERS } from "../types";

const OrdersState = () => {

    const initialState = {
        mepOrders: [],
        opOrders: []
        }


    const [state, dispatch] = useReducer(OrdersReducer, initialState);

    const getOrders = async () => {
        let token = localStorage.getItem('token')

        if (!token) {
          return false
        }

        axios({
          method: "GET",
          url:"/broker/api/user_orders",
          headers: {
            Authorization: 'Bearer ' + token
          }
        })
        .then((response) => {
          //response.data.access_token && setToken(response.data.access_token)

          if (response.data?.mep_orders) {
            dispatch({ type: GET_MEP_ORDERS, payload: response.data?.mep_orders });
          }

          if (response.data?.op_orders) {
            dispatch({ type: GET_ORDERS, payload: response.data?.op_orders });
          }
        }).catch((error) => {
          // if (error.response.status == 401) {
          //   console.log('desconecta por inactividad', error.response.data)
          // }
  
          // setUserProfile(initialProfile)
          // FETCH_DATA_FAILURE
          //   setUserProfile(profTemplate)
          //
        }) 
      
      }


    return (
        {
            opOrders: state.opOrders,
            mepOrders: state.mepOrders,
            getOrders,
        }
    );
};

export default OrdersState;
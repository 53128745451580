import React, { useContext } from "react"

import { Link } from 'react-router-dom'

import PricesContext from "../../context/Prices/PricesContext.js";


const YourOrdersBuyDetail = ({order}) => {

    const {prices} = useContext(PricesContext)

    console.log("to mock",order, prices)
    
    const OrderInProgress = ({t}) => {

        return (
            <span className="flex flex-row"><b className="mr-2">{t}</b>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>                
            </span>
            )
    }

    const OrderRequestedArs = ({o}) => {

        return (
            <>
                <li>
                    <b className="mr-3">Compra de título en pesos</b>   
                </li>
                <li>Importe solicitado: ARS {o?.order_amount}</li>
            </>
        )
    }

    const OrderProcesedArs = () => {

        return (
            <>
                <li>
                    <span className="flex font-bold">
                    <b className="mr-3">Compra de título en pesos</b>
                           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                        </svg> 
                    </span>
                </li>
            </>
        )
    }

    const OrderProcesedUsd = () => {

        return (
            <>
                <li>
                    <span className="flex font-bold">
                        <b className="mr-3">Venta de título en USD</b>
                    </span>
                </li>
            </>
        )
    }

    const MarketArs = ({o}) => {


        return (
            <>

                <li>Importe ejecutado ARS {o?.mep_order_analytics?.analytics_ars?.orders_bought}</li>
                {o?.mep_order_analytics?.analytics_ars &&
                    <li>Comisiones ARS {o?.mep_order_analytics?.analytics_ars?.orders_fees}</li>
                }
            </>
        )
    }

    const MarketUSD = ({o,p}) => {


        return (
            <>
                <li>Precio estimativo de la operación: ARS {
                p[o?.source]?.lastUsd  ?
                (o?.mep_order_analytics?.analytics_ars?.avg_side1_price / (p[o?.source]?.lastUsd / 100)).toFixed(2)
                :
                "..."
                }</li>
            </>
        )
    }
    
    const OperatedUSD = ({o}) => {


        return (
            <>
            
                <li>
                    <span className="flex font-bold">
                    Venta de títulos finalizada
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                        </svg> 
                    </span>
                </li>
                <OperatedUSDDetails o={o}></OperatedUSDDetails>
            </>
        )
    }

    const OperatedUSDDetails = (o) => {


        return (
            <>
                
                <li className="flex">
                        Dólares Comprados: { o?.o.sold_amount}
                </li>
                <li className="flex">
                        Comisiones USD: { o?.o.orders_fees}
                </li>
                <li className="flex bold">
                        Precio por dólar: ARS { o?.o?.mep_price_side_2?.toFixed(2)}
                </li>
            </>
        )
    }

    switch (order?.order_progress) {
        // case 1 to show stepOne form and passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
        case "ORDER_EXPIRED":
        return (<>
            <p>Se ha vencido el tiempo para ejecutar la orden. Ingrese una nueva operación.</p>
        </>);

        case "ORDER_SIDE_2_INACTIVE":
        return (<>
            <p>Se ha vencido el tiempo para ejecutar la orden. Ingrese una nueva operación.</p>
        </>);

        case "ORDER_ABORTED":
            return (<>
                <p>La orden no fue confirmada por el usuario y fue desestimada.</p>
            </>);

        case "ORDER_REJECTED":
            return (<>
                <p>La orden solicitada no fue aceptada.</p>
            </>);

        case "ORDER_CREATED":
        return (<>
                <ul>
                    <OrderRequestedArs o={order}></OrderRequestedArs>
                    <li>Orden creada. Esperando instrucciones para operar.</li>
                </ul>
        </>);
        
        case "ORDER_CONFIRMED":
            return (<>
                <ul>
                    <OrderRequestedArs o={order}></OrderRequestedArs>
                    <li>Orden Confirmada. Preparando ordenes para enviar al mercado.</li>
                </ul>
            </>);
        
        case "ORDER_SIDE_1_SENT":
            return (<>
                <ul>
                    <OrderRequestedArs o={order}></OrderRequestedArs>
                    <li>
                        <OrderInProgress t={"Orden en Proceso"}></OrderInProgress>
                    </li>

                </ul>
            </>); 

        case "ORDER_SIDE_1_SENT_AWAIT":
            return (<>
                <ul>
                    <OrderRequestedArs o={order}></OrderRequestedArs>
                    <MarketArs o={order}></MarketArs>
                    <li>
                        <OrderInProgress t={"Orden en Proceso"}></OrderInProgress>
                    </li>

                </ul>
            </>);
        
        case "ORDER_SIDE_1_PARTIALLY_FILLED":
            return (<>
                <ul>
                    <OrderRequestedArs o={order}></OrderRequestedArs>
                    <MarketArs o={order}></MarketArs>
                    <li>
                        <OrderInProgress t={"Orden parcialmente ejecutada. En Proceso"}></OrderInProgress>
                    </li>


                </ul>
            </>);
        

        case "ORDER_SIDE_1_FULLY_FILLED":
            return (<>
                <ul>
                    <OrderProcesedArs o={order}></OrderProcesedArs>
                    <MarketArs o={order}></MarketArs>
                    <OrderProcesedUsd o={order}></OrderProcesedUsd>
                    <li className="flex">
                    {order.from_today ?
                        <b className="mr-3">Esperando plazo legal (parking)</b>
                        :
                        <b className="mr-3">En venta próximamente.</b>
                        }
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>  
                    </li>
                    <MarketUSD o={order} p={prices}></MarketUSD>

                </ul>
            </>);

        case "ORDER_SIDE_1_PARKING":
            return (<>
                <ul>
                    <OrderProcesedArs o={order}></OrderProcesedArs>
                    <MarketArs o={order}></MarketArs>

                    <li className="flex">
                        {order.from_today ?
                        <b className="mr-3">Esperando plazo legal (parking)</b>
                        :
                        <b className="mr-3">En venta próximamente.</b>
                        }
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>  
                    </li>
                    <MarketUSD o={order} p={prices}></MarketUSD>

                </ul>
                </>
                );

        case "ORDER_SIDE_2_SENT_AWAIT": // ORDER_SIDE_2_SENT_CONFIRM // CASE ORDER_SIDE_2_SENT_AWAIT
            return (
                <ul>
                    <OrderProcesedArs o={order}></OrderProcesedArs>
                    <li className="flex">
                        <b className="mr-3">Venta de dólares en proceso</b>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>  
                    </li>
                    <MarketUSD o={order} p={prices}></MarketUSD>
                </ul>
            )
        
            case "ORDER_SIDE_2_SENT":
                return (
                    <ul>
                        <OrderProcesedArs o={order}></OrderProcesedArs>
                        <li className="flex">
                            <b className="mr-3">Venta de dólares en proceso</b>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>  
                        </li>
                        <MarketUSD o={order} p={prices}></MarketUSD>
    
                    </ul>
                )
            
            case "ORDER_SIDE_2_PARTIALLY_FILLED":
                return (
                    <ul>
                        <OrderProcesedArs o={order}></OrderProcesedArs>
                        <MarketUSD o={order} p={prices}></MarketUSD>
                        <li className="flex">
                            <b className="mr-3">Venta de dólares en proceso</b>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>  
                        </li>                            
                        <li className="flex">
                            Dólares Comprados (parcial): USD { order?.mep_order_analytics?.analytics_usd?.sold_amount}
                        </li>
    
                    </ul>
                )
                    
            case "ORDER_SIDE_2_FULLY_FILLED":
                return (
                    <ul>
                        <li>
                            <span className="flex font-bold">
                                Compra de Dólares Exitosa
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                                </svg> 
                            </span>
                        </li>
                        

                        <OrderProcesedArs o={order}></OrderProcesedArs>
                        <MarketArs o={order}></MarketArs>
                        <OperatedUSD o={order?.mep_order_analytics?.analytics_usd} p={order?.mep_order_analytics?.analytics_usd}></OperatedUSD>

                        <p>Los dólares se reflejan en tenencias según el plazo de la operación</p>
                    </ul>
                )

                case "ORDER_SIDE_2_COMPLETED": 
                    return (
                        <ul>
                            <span className="flex font-bold">
                            Compra de Dólares Exitosa
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                                </svg> 
                            </span>
                            

                            <MarketArs o={order}></MarketArs>

                            <OperatedUSDDetails o={order?.mep_order_analytics?.analytics_usd}></OperatedUSDDetails>

                            <Link to="/useraccount/tus-activos">
                            <div className="w-full flex justify-end">
                                <button  className="m-3 flex rounded-md bg-white-600 px-3 py-2 text-center text-sm font-semibold text-indigo shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" />
                                    </svg>
                                    <span className="pl-3">Tus Activos</span>
                                </button>
                                </div>
                            </Link>
                        </ul>
                    )

                case "ORDER_SIDE_2_WAIT_USD":
                    return (
                        <ul>
                            <span className="flex font-bold">
                            Compra de Dólares Exitosa
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                                </svg> 
                            </span>
                            

                            <MarketArs o={order}></MarketArs>
                            <OperatedUSD o={order?.mep_order_analytics?.analytics_usd} p={order?.mep_order_analytics?.analytics_usd}></OperatedUSD>
                            <p>Los dólares se reflejan en tenencias según el plazo de la operación</p>
                        </ul>
                    )

                case "ORDER_FULLFILLED":
                    return (
                        <ul>
                            <span className="flex font-bold">
                            Compra de Dólares Exitosa
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                                </svg> 
                            </span>
                            

                            <MarketArs o={order}></MarketArs>
                            <OperatedUSD o={order?.mep_order_analytics?.analytics_usd} p={order?.mep_order_analytics?.analytics_usd}></OperatedUSD>
                            <p>Los dólares se reflejan en tenencias según el plazo de la operación</p>
                        </ul>
                    )

        default:
        return (
            <p>
                No hemos encontrado la información requerida.

                {order?.order_progress}
            </p>
                );
        ;

    }
}

export default YourOrdersBuyDetail
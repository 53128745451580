import React, { useEffect, useState } from "react";

import axios from "axios";

import StepOne from "./StepOne.js";
import StepOneSelfie from "./StepOneSelfie.js";
import StepOneDNIFront from "./StepOneDNIFront.js";
import StepOneDNIBack from "./StepOneDNIBack.js";
import StepTwo from "./StepTwo.js";
import StepTree from "./StepTree.js";
import StepFour from "./StepFour.js";

import UserSideBar from '../../business/UserSideBar.js';
import ProfilePanel from '../../business/ProfilePanel.js';

import useToken from '../../context/Profile/useToken';
import Footer from '../../business/Footer.js';


const Onboarding = () => {


  const [step, setStep] = useState(0);

  const [formData, setFormData] = useState({})


  useEffect(()=>{
    getAccountState()
  },[])

  const [open, setOpen] = useState(false)


  const getAccountState = async () => {

    axios({
      method: "post",
      url: "/broker/api/onboarding_state",
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    }).then(response => {

      Object.keys(response.data).forEach(function (key) {

        setFormData(prevState => (
          {
            ...prevState,
            [key]: response.data[key]
          }));

      });
    }).catch(error => {
      console.error("getAccountState", error)
    })
  }

  useEffect(() => {
    if (["CREATION_NEEDS_IEB_REVIEW", "ACTIVE_ACCOUNT", "CREATION_AWAITS_IEB_RESPONSE"].includes(formData?.account_creation_process)) {
      setStep(1)
    } else {
      if (step === 0) { setStep(1) }
    }
  },
    [formData?.account_creation_process])


  // handling form input data by taking onchange value and updating our previous form data state


  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    getAccountState()
    setStep(step + 1)
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setStep(step - 1);
  };


  const handleInputData = input => e => {
    // input value from the form
    const { value } = e.target;
    //updating for data state taking previous state and then adding new value to create new object
    setFormData(prevState => (
      {
        ...prevState,
        [input]: value
      }));
  }

  const Ownership = ({progress}) => {

    if (progress  === "OWNERSHIP_IN_PROGRESS") {
      return (<></>)
    }
    else {
      return (<></>)
    }
  }

  // javascript switch case to show different form in each step
  switch (step) {
    // case 1 to show stepOne form and passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
    case 0:
      return (
        <>
          <div className="min-h-screen relative pt-14 ">
            <header className="absolute inset-x-0 top-0 z-50">
              <UserSideBar  open={open} setOpen={setOpen} />
            </header>

            <div className="max-w-4xl mx-auto px-4 mt-5 bg-white py-5 sm:py-10 min-h-full mb-12">

            </div>
          </div>
          <Footer logedIn={true} />
        </>)

    case 1:
      return (
        <>
          <div className="min-h-screen relative pt-14 ">
            <header className="absolute inset-x-0 top-0 z-50">
              <UserSideBar  open={open} setOpen={setOpen} />
            </header>
            <Ownership progress={formData?.account_creation_process} />

            <div className="max-w-4xl mx-auto px-4 mt-5 bg-white py-5 min-h-full">
              <div className="custom-margin col">
                <StepOne context={'ownership'} nextStep={nextStep} prevStep={prevStep} handleFormData={handleInputData} values={formData} setFormData={setFormData} />
              </div>
            </div>
          </div>
          <Footer logedIn={true} />
        </>
      );
    case 2:
      return (
        <>
          <div className="min-h-screen relative pt-14 ">
            <header className="absolute inset-x-0 top-0 z-50">
              <UserSideBar  open={open} setOpen={setOpen} />
            </header>

            <div className="max-w-4xl mx-auto px-4 mt-5 bg-white py-5 sm:py-10 min-h-full mb-12">

              <StepOneSelfie context={'ownership'} nextStep={nextStep} prevStep={prevStep} values={formData} setFormData={setFormData} />

            </div>
          </div>
          <Footer logedIn={true} />
        </>
      );
    case 3:
      return (
        <>
          <div className="min-h-screen relative pt-14 ">
            <header className="absolute inset-x-0 top-0 z-50">
              <UserSideBar  open={open} setOpen={setOpen} />
            </header>

            <div className="max-w-4xl mx-auto px-4 mt-5 bg-white py-5 sm:py-10 min-h-full mb-12">

              <div className="custom-margin col">
                <StepOneDNIFront nextStep={nextStep} prevStep={prevStep} values={formData} setFormData={setFormData} />
              </div>
            </div>
          </div>
          <Footer logedIn={true} />
        </>
      );
    case 4:
      return (
        <>
          <div className="min-h-screen relative pt-14 ">
            <header className="absolute inset-x-0 top-0 z-50">
              <UserSideBar  open={open} setOpen={setOpen} />
            </header>
            <div className="max-w-4xl mx-auto px-4 mt-5 bg-white py-5 sm:py-10 min-h-full mb-12">
              <div className="custom-margin col">
                <StepOneDNIBack nextStep={nextStep} prevStep={prevStep} values={formData} setFormData={setFormData} />
              </div>
            </div>
          </div>
          <Footer logedIn={true} />
        </>
      );
    // case 2 to show stepTwo form passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
    case 5:
      return (
        <>
          <div className="min-h-screen relative pt-14 ">
            <header className="absolute inset-x-0 top-0 z-50">
              <UserSideBar  open={open} setOpen={setOpen} />
            </header>
            <div className="max-w-4xl mx-auto px-4 mt-5 bg-white py-5 sm:py-10 min-h-full mb-12">
              <div className="custom-margin col">
                <StepTwo nextStep={nextStep} prevStep={prevStep} handleFormData={handleInputData} values={formData} />
              </div>
            </div>
          </div>
          <Footer logedIn={true} />
        </>
      );
    // Only formData is passed as prop to show the final value at form submit
    case 6:
      return (
        <>
          <div className="min-h-screen relative pt-14 ">
            <header className="absolute inset-x-0 top-0 z-50">
              <UserSideBar  open={open} setOpen={setOpen} />
            </header>
            <div className="max-w-4xl mx-auto px-4 mt-5 bg-white py-5 sm:py-10 min-h-full mb-12">
              <div className="custom-margin col">
                <StepTree nextStep={nextStep} prevStep={prevStep} handleFormData={handleInputData} values={formData} />
              </div>
            </div>
          </div>
          <Footer logedIn={true} />
        </>
      );
    // Only formData is passed as prop to show the final value at form submit
    case 7:
      return (
        <>
          <div className="min-h-screen relative pt-14 ">
            <header className="absolute inset-x-0 top-0 z-50">
              <UserSideBar  open={open} setOpen={setOpen} />
            </header>
            <div className="max-w-4xl mx-auto px-4 mt-5 bg-white py-5 sm:py-10 min-h-full mb-12">
              <div className="custom-margin col">
                <StepFour nextStep={nextStep} prevStep={prevStep} handleFormData={handleInputData} values={formData} />
              </div>
            </div>
          </div>
          <Footer logedIn={true} />
        </>
      );
    // Only formData is passed as prop to show the final value at form submit
    case 8:
      window.location.replace('/useraccount/tu_cuenta');
    default:
      return (
        <div className="App">
        </div>
      );
  }
}

const FF = ({ values }) => {

  const { getProfile, userProfile } = useToken()

  useEffect(() => {

    

  }, [])

  useEffect(() => { },
    [userProfile?.account_progress]
  )

  return (

    <div className="relative">
      <div className="border-t-4 border-green-500 text-emerald-700 p-4 " role="alert">
        <h3 className="font-bold">Solicitud Recibida</h3>
        <p>
          Muchas gracias. A continuación te compartimos información sobre la apertura de tu cuenta comitente.
        </p>

        <div className="max-w-lg mr-auto flex flex-col justify-between p-5">
          <ProfilePanel />
        </div>

      </div>
    </div>

  )


}


export default Onboarding

import React, {useContext} from "react";

import Multistep from '../components/Multisteps';
import ShowWeb from '../components/home/ShowWeb';
import ShowWebD   from '../components/home/ShowWebD';
import DolarPrice from '../components/DolarPrice';
import Footer from './Footer';
import Navbar from '../components/NavBar';
import usddollar from './../images/usddollar.png';
import dolarsomeclicks from './../images/dolarsomeclicks.png'
import dolarsomeclicks3 from './../images/dolarsomeclicks.3.png'

import PricesContext from "../context/Prices/PricesContext.js";

import { Link } from 'react-router-dom'

function Home() {


  const {prices, marketOpen} = useContext(PricesContext)

  return (
    <div  className="bg-white-100">
      
      <Navbar></Navbar>

      <div className="container mx-auto px-4">
      <div className="mx-auto mt-16 sm:mt-4">

        <div className='flex-column md:flex w-full'> 
          <div className="w-full md:w-1/2 sm:py-10 md:py-1 flex-column text-center justify-center">
            <img src={usddollar} className="mx-auto" style={{maxWidth:"200px"}}/> 
            <div className="mx-4 text-4xl py-auto font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl">Dólar <span className="text-green-600">Financiero</span></div>
            <div className="mx-4 text-1xl py-auto font-extrabold leading-none tracking-tight text-gray-900 md:text-1xl lg:text-1xl">Protegé tus ahorros comprando Dólar MEP.</div>

          </div>
          <DolarPrice prices={prices?.FX_30_LA_CI} marketOpen={marketOpen}></DolarPrice>  
        </div>

      </div>
    </div>
    <div className="mt-10 text-center rounded-2xl ring-gray-900/5 md:p-10 sm:p-2 text-4xl" style={{minHeight:"400px"}}>
      <div className="mx-auto flex md:flex-col flex-col text-center">

      <img src={dolarsomeclicks} className="mx-auto" style={{maxWidth:"200px"}}/> 
        <div className="text-4xl">
            <span className="text-green-600">Dolarizate</span> en pocos clicks de modo legal, seguro y transparente.
        </div>
        <div className="text-xl mt-5">
                <span className="text-green-600 font-extrabold	">
                  0.41% del importe bruto operado.
                </span>
                <span className="ml-2">
                La comisión más competitiva, clara y transparente que va encontrar
                </span>
        </div>
      </div>
    </div>

    {/* https://hypercolor.dev/ */}
    <div className="container mx-auto px-4 mt-10">
      <div className="bg-green-50 rounded-2xl ">

    <div className="hidden md:block mx-auto my-4  md:p-8 sm:p-2 pt-10 pb-5 border-t border-slate-200 sm:flex justify-between text-slate-500 dark:border-slate-200/5 rounded-2xl">
      <ShowWeb></ShowWeb>
    </div>

    <div className="block md:hidden mx-auto my-4  md:p-8 sm:p-2 pt-10 pb-5 border-t border-slate-200 sm:flex justify-between text-slate-500 dark:border-slate-200/5">
      <ShowWebD></ShowWebD>
    </div>

      <div className="m-3 p-5 text-center">
        Todas las transacciones se hacen a precios de mercado, en el mercado, con agentes regulados. Las transferencias se realizan a agentes regulados e inscriptos en CNV.
      </div>
      </div>
    </div>
    <div className="container mx-auto px-4  sm:p-2 pt-10">


      <Multistep></Multistep>




      </div>
      <div className="container mx-auto px-4  my-8  md:p-5 sm:p-2 ">
        <div className="w-full text-center flex flex-col  ">
        <img src={dolarsomeclicks3} className="mx-auto" style={{maxWidth:"200px", borderRadius:"5px"}}/> 
          <h1 className="my-4 mx-auto text-4xl leading-none tracking-tight text-gray-900 md:text-4xl lg:text-4xl ">¿Por qué Dólar <span className="text-green-600">Financiero</span>?</h1>
        </div>

        <div className="flex flex-col max-w-md mx-auto">

      <div className="flex flex-row mx-auto my-3 text-2xl">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" dataSlot="icon" className="w-6 h-6 text-green-600">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" />
          </svg>
          <span className="font-bold ml-2">
          Comisiones Claras
          </span>
      </div>

      <div className="flex flex-row mx-auto my-3 text-2xl">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" dataSlot="icon" className="w-6 h-6 text-green-600">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" />
          </svg>
          <span className="font-bold ml-2">
            Transacciones seguras
          </span>
      </div>


      <div className="flex flex-row mx-auto my-3 text-2xl">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" dataSlot="icon" className="w-6 h-6 text-green-600">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" />
          </svg>
        <span className="font-bold ml-2">
          Operaciones simples
        </span>
      </div>

      <div className="flex flex-row mx-auto my-3 text-2xl">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" dataSlot="icon" className="w-6 h-6 text-green-600">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" />
          </svg>
          <span className="font-bold ml-2">  
            Pensado para vos
          </span>
      </div>


          <Link to="/useraccount/registro" className="flex flex-col md:flex-row mt-3 mx-auto">
            <div className="block rounded-md bg-green-600 hover:bg-green-500 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 flex flex-row" aria-current="page">
                Registrate ahora
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" dataSlot="icon" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z" />
                </svg>
              </div>
              <span className="ml-4">y comenzá a Invertir</span>
            </Link>


      </div>


      

        
      </div>

      
      <Footer logedIn={true}/>
    </div>
  );
}

export default Home;


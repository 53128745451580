import React, { useState, useEffect } from "react"

const MakeDeposit = () => {


    const [toogle, setToogle] = useState({cbupesos:'none', aliaspesos:'none',cbudolar:'none', aliasdolar:'none'})
    const [tooglex, setTooglex] = useState({cbupesos:'none', aliaspesos:'none',cbudolar:'none', aliasdolar:'none'})

    return (

        <div className="w-full  h-full">
          <div className="container  mx-auto px-4 mt-5">
            <div className='mx-auto flex m-15 px-5 flex-col'>

                            <h2 className="font-semibold text-green-700">
                                Datos bancarios
                            </h2>
                            <p>
                                Todas las cuentas informadas son cuentas bancarias de Invertir en Bolsa S.A. en Banco Industrlal.
                            </p>
                            <p>
                            La acreditación es semi automática y tiene un tiempo de procesamiento promedio de 2 horas.
                            </p>
                            <div className="flex md:flex-row flex-col">
                                <div className="rounded-2xl p-5  bg-gray-50 text-left  lg:flex lg:flex-col lg:justify-left">
                                        <ul className="my-2 w-full  lg:flex-shrink-0">
                                            <li className="py-2">
                                                <h2 className="font-semibold ">
                                                    Cuenta Corriente en pesos
                                                </h2>
                                            </li>
                                            <li className="py-2">
                                                <div className="flex">
                                                    <span  style={{minWidth:"28ch"}}>
                                                        CBU: 3220001813006626130022
                                                    </span>
                                                    <a onClick={()=>{
                                                            navigator.clipboard.writeText("3220001813006626130022")
                                                            setToogle({...tooglex,cbupesos:"currentColor"})
                                                            }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill={toogle?.cbupesos} viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
                                                    </svg>
                                                    </a>
                                                </div>
                                            </li>
                                            <li className="py-2">
                                                <div className="flex">
                                                    <span  style={{minWidth:"28ch"}}>
                                                        ALIAS: IEBPESOSBIND
                                                    </span>
                                                <a onClick={()=>{
                                                        navigator.clipboard.writeText("IEBPESOSBIND");
                                                        setToogle({...tooglex,aliaspesos:"currentColor"})
                                                    }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill={toogle?.aliaspesos} viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
                                                </svg></a></div>
                                            </li>
                                        </ul>
                                </div>
                                <div className="rounded-2xl p-5 md:ml-5 bg-gray-50 text-left  lg:flex lg:flex-col lg:justify-left">
                                        <ul className="my-2 w-full  lg:flex-shrink-0">
                                            <li className="py-2">
                                                <h2 className="font-semibold">
                                                    Cuenta Corriente en dólares
                                                </h2>
                                            </li>
                                            <li className="py-2">
                                                <div className="flex">
                                                    <span  style={{minWidth:"28ch"}}>
                                                        CBU: 3220001812006626130038
                                                    </span>
                                                <a onClick={()=>{
                                                        navigator.clipboard.writeText("3220001812006626130038")
                                                        setToogle({...tooglex,cbudolar:"currentColor"})
                                                        }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill={toogle?.cbudolar} viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
                                                </svg></a>
                                                </div>
                                            </li>
                                            <li className="py-2"><div className="flex">
                                                <span  style={{minWidth:"28ch"}}>
                                                    ALIAS: IEBUSDBIND
                                                </span>
                                                <a onClick={()=>{
                                                        navigator.clipboard.writeText("IEBUSDBIND   ");
                                                        setToogle({...tooglex,aliasdolar:"currentColor"})
                                                    }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill={toogle?.aliasdolar} viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
                                                </svg></a>
                                                </div>
                                            </li>
                                        </ul>
                                </div>
                            </div>
                            <div className="mt-10 p-5">
                                <ul>
                                    <li className="py-2"><b>Invertir en Bolsa.</b></li>
                                    <li className="py-2"><span className="mr-1">CUIT:</span>30-71237262-8</li>
                                    <li className="py-2"><span className="mr-1">Registro en CNV:</span>246</li>
                                    <li className="py-2"><span className="mr-1">Registro en ROFEX:</span>313</li>
                                    <li className="py-2"><span className="mr-1">Registro en ByMA:</span>203</li>
                                    <li className="py-2"><span className="mr-1">Miembro MAV S.A. </span></li>
                                    <li className="py-2"><span className="mr-1">Agente ACDI:</span>49</li>
                                </ul>
                            </div>
                        </div>
                    </div>
        </div>
    )
}

export default MakeDeposit
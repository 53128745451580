import React, { useState, useEffect } from "react"


const FormSelectArray = ({ title, value, tag, options, handleFormData, error }) => {


    return (
    
        <div className="sm:col-span-4 w-full">
            <label htmlFor={tag} className="block text-sm font-medium leading-6 text-gray-900">
            {title}
            </label>
            {error ? (<div style={{ color: "red" }}>Este es un campo requerido</div>) : ("")}
            <div className="mt-2">
            <select
                type="text"
                name={tag}
                value={value}
                defaultValue={value}
                placeholder={title}
                onChange={handleFormData(tag)}
                style={{ border: error ? "2px solid red" : "" }}
                autoComplete={tag}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
            >
                <option key={0}>Seleccione una cuenta</option>
                { options.map(
                    o => {
                        return (
                            <option key={o.id} id={o.cbu} className="block w-full" >
                            {o.cbu_number } * {o.account_name}  * {o.currency}  
                        </option>
                        )
                    }
                )
                }
            </select>
            </div>
        </div>
    )
}



export default FormSelectArray
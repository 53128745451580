import AccessButton from "./../fields/AccessButton"
import React, { useState } from "react";
import { Link } from 'react-router-dom'

const StepIEB = ({ nextStep, handleFormData, values, setFormData }) =>{


    const [processing, setProcessing] = useState(false)
    const [context, setContext] = useState(false)

    return (
        <>
            <div className="relative flex flex-col">
                <div className="border-t-4 border-green-500 text-emerald-700 p-4 " role="alert">
                        <h3 className="font-bold">Validación de Cuenta IEB+</h3>
                        <p>Vamos a validar tu identidad para poder linkear Dólar Financiero a tu cuenta IEB+</p>

                        {context &&
                            <p className="my-5">
                            <div style={{ color: context.COLOR }}>{context.MESSAGE}</div>
                            </p>        
                        }

                </div>    

                <div className="mt-6 flex gap-x-6 mx-6 flex flex-col items-start">
                <p>Para poder operar Dólar Financiero necesitás una cuenta comitente en IEB+. Esta cuenta además debe estara asociada al manager 218.</p>
                    <p>Si no tenés cuenta podés darla de alta desde este link
                        <Link to="/useraccount/crear_cuenta_comitente">
                                <button className="m-3 rounded-md bg-green-400 text-blask px-3 py-2 text-left text-sm font-semibold text-indigo shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600">
                                    Generar Cuenta Comitente en IEB+
                                </button>
                        </Link>
                    </p>
                    <p>Si ya tenés cuenta avanzá asociada al manager 218 avanzá en el proceso para confirmar la vinculación.</p>
                    
                </div>
                
                    <div className="mt-6 flex items-center justify-end gap-x-6 mr-6">

                        <AccessButton
                            text={'Confirmar'}
                            fieldaction={()=>{nextStep()}}
                            processing={processing}
                            >
                        </AccessButton>
                    
                    </div>
                
            
            </div>
        </>
        )

} 

export default StepIEB
import React, { useEffect, useState, useContext } from "react"

import axios from "axios";

import FormField from "../onboarding/FormField"
import FormSelectArray from "../onboarding/FormSelectArray"



import ProfileContext from "../../context/Profile/ProfileContext.js";

const Money = ({arsCBU, usdCBU}) => {

    const { userProfile } = useContext(ProfileContext)

    const [context, setContext] = useState(null)
    const [processing, setProcessing] = useState(false)
    const [error, setError] = useState(null)

    const [panel, setPanel] = useState('PESOS')

    const [formData, setFormData] = useState({
      withdraw_value:0,
      cbu_number:'',
      currency: panel
    })

    const withdrawRequest = () => {

      if (formData.cbu_number === "") {

        setContext({
          COLOR:"red",
          MESSAGE: "Por favor eliga un CBU Válido"
        })

        return false
      }


        axios({
            method: "POST",
            url:"/broker/api/withdrawing",
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token')
            },
            data:{
                ... formData
            }
          })
          .then((response) => {

            setProcessing(true)

            setContext({
              COLOR:"green",
              MESSAGE: response.data.msg
            })

            setFormData({
              withdraw_value:0,
              cbu_number:'',
              currency: panel
            })

          }).catch((error)=>{
            if (error.response.data?.hook) {
                
              if (error.response.data.message) {
                setContext({
                COLOR:"red",
                MESSAGE: error.response.data?.message}
                )
              }
            }
          })
    }

    const [arsAvailable, setArsAvailable] = useState(0)
    const [usdAvailable, setUsdAvailable] = useState(0)


    const handleCBU = input => e => {
      const { value, id } = e.target;

      const index = e.target.selectedIndex;

      let cbu_number

      if (formData.currency == 'PESOS' && arsCBU.length ) {
        cbu_number = arsCBU[index-1]
      }
      if (formData.currency == 'DOLARES' && usdCBU.length ) {
        cbu_number = usdCBU[index-1]
      }

      //updating for data state taking previous state and then adding new value to create new object
      setFormData(prevState => ({
        ...prevState,
        cbu_number: cbu_number.cbu_number,
        cbu_number_option: value
       }));
    }
    
    const handleAmmount = input => e => {

      // input value from the form
      const { value } = e.target;

      let maxvalue = 0

      let r =  /^-?\d*\.?\d*$/; // /^\d+$/;

      const reg = new RegExp(r)

      if(reg.test(value)) {

      if (formData.currency == 'PESOS') {
        maxvalue = arsAvailable
      }
      if (formData.currency == 'DOLARES') {
        maxvalue = usdAvailable
      }

      if (!(value > maxvalue)) {
        maxvalue = value
      }
    }
  
      //updating for data state taking previous state and then adding new value to create new object
      setFormData(prevState => (
        {
      ...prevState,
      [input]: maxvalue
      }));
    
    }

    const setMaxAmount = (field, maxvalue) => {
      setFormData(prevState => ({
          ...prevState,
          [field]: maxvalue
      }));
    }
  
    const toogle = (too) => {

      setFormData(prevState => (
          {
        ...prevState,
        withdraw_value:0, 
        currency: too
        }))

        setPanel(too)
    }

    useEffect(()=>{

        setArsAvailable(userProfile.pp?.retiro_pesos)
        setUsdAvailable(userProfile.pp?.retiro_mep)

    },[userProfile.pp.pesos_0,userProfile.pp?.pesos_1,userProfile.pp?.pesos_2,userProfile.pp?.dolarmep_0,userProfile.pp?.dolarmep_1,userProfile.pp?.dolarmep_2])

    return (
      
      <div className="rounded-2xl p-5  bg-gray-50 text-left  lg:flex lg:flex-col lg:justify-left">                                    

              {(context && !processing) &&
                        <p className="my-5">
                            <div style={{ color: context.COLOR }}>{context.MESSAGE}</div>
                        </p>        
              }

              { processing ?
                <div className="my-5">

                  <button variant="primary" type="submit" className="mt-10 block rounded-md bg-emerald-600 px-3 py-2 text-center font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                  onClick={()=>setProcessing(false)}>
                    Nueva Transferencia
                  </button>
                </div>
              :
                <div className="my-5">
                  <p><b>Seleccioná la moneda de la cuenta que vas a retirar:</b></p>

                    <div>

                      {panel == 'PESOS' &&
                            <div> 
                                <ul>
                                  <li className="flex flex-row my-3">
                                  <button 
                                  className="block rounded-md py-2 text-center font-semibold text-green-500 shadow-sm px-3 py-2">
                                    Pesos
                                  </button>
                                  <button onClick={()=> {toogle('DOLARES')}}
                                    className="block  rounded-md bg-gray-500 px-3 py-2 font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600">
                                    Dólares
                                  </button>
                                  </li>
                                <li className=" my-3">
                                  <div>El retiro se acreditará en las próximas horas.</div>
                                </li>
                                <li className=" my-3">
                                  <div>

                                  <FormSelectArray 
                                      title={'Cuenta bancaria retiro'}
                                      value={formData.cbu_number_option}
                                      tag={'cbu_number_option'}
                                      options={arsCBU}
                                      handleFormData={handleCBU}
                                      error={error}/>

                                  </div>
                                </li>
                                  <li className=" my-3">Saldo disponible:</li>
                                  <li className=" my-3"><span className="block text-lg font-semibold text-green-500 shadow-sm px-3 py-2" 
                                    onClick={() => {setMaxAmount('withdraw_value', arsAvailable)}} >AR$ {arsAvailable} </span> 
                                   <FormField title={"Pesos a retirar"} value={formData.withdraw_value} tag={"withdraw_value"}  handleFormData={handleAmmount} error={error} />
                                  </li>
                                </ul>
                            </div>
                    }
                      {panel == 'DOLARES' &&
                    
                              <div> 
                              <ul>
                                  <li className="flex flex-row my-3">
                                  <button onClick={()=> {toogle('PESOS')}}
                                  className="block rounded-md bg-gray-500 px-3 py-2 text-center font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600">
                                    Pesos
                                  </button>
                                  <button 
                                  className="block rounded-md py-2 text-center font-semibold text-green-500 shadow-sm px-3 py-2">
                                    Dólares
                                  </button>
                                </li>
                                <li className=" my-3">
                                </li>
                                <li className=" my-3">
                                  <div>

                                  <FormSelectArray 
                                      title={'Cuenta bancaria retiro'}
                                      value={formData.cbu_number_option}
                                      tag={'cbu_number'}
                                      options={usdCBU}
                                      handleFormData={handleCBU}
                                      error={error}/>

                                  </div>
                                </li>

                                <li className=" my-3">Saldo disponible:</li>
                                  <li className=" my-3"><span className="block text-lg font-semibold text-green-500 shadow-sm px-3 py-2" 
                                  onClick={() => {setMaxAmount('withdraw_value', usdAvailable)}} >USD$ {usdAvailable} </span> 
                                   <FormField title={"Dólares a retirar"} value={formData.withdraw_value} tag={"withdraw_value"}  handleFormData={handleAmmount} error={error} />
                                  </li>
                              </ul>

                          </div>
                        }


                          <div className="mt-6 flex items-center justify-end gap-x-6 mr-6">
                            <button variant="primary" type="submit" className="mt-10 block rounded-md bg-emerald-600 px-3 py-2 text-center font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                                onClick={withdrawRequest}
                                >
                                Confirmar
                            </button>
                          </div>
                    </div>


                </div>
              }

      </div>
    )
}


export default Money
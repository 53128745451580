export const sellEstimation = (bidUsd, askArs, cashAvailable, dm=0.0002, buffer=0.004, comisell=0.0081) => {
    
    let side1Q = 0
    let side1Amount = 0
    let totalExpense = 0
    let side2Q = 0

    if (dm > 0.001|| buffer > 0.005 || comisell > 0.01) {
        return {side1Q, side1Amount, totalExpense, side2Q}
    }

    if (bidUsd && askArs && cashAvailable) {
        let ppc = Math.round(cashAvailable / (1+dm) * 100) / 100
        bidUsd = Math.floor((bidUsd * (1+buffer+dm)) * 10) / 1000
        side1Q = Math.floor(ppc / bidUsd)
        side1Amount = Math.floor( side1Q * bidUsd * 100) / 100
        totalExpense = Math.floor(side1Amount * 100) / 100
        askArs = Math.floor((askArs  / (1+comisell)) * 100) / 100
        side2Q =  Math.floor(side1Q * askArs) / 100

    }

    return {side1Q, side1Amount, totalExpense, side2Q}

}

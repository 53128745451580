import React, { useState} from 'react'

import dolarsomeclicks2 from './../images/dolarsomeclicks.2.png'

const questions = [
  {
    id: 0,
    pregunta: "¿Qué es el Dólar MEP?",
    texto: "El Dólar MEP (Mercado Electrónico de Pagos) se obtiene al operar títulos públicos y privados que cotizan tanto en pesos como en dólares en el Mercado de Valores. Siguiendo las regulaciones aplicables del momento."
  },
  {
    id: 1,
    pregunta: "¿Es legal el Dólar MEP?",
    texto: "El Dólar MEP es 100% legal, pero tiene restricciones que deben seguirse según lo definido por la CNV y el BCRA, entre otros. En Dólar Financiero simplificamos esta operación para que la resuelvas con un par de clicks."
  },
  {
    id: 2,
    pregunta: "¿Hay algún requisito bancario?",
    texto: "Es fundamental tener al menos una cuenta bancaria en pesos y otra en dólares para operar. Al ser una transacción electrónica, todas las operaciones de ingreso y retiro de divisas se realizan por transferencia bancaria."
  },
  {
    id: 3,
    pregunta: "¿Necesito una cuenta comitente?",
    texto: "Sí, para operar en Dólar MEP necesitas una cuenta comitente CNV, la cual te permite comprar y vender títulos públicos. Al registrarte en Dólar Financiero, podrás abrir tu cuenta en Invertir en Bolsa, el Broker Líder del Mercado Financiero Argentino."
  },
  {
    id: 4,
    pregunta: "¿Qué hace Dólar Financiero?",
    texto: "Dólar Financiero facilita la operación de Dólar MEP. Gestionamos las operaciones de compra y venta para que tus divisas se acrediten en tu cuenta comitente IEB de manera simple y sencilla."
  },
  {
    id: 5,
    pregunta: "¿Cómo ingreso mis fondos para operar?",
    texto: "Al registrarte y crear tu cuenta comitente, encontrarás la opción para informar tus cuentas y acceder a los números de cuentas bancarias de IEB a las que vas a transferir. Cuando transferís, tus fondos se acreditarán en pocos minutos."
  },
  {
    id: 6,
    pregunta: "¿Cómo genero la orden de Compra y Venta?",
    texto: "Al acceder a Dólar Financiero verás la opción de Comprar y Vender Dólar. Está disponible en días hábiles de 11 a 16.15. Tus divisas se acreditarán según el plazo de liquidación de cada operación."
  },
  {
    id: 7,
    pregunta: "¿Cuándo se acreditan tus divisas?",
    texto: "Si compras Dólar en Contado Inmediato, los dólares estarán al día hábil siguiente, ya que la CNV exige un día de espera entre la liquidación de la compra y la venta. Si vendes Dólar, verás la acreditación en minutos."
  },
  {
    id: 8,
    pregunta: "¿Cómo retiro mis fondos?",
    texto: "Una vez acreditadas las divisas, las verás reflejadas e informadas en tu Cuenta Comitente. Desde ese momento, puedes retirarlas con un simple pedido de retiro bancario."
  },
  {
    id: 9,
    pregunta: "¿Puedo operar más de una vez por mes?",
    texto: "Disponiendo de fondos lícitos y siguiendo las regulaciones, podés operar con Dólar Financiero todas las veces que quieras. Al hacer múltiples retiros, recuerda mantener informado a tu banco o utilizar cuentas en más de una Entidad. "
  }
];



const Multistep = () => {

    const[page, setPage] = useState(0)

    const[progress, setProgress] = useState([0,1,2,3,4,5,6,7,8])

    // Que vamos a comprar? - Contacto - Bonistas
  

  return (

   
    <div className='mx-auto'>
    <section className="relative isolate overflow-hidden bg-white px-6 py-5 sm:py-5 lg:px-8 ring-5 ring-inset ring-gray-900 rounded-2xl mt-5" >
    <img src={dolarsomeclicks2} className="mx-auto" style={{maxWidth:"200px"}}/> 
    <div className="w-full text-center flex flex-row  ">
      <h1 className="my-4 mx-auto text-4xl leading-none tracking-tight text-gray-900 md:text-4xl lg:text-4xl ">Check-list <span className="text-green-600">Dólar MEP</span></h1>
    </div>
    
    <div className="justify-center rounded-md shadow-sm"
        style={{margin:'10px auto'}} 
        aria-label="Pagination">      
      </div>

      <div className="mx-auto max-w-2xl lg:max-w-4xl text-center" >
          <h1 className="mb-4 text-3xl font-extrabold leading-none tracking-tight text-gray-900"  style={{
            minHeight:"60px"}}>
            {questions[page].pregunta}
          </h1> 

          <div className="flex justify-center" style={{justifyContent: 'center'}}>
            {progress.map(p => (
              p == (page)
                ? <button key={p} href="#"  aria-current="page" 
                className="z-10  items-center bg-emerald-600 md:px-4 px-3 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600">
                  {p+1}
                </button>
                :
                <button key={p} onClick={()=>setPage(p)}  
                className="items-center  md:px-4 px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                  {p+1}
                </button>
            ))}
          </div>

          <figure className="mt-5 ">
            <div>
              <blockquote className="text-left text-xl leading-8 text-gray-900 sm:text-2xl sm:leading-9">
                <div className="min-h-350 sm:min-h-200"style={{
                   maxWdth: "300px", /* Ancho máximo permitido */
                   overflowWrap: "break-word",
                }}>{questions[page].texto}</div>
              </blockquote>

            </div>

            <figcaption className="mt-10">
                <svg viewBox="0 0 2 2" width={3} height={3} aria-hidden="true" className="fill-gray-900">
                  <circle cx={1} cy={1} r={1} />
                </svg>
              <div className="mt-4 flex items-center justify-center space-x-3 text-base">
                

              </div>
            </figcaption>
          </figure>
        </div>
      <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20" />
      <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-emerald-600/10 ring-1 ring-emerald-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />

    </section>
    </div>
  );
}

export default Multistep
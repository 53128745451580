import React, { Component }  from 'react';

const AccessInput = ({tag, text, ftype, fieldaction, processing}) => {

    return (
        <>{processing ? 
                <input
                    className="block w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                    type={ftype}
                    disabled
                />

            :
                <input
                    id={tag}
                    name={tag}
                    onChange={fieldaction} 
                    type={ftype}
                    text={text} 
                    value={text}
                    //autoComplete="email"
                    required
                    className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-green-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                  />

        }</>
    )
}


export default AccessInput
import React, { useState, useEffect } from "react";

import FormSelect from "./FormSelect"

import axios from "axios";

// creating functional component ans getting props from app.js and destucturing them
const StepTree = ({ nextStep, prevStep, handleFormData, values }) => {
  
  //creating error state for validation
  const [error, setError] = useState(false)
  const [context, setContext] = useState(false)
  const validateState = async() => {

    axios({
      method: "POST",
      url:"/broker/api/onboarding_compliance",
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      },
      data:{
        PEP: values.PEP,
        FATCA: values.FATCA,
        SujetoObligado:values.SujetoObligado,
        LivesAbroad:values.LivesAbroad
      }
    })
    .then((response)=>{
      nextStep();
    })
    .catch((error)=>{
      if (error.response.data?.msg) {
        setContext({
            KIND:'ALERT',
            COLOR:'RED',
            MESSAGE:error.response.data.msg
          })
    }
    })



  }

  const submitFormData = async() => {

    if (
      (values.PEP !== null && values.PEP > -1 ) &&
      (values.FATCA !== null && values.FATCA > -1 ) &&
      (values.SujetoObligado !== null && values.SujetoObligado > -1 ) &&
      (values.LivesAbroad !== null && values.LivesAbroad  > -1 ) // || (values.civil_state) || (values.date_of_birth)
    ) {
      setContext(false)
      setError(false);
      validateState()
    } else {
      setError(true);
    }
  }

  return (
    
    <div className="relative">
    <div className="border-t-4 border-green-500 text-emerald-700 p-4 " role="alert">
          <h3 className="font-bold">¿Verificás algunas de estas condiciones?</h3>

        <p>
          {error ? (<div style={{ color: "red" }}>Todos los campos son requeridos</div>) : ("")}
        </p>
      </div>

      {context &&
              <p className="my-5">
                <div style={{ color: context.COLOR }}>{context.MESSAGE}</div>
              </p>        
          }
        
      <div className="p-4 grid grid-cols-1 ">
          <div className="gap-x-6 gap-y-8" style={{minHeight:"350px"}}>
            
              <FormSelect 
                title={'¿Sos una "PERSONA POLÍTICAMENTE EXPUESTA - (PEP)"?'}
                value={values.PEP}
                tag={'PEP'}
                enum_tag={'BINARIO'}
                handleFormData={handleFormData}
                error={error}/>

              <FormSelect 
                title={'¿Sos "FATCA"?'}
                value={values.FATCA}
                tag={'FATCA'}
                enum_tag={'BINARIO'}
                handleFormData={handleFormData}
                error={error}/>

              <FormSelect 
                title={'¿Sos "Sujeto Obligado"?'}
                value={values.SujetoObligado}
                tag={'SujetoObligado'}
                enum_tag={'BINARIO'}
                handleFormData={handleFormData}
                error={error}/>

              <FormSelect 
                title={'¿Tenés "Residencia Fiscal" en el exterior?'}
                value={values.LivesAbroad}
                tag={'LivesAbroad'}
                enum_tag={'BINARIO'}
                handleFormData={handleFormData}
                error={error}/>


            </div>

            <hr></hr>
            <p className="text-left">
                La respuesta tiene carácter de declaración jurada. Al darle click a <b>Confirmar</b> estás aceptando dichas respuestas.
              </p>

            <div className="mt-6 flex items-center justify-end gap-x-6 mr-6">
              
              
              <button variant="primary" type="submit" className="mt-10 block rounded-md bg-gray-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                onClick={prevStep}
                >
                Regresar  
              </button>


              <button variant="primary" type="submit" className="mt-10 block rounded-md bg-emerald-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                onClick={submitFormData}
                >
                Confirmar
              </button>

            </div>
        </div>
      </div>
  );
};

export default StepTree;
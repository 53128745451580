import React, {useState} from 'react'
import Consent from "./Consent"
import AccessButton from "./../fields/AccessButton"

const StepOnePopUp = ({ openPopUp, closePopUp, processing, consentFormData }) => {

  const handlelosePopUp = (e) => {
    if (e.target.id === 'ModelContainer') {
      closePopUp();
    }
  }
  
  const [showTerms, setShowTerms] = useState(false)

  if (openPopUp !== true) return null

  return (
    <div
      id='ModelContainer'
      onClick={handlelosePopUp}
      className='p-4 grid grid-cols-1 '>
      <div 
        className='gap-x-6 gap-y-8' style={{minHeight:"300px"}}>
        <div
          className='w-full justify-center items-center pt-5'>
            <p>Al darle click a <b>Confirmar</b> estás dando conformidad al:</p>
            <ul className="list-disc mb-5">
                <li><a className="text-blue-600 underline" onClick={()=>setShowTerms(!showTerms)}>Consentimiento Informado</a> para el <b>tratamiento de tus datos</b>.</li>
                <li>Al <b>proceso de apertura de Cuenta Comitente en Invertir en Bolsa S.A. (IEB)</b> que es agente de Compensación y Liquidación y Agente de Negociación Integral registrado en CNV bajo el N° 246. Tu Manager asignado será David Andrés Cervi, Matrícula CNV 1546.</li>
            </ul>
            <p>¿Ya tenés cuenta en IEB? </p>Si en el proceso de apertura detectamos una cuenta abierta asociada a tus datos, solo validaremos tu identidad para vincular esa cuenta al usuario de este servicio.
        </div>
        <div>

        </div>      
        <div>
            <Consent showTerms={showTerms}/>
        </div>
      </div>
      <hr className="mt-10"></hr>
          <div className="mt-6 flex items-center justify-end gap-x-6 mr-6">
              <button variant="primary" onClick={()=>closePopUp()} type="submit" className="mt-10 block rounded-md bg-gray-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                >
                Cancelar
              </button>
                <AccessButton
                    text={'Confirmar'}
                    fieldaction={()=>{consentFormData()}}
                    processing={processing}
                    >
                  </AccessButton>
          </div>

    </div>
  )
}

export default StepOnePopUp
import React, { useState, useEffect } from "react"

import FIELD_ENUMS from "./FieldEnums"

const FormSelect = ({ title, value, tag, enum_tag, handleFormData, error }) => {

    const [options, setOptions] = useState(FIELD_ENUMS[enum_tag])

    return (
    
        <div className="sm:col-span-4">
            <label htmlFor={tag} className="block text-sm font-medium leading-6 text-gray-900">
            {title}
            </label>
            <div className="mt-2">
            <select
                type="text"
                name={tag}
                value={value}
                defaultValue={value}
                placeholder={title}
                onChange={handleFormData(tag)}
                style={{ border: (error && (value == -1 || value == null ) ) ? "2px solid red" : "" }}
                autoComplete={tag}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
            >
                {
                    Object.keys(options).map(key => (
                        <option key={key} value={options[key].id}>
                            {options[key].label}
                        </option>
                    ))
                }
                
            </select>
            </div>
        </div>
    )
}



export default FormSelect
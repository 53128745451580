import { GET_ASSETS } from "../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ASSETS:
      return {
        ...state,
        assets: payload,
      };
    default:
      return state;
  }
};
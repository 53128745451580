import React, {useEffect, useState} from "react";
import { Link } from 'react-router-dom'


const FieldFormat = ({label, value}) => {
    return (
        <div className="md:flex md:items-center mb-6">
                    <div className="md:w-1/3">
                    <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                        {label}
                    </label>
                    </div>
                    <div className="md:w-2/3">
                    <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name" type="text" value={value} disabled="true"/>
                    </div>
                </div>
    )
}

const ProfilePanel = ({userProfile}) => {

    return (<div className="content-center text-center">

                {userProfile?.account_progress == "FULLFILLED" &&

                    <>
                        <FieldFormat label="Cuenta Comitente" value={userProfile?.codigo_comitente} />

                        <FieldFormat label="Broker" value="Invertir en Bolsa" />

                        <hr className="mb-5"></hr>
                    </>

                }

                {userProfile?.account_progress == "DATA_GATHERING" &&
                    <div className="text-left py-5">
                        Debés completar la información necesaria para poder abrir tu cuenta comitente
                        <hr className="mb-5"></hr>
                    </div>
                }

                { userProfile?.account_progress == "CREATION_NEEDS_IEB_REVIEW" &&
                    <div className="text-left text-red-800 py-5">
                        Necesitamos validar tus datos para proceder a la apertura de tu cuenta. Nuestro equipo se pondrá en contacto con vos a la brevedad.
                        <hr className="my-5"></hr>
                    </div>
                }


                { userProfile?.account_progress == "CREATION_AWAITS_IEB_RESPONSE" &&
                    <div className="text-left text-red-800 py-5">
                        Estamos generando tu cuenta comitente. En segundos recibirás la confirmación con los resultados.
                        <hr className="my-5"></hr>
                    </div>
                }


                <FieldFormat label="Email" value={userProfile?.email} />

                <FieldFormat label="Nombre" value={userProfile?.name} />

                <FieldFormat label="Apellido" value={userProfile?.last_name} />
            
            </div>)

}


export default ProfilePanel
import { useState, useEffect, useReducer } from 'react';
import axios from "axios";
import jwt_decode from "jwt-decode";
import ProfileState from './ProfileState.js'

function useToken() {

  //  const {clearProfile} = ProfileState()

  function getToken() {
    var userToken = localStorage.getItem('token');
    
    try{
        var decoded = jwt_decode(userToken);
        if (decoded?.exp < new Date()/1000) {
          localStorage.removeItem("token");
          localStorage.clear();
          userToken = null
        }
      } catch (err) {
        userToken = null
      }

    return userToken && userToken
  }

  const [token, setToken] = useState(getToken());

  const validToken = () => {

    var userToken = localStorage.getItem('token');

    try
      {
      var decoded = jwt_decode(userToken);

      if (decoded?.exp > new Date()/1000) {

        return true

      }} catch (err) {
        return false
      }

    return false
  }

  function saveToken(userToken) {
    setToken(userToken);
    localStorage.setItem('token', userToken);
  };

  function removeToken() {
    localStorage.removeItem("token");
    localStorage.clear();
    setToken(null);
  }

const logMeOut = async() => {

      axios({
        method: "POST",
        url:"/broker/api/logout",
      })
      .then((response) => {
         removeToken()
         window.location.replace('../');
      }).catch((error) => {
        removeToken()
        // clearProfile()

      })}


  return {
    saveToken,
    logMeOut,
    token,
    validToken,
    removeToken
  }

}

export default useToken;

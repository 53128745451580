import React, { useState, useEffect, useContext } from "react"
import OperateDissclaimer from "./OperateDissclaimer.js"
import OperateDDJJ from './OperateDDJJ.js'
import PricesContext from "../../context/Prices/PricesContext.js";
import ProfileContext from "../../context/Profile/ProfileContext.js";

import { Link } from 'react-router-dom'

import { default as axios } from 'axios';

import { buyEstimation } from "./utils/buyEstimation.js";

const OrderBuyGenerate = ({
    source,
    context,
    setContext,
    setOperationId,
    setResponsehook,
    nextStep
    }) => {
  
    const {userProfile, fees} = useContext(ProfileContext)

    const [pp, setPP] = useState(userProfile?.pp)

    const [isUser, setIsUser] = useState(userProfile?.has_account)

    useEffect(()=>{
        setPP(userProfile.has_account)
        setIsUser(userProfile?.has_account)
    },[userProfile])


    const {prices} = useContext(PricesContext)

    const [ordering, setOrdering] = useState({})

    useEffect(()=>{
        setOrdering(prices[source])
    },[source, prices])

    const [showTerms, setShowTerms] = useState(false)
    const [showDDJJ, setshowDDJJ] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [values, setValues] = useState(0)
    
    const [qusds, setQusds] = useState(0)
    const [purchaseQ, setPurchaseQ] = useState(0) // Purchase Quantity
    const [purchaseI, setPurchaseI] = useState(0) // Purchase Invoice
    const [CostoTotal, setCostoTotal] = useState(0) // Purchase Invoice

    const [consented, setConsented] = useState(null)
    const [ddjj, setDDJJ] = useState(null)
    const [confirmed, setConfirmed] = useState(null)
    const [terms, setTerms] = useState(null)



    useEffect(()=>{

        setValues(pp.pesos_0)
        setPP(userProfile?.pp)

        estimateBuy()    
        
    },[pp.pesos_0,pp?.pesos_1,pp?.pesos_2,pp?.dolarmep_0,pp?.dolarmep_1,pp?.dolarmep_2])


    function handleChange(event) { 
        
        setContext(false)
        
    let v
    
    try {

        if (event.target.value.length === 0) {
            v = 0
        } else {
            v = parseFloat(event.target.value)
        }

        if (v < 0) {
            v = 0
        }

        if (parseFloat(v) > parseFloat(pp.pesos_0)) {
            v = pp.pesos_0
        } else {
            v = event.target.value
        }
      
    } catch (err) {
        v = 0
    }

    setValues(v)

    }

    function setMax() {

        setContext(false)
        
        setValues(pp.pesos_0)

    }

    function estimateBuy() {
        
        let {side1Q, side1Amount, totalExpense, side2Q } = buyEstimation(ordering?.bidArs, ordering?.askUsd, values)

        setPurchaseQ(side1Q)
        setPurchaseI(side1Amount)
        setCostoTotal(totalExpense)
        setQusds(side2Q)

    } 

    const operationConfirm = () => {

        setContext(false)

        if (purchaseQ == 0) { 
            setContext({
                COLOR:"red"
                ,MESSAGE:'La operación propuesta no alcanza el monto mínimo para operar.'
            })
                setProcessing(false)
                return true
        }

        if (!consented) { 
            setContext({
                COLOR:"red"
                ,MESSAGE:'Debés aceptar los términos de la operación y realizar la declaración jurada para poder operar en estas condiciones.'
            })

                setProcessing(false)
                return true
        }
        
        setProcessing(true)

        axios({
            method: "post",
            url:"/broker/api/operate_buyusd_user_confirm",
            data:{
                ticker: ordering.ticker,
                settlement: ordering.settlement,
                source: ordering.source,
                order_amount: purchaseI,
                order_quantity:purchaseQ,
                order_price: Math.round(ordering?.bidArs*1.0045)
            },
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token')
            }
          })
          .then((response) => {

            if (response.data?.hook) {
                setResponsehook(response.data.hook)
            }
            
            if (response.data.hook == 'order_confirmed') {

                setOperationId(response.data.data.id)
                setConfirmed(response.data.data.order_confirmed)
                setContext({
                     COLOR:"green"
                     ,MESSAGE:response.data.msg
                })
                nextStep()

            }
            else {
                setContext({
                    COLOR:"red"
                    ,MESSAGE:response.data.msg
                })
            }
            setProcessing(false)
    
          }).catch((error) => {
            if (error?.response?.data?.hook) {
                setResponsehook(error.response.data.hook)
                
            }
              setOperationId(false)
              setProcessing(false)
        })



    }
    
    useEffect(()=>{

        estimateBuy()
      },[
        values
      ])
    
    useEffect(()=>{
        estimateBuy()

        if (values > pp.pesos_0) {
            setValues(pp.pesos_0)
        }

      },[
        pp.pesos_0,
        pp.pesos_1,
        pp.pesos_2,
        pp.pesos_3,
        pp.dolarmep_0,
        pp.dolarmep_1,
        pp.dolarmep_2,
        pp.dolarmep_3,
        pp.dolarcable_0,
        pp.dolarcable_1,
        pp.dolarcable_2,
        pp.dolarcable_3

      ])


    return (
        (prices && prices != []) && <>
            <div className="mx-auto max-w-xl md:px-2 text-left ">
            <h2 className="font-semibold text-green-700 mt-10 mb-3">Compra de Dólar mediante {ordering?.label}</h2>
            {context &&
                <div className="my-5">
                    <div style={{ color: context.COLOR }}>{context.MESSAGE}</div>
                </div>        
            }

            <div className="border-b border-gray-900/10 pb-5">

            <>
            <div>
                <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                    ¿Cuanto querés operar?
                </label>
                <div className="relative mt-2 rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span className="text-gray-500 sm:text-sm">ARS</span>
                    </div>
                    <input
                    type="text"
                    data-testid="operate_amount"
                    name="pValue" onChange={handleChange}  
                    value={values}
                    className="block w-full rounded-md border-0 py-1.5 pl-12 pr-7 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 text-right focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="0.00"
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center">
                    <label htmlFor="currency" className="sr-only">
                        Currency
                    </label>
                    </div>
                </div>

                <div className="w-full flex items-end  ">
                <button
                        id="currency"
                        name="currency"
                        onClick={()=>setMax()}
                        className="h-full rounded-md border-0 bg-transparent py-0 px-7 text-center text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm font-bolder ml-auto">
                            MAX
                    </button>
                </div>
                </div>

            <div>

                <label htmlFor="Dólares" className="block text-sm font-medium leading-6 text-gray-900">
                    Vas a operar ...*
                </label>
                <div className="relative mt-2 rounded-md shadow-sm font-bold text-green-600">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span className="text-gray-500 sm:text-sm  text-green-600">ARS</span>
                    </div>
                    <span className="block w-full rounded-md border-0 py-1.5 pl-12 pr-7 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 text-right focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6  text-green-600">
                        {purchaseI}</span>
                </div>
                <label htmlFor="Dólares" className="block text-sm font-medium leading-6 text-gray-900">
                    Para una compra estimativa de ...*
                </label>
                <div className="relative mt-2 rounded-md shadow-sm font-bold text-green-600">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span className="text-gray-500 sm:text-sm  text-green-600">USD</span>
                    </div>
                    <span className="block w-full rounded-md border-0 py-1.5 pl-12 pr-7 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 text-right focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6  text-green-600">
                        {qusds}</span>
                </div>

            </div>
            
            </>


            <div className="mt-5">
                <label htmlFor="Dólares" className="block text-sm font-medium leading-6 text-gray-900">
                        Detalle
                    </label>
                    {/*<div className="relative mt-2 rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span className="text-gray-500 sm:text-sm">Precio estimado {ordering.ticker}</span>
                    </div>
                    <span className="block w-full rounded-md border-0 py-0.5 pl-12 pr-2 text-gray-900 placeholder:text-gray-400 text-right focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        {((purchasePrice / 100).toFixed(4) )} ARS
                    </span>
                </div>
                <div className="relative mt-2 rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span className="text-gray-500 sm:text-sm">Cantidad {ordering.ticker}</span>
                    </div>
                    <span className="block w-full rounded-md border-0 py-0.5 pl-12 pr-2 text-gray-900 placeholder:text-gray-400 text-right focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        {(purchaseQ)}
                    </span>
                </div>*/}
                <div className="relative mt-2 rounded-md shadow-sm">

                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span className="text-gray-500 sm:text-sm">Comisión y Derechos ARS*</span>
                    </div>
                    <span className="block w-full rounded-md border-0 py-0.5 pl-12 pr-2 text-gray-900 placeholder:text-gray-400 text-right focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        {(((fees.side_1 +0.0001)*100))?.toFixed(2)} %
                    </span>
                </div>
                <div className="relative mt-2 rounded-md shadow-sm">

                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span className="text-gray-500 sm:text-sm">Comisión y Derechos USD*</span>
                    </div>
                    <span className="block w-full rounded-md border-0 py-0.5 pl-12 pr-2 text-gray-900 placeholder:text-gray-400 text-right focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        {(((fees.side_2+0.0001)*100))?.toFixed(2)} %
                    </span>
                </div>

                {/*<div className="relative mt-2 rounded-md shadow-sm">

                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span className="text-gray-500 sm:text-sm">D.M. ARS</span>
                </div>
                <span className="block w-full rounded-md border-0 py-0.5 pl-12 pr-2 text-gray-900 placeholder:text-gray-400 text-right focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    {((purchaseI * 0.001))?.toFixed(2)}
                </span>
                </div>
                */}

            </div>
                
                <div className="mt-5">
                        <input data-testid="confirmed_terms" onChange={()=>{setConsented(!consented);setContext(false)}} type="checkbox" className="mr-2" defaultChecked={consented}/>
                        <span onClick={()=>{setConsented(!consented);setContext(false)}} >
                        Acepto los 
                        <span onClick={()=>setShowTerms(true)} className="px-1 text-blue-600 underline">Términos y Condiciones</span> de la operación y la <span onClick={()=>setshowDDJJ(true)} className="px-1 text-blue-600 underline">Declaración Jurada</span>.
                        </span>
                    <OperateDissclaimer showTerms={showTerms} side={1}/>
                    <OperateDDJJ showDDJJ={showDDJJ} setshowDDJJ={setshowDDJJ}/>
                </div>
                    
            </div>
        </div>


            <div className="mx-auto max-w-xl md:px-2 text-left mt-5 flex items-center justify-end gap-x-2 ">
                                
            { isUser ?
                    <>

                        { processing ?
                            <button 
                            className="my-5 block rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 disabled">
                                <svg aria-hidden="true" role="status" className="inline mr-3 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"></path>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"></path>
                                </svg> Confirmar
                            </button>
                                :
                                <> 
                                <Link to="/useraccount/">
                                    <button className="my-5 block rounded-md bg-slate-400 px-2 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
                                        Cancelar
                                    </button>
                                </Link>   
                                <button 
                                    data-testid="order_confirm"
                                    onClick={()=>operationConfirm()} className="my-5 block rounded-md bg-green-600 px-2 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
                                    Confirmar
                                </button>
                                </>
                            }
                    </>
                :
                    <Link to="/useraccount/crear_cuenta_comitente">
                        <button className="m-3  rounded-md bg-green-600 flex px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-3">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                            </svg>
                            <span className="pl-3">
                                Crear Cuenta Comitente
                            </span>            
                        </button>
                    </Link>
                }
            </div>
        </>
    )
    }

export default OrderBuyGenerate
import React, { useState, useContext, useEffect } from "react"

import Money from './Money.js'
import axios from "axios";

import ProfileContext from "../../context/Profile/ProfileContext.js";

const Withdraw = () => {

    const { userProfile } = useContext(ProfileContext)

    const [context, setContext] = useState(false)
    
    const [arsCBU, setArsCBU] = useState([])
    const [usdCBU, setUsdCBU] = useState([])

    function getCBUs() {
        axios({
          method: "GET",
          url:"/broker/api/user/cbu",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
        .then((response) => {
            setArsCBU(response.data.data.varsCBU)
            setUsdCBU(response.data.data.vusdCBU)
        }).catch((error) => {

        })
      
      }

    useEffect(() => {
        getCBUs()

        if (
            (userProfile?.arsCBU ==  0 ||
            userProfile?.usdCBU  == 0)

        ) {
            setContext({
            //    COLOR: "RED",
            //    MESSAGE: "Es requisito para poder retirar tus dólares tener una cuenta bancaria en Dólares registrada a tu nombre. La cantidad de retiros disponibles por mes depende de cada banco."
            })

        } else {
            setContext(false)
        }

        },[
            userProfile.arsCBU,
            userProfile.usdCBU
        ])

    return (
        <div className="w-full  h-full">
          <div className="container  mx-auto px-4 mt-5">
            <div className='mx-auto flex m-15 px-5 flex-col'>
                <h2 className="font-semibold text-green-700">Retiros de Efectivo</h2>

                <p>Es requisito para poder retirar tus divisas tener una cuenta bancaria en Dólares registrada.</p>
                <p>Tu banco puede limitar la cantidad de transferencias de dólares que podés recibir por mes.</p>

                {context &&
                    <p className="my-5">
                    <div style={{ color: context.COLOR }}>{context.MESSAGE}</div>
                    </p>
                }

                    <Money
                        arsCBU={arsCBU}
                        usdCBU={usdCBU}
                    ></Money>

                </div>
            </div>      
        </div>
    )
}

export default Withdraw
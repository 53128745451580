import React, { useState, useContext, useEffect } from "react"

import BankRibbon from './BankRibbon.js'
import CBUs from './CBUs.js'
import Withdraw from './Withdraw.js'
import MakeDeposit from './MakeDeposit.js'

import UserSideBar from './../../business/UserSideBar.js'
import OnboardingHook from './../onboarding/OnboardingHook.js'
import ProfileContext from "./../../context/Profile/ProfileContext.js";

import axios from "axios";

const YourBanks = () => {
 
    const {userProfile} = useContext(ProfileContext)

    const [bankPage, setBankPage] = useState('retirar')
    
    const [addaccount, setAddaccount] = useState(false)

    const [open, setOpen] = useState(false)


    return(
        <>
            <div className="relative pt-14 min-h-screen">
                <header className="absolute inset-x-0 top-0 z-50">
                    <UserSideBar
                        open={open}
                        setOpen={setOpen}
                        />
                </header>


                {userProfile?.has_account === false ? 
                    <OnboardingHook />
                :
                    <BankRibbon setBankPage={setBankPage}></BankRibbon>
                }

                {(() => {
                switch (bankPage) {
                case 'depositar':
                    return (<MakeDeposit
                    />)
                case 'registrarcbu':
                    return (
                    <CBUs
                        setBankPage={setBankPage}
                    ></CBUs>)
                default:
                    return (
                    <>
                    <Withdraw
                        setBankPage={setBankPage}
                    />
                    </>   
)
                }
            })()}
                
            </div>
        </>
    )
    
}

export default YourBanks
import React, {useState, useEffect} from "react";
import useToken from '../context/Profile/useToken';
import axios from "axios";

import AccessInput from './fields/AccessInput';
import AccessButton from './fields/AccessButton';

import { Link } from 'react-router-dom'
import Logo from '../business/Logo'

export default function Access() {
  
  const { saveToken } = useToken();

    const [loginForm, setloginForm] = useState({
      email: "",
      password: ""
    })
    const [processing, setProcessing] = useState(false)
    const [message, setMessage] = useState('')

    function logMeIn(event) {

      if (processing) {
        return true
      }

      setProcessing(true)
      axios({
        method: "POST",
        url:"/broker/api/token",
        data:{
          email: loginForm.email,
          password: loginForm.password
         }
      })
      .then((response) => {
        saveToken(response.data.access_token)
        window.location.replace(process.env.REACT_APP_HOME_REDIRECT);
      }).catch((error) => {
        if (error.response) {
          setProcessing(false)
          setMessage(error.response.data.msg)
          }
      })

      setloginForm(({
        email: "",
        password: ""}))

      event.preventDefault()
    }

    function handleChange(event) {
      setMessage('') 
      const {value, name} = event.target
      setloginForm(prevNote => ({
          ...prevNote, [name]: value})
      )}

    return (
      <>
        {/*
          This example requires updating your template:
  
          ```
          <html className="h-full bg-white">
          <body className="h-full">
          ```
        */}
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 lg:px-8 py-5 md:py-12 ">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm  content-center">

            <div className="sm:mx-auto sm:w-full sm:max-w-sm  content-center">
              <Link to="/useraccount/">
                    <Logo classLogo="text-2xl flex items-center"/>
              </Link>
            </div>

            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Accedé a tu cuenta
            </h2>
          </div>

          <div className="sm:mx-auto sm:w-full sm:max-w-sm  content-center">
            <p className="text-red-500">{message}</p>
          </div>
  
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Dirección de Correo
                </label>
                <div className="mt-2">

                  <AccessInput
                    tag={'email'}
                    text={loginForm.email}
                    ftype={'email'}
                    fieldaction={handleChange} 
                    processing={processing}
                  />

                </div>
              </div>
  
              <div>
                <div className="flex items-center justify-between">
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                    Contraseña
                  </label>
                  <div className="text-sm">
                  <Link to="/useraccount/reestablecer_acceso">

                    <div className="font-semibold text-emerald-600 hover:text-green-500">
                      ¿Olvidaste tu contraseña?
                    </div>
                    </Link>
                  </div>
                </div>
                <div className="mt-2">
                  <AccessInput
                      tag={'password'}
                      text={loginForm.password} 
                      ftype={'password'}
                      fieldaction={handleChange} 
                      processing={processing}
                    />
                </div>
              </div>
  
              <div>
                <AccessButton
                text={'Acceder'}
                fieldaction={logMeIn}
                processing={processing}
                >
                </AccessButton>
              
                
              </div>
            
            <p className="mt-10 text-center text-sm text-gray-500">
              ¿No estás registrado?{' '}
              <Link to="/useraccount/registro">
              <span className="font-semibold leading-6 text-emerald-600 hover:text-green-500">
                Registrate ahora
                </span>
              </Link>
            </p>
          </div>
        </div>
      </>
    )
  }
        
 
import React, { useState, useContext, useEffect } from "react"

import OrderSellGenerate from './OrderSellGenerate.js'

import UserSideBar from '../../business/UserSideBar.js'
import Footer from '../../business/Footer';
import OnboardingHook from '../onboarding/OnboardingHook.js'
import RibbonStats from '../fields/RibbonStats.js'

import OrderConfirm from './OrderConfirm.js'

import { useParams } from 'react-router-dom';

import ProfileContext from "../../context/Profile/ProfileContext.js";

import DFINDissclaimer from './DFINDissclaimer.js'

import usddollar from './../../images/usddollar.png';

const OperateSell = () => {

    let { source } = useParams();

    const {userProfile} = useContext(ProfileContext)
    const [open, setOpen] = useState(false)

    const [step, setstep] = useState(1);
    const nextStep = () => {
        setstep(step + 1);
      };
    
      // function for going to previous step by decreasing step state by 1
      const prevStep = () => {
        setstep(step - 1);
      };

    const [context, setContext] = useState(null)

    const [operationId, setOperationId] = useState(null)

    const [responsehook, setResponsehook] = useState(null)
    
     switch (step) {
        case 1:
        return (

        <div className="relative min-h-screen">
            <div className="relative pt-14 min-h-screen">
                <header className="absolute inset-x-0 top-0 z-50">
                    <UserSideBar  open={open} setOpen={setOpen} />
                </header>
                


                {userProfile?.has_account === false ?

                    <OnboardingHook />

                    :


                    <RibbonStats pp={userProfile?.pp} />
                }

                <div className="min-h-full p-5 text-center lg:justify-center lg:p-5">
                    
                    <OrderSellGenerate
                        context={context}
                        source={source}
                        // handleChange={handleChange}
                        // values={values}
                        // setMax={setMaxBuy}
                        // qusds={qusds}
                        // ordering={ordering}
                        // purchaseQ={purchaseQ}
                        // purchaseI={purchaseI}
                        // purchasePrice={ordering.bidArs}
                        // consented={consented}
                        // setConsented={setConsented}
                        setContext={setContext}
                        setResponsehook={setResponsehook}
                        setOperationId={setOperationId}
                        nextStep={nextStep}
                    >
                    </OrderSellGenerate>
                    </div>
                </div>
            <DFINDissclaimer></DFINDissclaimer>
            <Footer logedIn={true} />
            </div>
        )


        case 2:
        return (

            <div className="relative min-h-screen">
                <div className="relative pt-14 min-h-screen">
                    <header className="absolute inset-x-0 top-0 z-50">
                        <UserSideBar  open={open} setOpen={setOpen} />
                    </header>
                    
                <div className="min-h-full px-5 mt-12 text-center lg:justify-center ">
                    <div className="mx-auto max-w-xl md:px-2 text-left ">
                        {context &&

                            <div className="my-5 flex flex-col" >

                                <img src={usddollar} className="mx-auto" style={{maxWidth:"200px"}}/> 

                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-12 h-12">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z" />
                                </svg>
                                <div style={{ color: context.COLOR }}>
                                    {context.MESSAGE}
                                </div>
                            </div>        
                        }xxx
                        <OrderConfirm
                            context={context}
                            operationId={operationId}
                            side={true}
                            prevStep={prevStep}>
                        </OrderConfirm>xx
                    </div>
                </div>
    
            </div>
            <div className="max-w-2xl mx-auto p-4 mt-5">
                <div className="mx-auto">
                    <p>La compra del Dólar Financiero o Dólar MEP representa la compra de un bono en pesos y su posterior venta en dólares en el mercado financiero.</p>
                    <p>Debido a regulaciones del Banco Central la venta debe hacerse al día hábil siguiente de la compra. Esto lleva a que los precios sean estimativos y que solo se conozcan una vez cerrada la operación.</p>
                    <p>En la venta en dólares se debe comprar Bonos en Dólares respentado plazos de liquidación definidos por el central.</p>
                    <p>Al operar en Dólar Financiero el usuario le da mandato al AP para que opere en cuenta y orden bajo las condiciones informadas.</p>
                </div>
            </div>
            <Footer logedIn={true} />
        </div>



        )
    }

}

export default OperateSell
import React, {useState, useContext} from "react";
import { Link } from 'react-router-dom'

import Logo from './Logo'

import ProfileContext from "../context/Profile/ProfileContext.js";
import useToken from '../context/Profile/useToken';

import CloseButton from '../components/fields/CloseButton'


export default function UserSideBar({ open, setOpen}) {

    const {userProfile} = useContext(ProfileContext)
    const { logMeOut } = useToken()
   
    const [processing, setProcessing] = useState(false)

    const logMeOutOfsite = () => {
        if (processing) {
            return true
          }    
        setProcessing()
        logMeOut()
    }

    const [toogle, setToogle] = useState({cbupesos:'none', aliaspesos:'none',cbudolar:'none', aliasdolar:'none'})

    return (

        <div className='bg-white py-3 fixed top-0 left-0 right-0 shadow-md'>
            <div className='block flex flex-row'>

                <button className='ml-4' onClick={() => setOpen('profile')}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke={userProfile?.email == '' ? 'black' : 'green' } className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                </button>
                <div className="max-w-screen-xl flex flex-wrap items-center justify-between ml-auto mr-5 w-50 text-2xl ">
                    <Link to="/useraccount/panel">
                        <Logo classLogo=""/>
                    </Link>
                </div>

                <div className={`${!open && "hidden"} bg-gray-600/50 min-h-screen w-full fixed top-0 left-0 right-0 backdrop-blur-sm`} onClick={() => setOpen(false)}></div>

                <div className={`${open ? "w-[90%]" : "w-0"} bg-zinc-50 min-h-screen fixed top-0 left-0 transition-all duration-300`} style={{maxWidth:"400px"}}>
                    <div className={`${!open && "hidden"} pt-3`} style={{minWidth:'400px'}}>
                        <button className='ml-4 text-black mb-14' onClick={() => setOpen(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>

                
                <div className="my-4">


                    <Link to="/useraccount/operar_dolar">
                        <button onClick={() => setOpen(false)} className="m-3 flex rounded-md bg-white-600 px-3 py-2 text-center text-sm font-semibold text-indigo shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                            </svg>
                            <span className="pl-3">Operar Dólar</span>
                        </button>
                    </Link>

                    <Link to="/useraccount/tus-activos">
                        <button onClick={() => setOpen(false)}  className="m-3 flex rounded-md bg-white-600 px-3 py-2 text-center text-sm font-semibold text-indigo shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" />
                            </svg>
                            <span className="pl-3">Tus Activos</span>
                        </button>
                    </Link>

                    <Link to="/useraccount/bancos-y-transferencias">
                    <button onClick={() => setOpen(false)}  className="m-3 flex rounded-md bg-white-600 px-3 py-2 text-center text-sm font-semibold text-indigo shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z" />
                        </svg>
                        <span className="pl-3">Transferencias</span>
                    </button>
                    </Link>
                    <Link to="/useraccount/tus-ordenes">
                    <button onClick={() => setOpen(false)}  className="m-3 flex rounded-md bg-white-600 px-3 py-2 text-center text-sm font-semibold text-indigo shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
                        </svg>
                        <span className="pl-3">Operaciones</span>
                    </button>
                    </Link>

                    <Link to="/useraccount/tu_cuenta">
                    <button onClick={() => setOpen(false)}  className="m-3 flex rounded-md bg-white-600 px-3 py-2 text-center text-sm font-semibold text-indigo shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                        <span className="pl-3">
                            {userProfile?.email}
                        </span>
                        </button>
                    </Link>
                       
                    {['NO_PROGRESS','DATA_GATHERING'].includes(userProfile?.account_progress) &&

                        <Link to="/useraccount/crear_cuenta_comitente">
                            <button onClick={() => setOpen(false)}  className="m-3  rounded-md bg-emerald-600 flex px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-3">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                            </svg>

                            <span className="pl-3">
                                Crear Cuenta Comitente
                            </span>            
                        </button>
                        </Link>

                    }

                    {/*(!userProfile?.hasTESTs && userProfile?.has_account && 1 == 0) &&
                    
                    <li className="flex my-3 px-3 ">
                        <div className="flex">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                            </svg>                            
                            <span className="pl-3 text-red-600">
                            Sin Perfil de Inversor
                            </span>
                        </div>
                    </li>*/}

                    {/*!userProfile?.hasCBUs && 1 == 0 &&
                    
                    <li className="flex my-3 px-3 ">
                    <Link to="/useraccount/bancos-y-transferencias">
                    <button onClick={() => setOpen(false)}  className="rounded-md bg-emerald-600 flex px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                            </svg>                            
                            <span className="pl-3 text-red-600">
                                Cuentas Bancarias
                            </span>
                        </button>
                    </Link>
                    </li>*/}

                    <div className="mt-6 flex items-center justify-start md:justify-end gap-x-6 mr-6 px-5 ">

                        <CloseButton text={"Cerrar Sesión"} fieldaction={logMeOutOfsite} processing={processing}>

                        </CloseButton>

                    </div>


                    </div>
                    </div>
                </div>
            </div>
        </div>
   )
  }
        
 
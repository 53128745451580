import React, { useState, useReducer, useEffect } from "react";

import FormSelect from "./FormSelect"

import Webcam from 'react-webcam'

import axios from "axios";


const WebcamComponent = () => <Webcam />

const videoConstraints = {
  width: 200,
  height: 400,
  facingMode: 'environment',
}

// creating functional component ans getting props from app.js and destucturing them
const StepOneDNIBack = ({ nextStep, prevStep, values, setFormData }) => {
  
  const [picture, setPicture] = useState('')
  const [validPic, setValidPic] = useState(null)
  const webcamRef = React.useRef(null)

  const capture = React.useCallback(() => {
    const pictureSrc = webcamRef.current.getScreenshot()

    setPicture(pictureSrc)

    validateState(pictureSrc)

  })

  //creating error state for validation
  const [error, setError] = useState(false)


  const validateState = async(pic) => {


    axios({
      method: "POST",
      url:"/broker/api/onboarding_back_document",
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      },
      data:{
        dni_back_json:pic
      }
      
      // data:{
      //   dni_back_json:values.dni_back_json
      // }
    })
    .then((response)=>{
      
      
      Object.keys(response.data).forEach(function(key) {
        
        setFormData(prevState => (
          {
          ...prevState,
          [key]: response.data[key]
        }));
        setValidPic(true)
      })

      
    })
    .catch((error)=>{
      setValidPic(false)
    })

  }

  const submitFormData = async() => {
    if (
      (validPic)
      
      // || (values.civil_state) || (values.date_of_birth)
    ) {
        validateState()
        //dispatch({type:ACTION_TYPES.CONFIRMED_CHANGES, payload:values})
        nextStep();
    } else {
      setError(true);
    }
  }

  useEffect(()=>{if (values.dni_back_json) setValidPic(true)},[values.dni_back_json])

  return (
    <div className="relative">
    <div className="border-t-4 border-green-500 text-emerald-700 p-4 rounded-xl" role="alert">
          <h3 className="font-bold">Validación de Identidad</h3>
          <p>Tomá una foto del reverso de tu DNI</p>

      <p>
      {error ? (<div style={{ color: "red" }}>Para poder avanzar tenés que validar tu identidad a través de la foto requerida.</div>) : ("")}
      </p>
    </div>

    <div className="p-4 grid grid-cols-1">
    <div className="gap-x-6 gap-y-8 h-4/6	min-h-full"  style={{minHeight:"400px"}}>

        {
          validPic ?
            <>
              <h1>Ya recibimos la imagen del reverso de tu DNI. La estamos validando. Mientras podés continuar con el proceso de registro.</h1>
              
            </>
          :
          <>
            <div id="dni_back_json">
              {picture == '' ? (
                <Webcam
                  audio={false}
                  height={400}
                  ref={webcamRef}
                  width={200}
                  screenshotFormat="image/jpeg"
                  className="mx-auto rounded-2xl"
                  videoConstraints={videoConstraints}
                />
              ) : (
                    <div>
                      <div className="text-center p-10  ">
                      <h3>Esperando validación de Renaper. Puede tardar unos minutos.</h3>
                      
                      <button className="mb-7 mt-1 block rounded-md bg-emerald-600 p-2 mx-auto text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600  flex flex-row">
                        <svg aria-hidden="true" role="status" className="inline mr-3 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"></path>
                          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"></path>
                        </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z" />
                          <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z" />
                        </svg>
                      </button>
                      
                      </div>
                    </div>
              )}
            </div>

              <div className="w-full">
                      <div className="text-center pt-5">
                      {picture == '' &&
                          <>
                          <button onClick={(e) => {
                              e.preventDefault()
                              capture()
                            }} 
                            
                            className="mb-7 mt-1 block rounded-md bg-emerald-600 p-2 mx-auto text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z" />
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z" />
                          </svg>

                          </button>

                          <div>
                            <p>
                              <span className="font-bold pr-2">Sugerencias para tomar la foto de tu Documento</span>
                              Evitá sombras y reflejos, No uses flash. Buscá buena iluminación y apoyá el documento en un fondo liso, Asegurate de que la información sea legible.
                            </p> 
                          </div>
                          </>
                          }
                      </div>
              </div>
          </>
        }

      </div>
      
      <hr className="mt-10"></hr>
              
            <div className="mt-6 flex items-center justify-end gap-x-6 mr-6">
              
              <button variant="primary" type="submit" className="mt-10 block rounded-md bg-gray-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                onClick={prevStep}
                >
                Regresar  
              </button>

              <button variant="primary" type="submit" className="mt-10 block rounded-md bg-emerald-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                onClick={submitFormData}
                >
                Confirmar
              </button>
              
              

            </div>
    </div>
    </div>
  );
};

export default StepOneDNIBack;
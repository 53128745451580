import React,  {useEffect, useState} from "react";

import axios from "axios";


import StepIEB from "./StepIEB.js";
import OwnershipOne from "./StepOne.js";
import OwnershipTwo from "./OwnershipTwo.js";
import StepOneSelfie from "./StepOneSelfie.js";

import UserSideBar from '../../business/UserSideBar.js';

import { Link } from 'react-router-dom'

const Onboarding = () => {
  
  const [open, setOpen] = useState(false)

  const getAccountState = async() => {
    axios({
        method:"post",
        url:"/broker/api/onboarding_state",
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    }).then(response => {

      Object.keys(response.data).forEach(function(key) {

        setFormData(prevState => (
          {
          ...prevState,
          [key]: response.data[key]
        }));
        
     });
    }).catch(error=> {
      console.error("getAccountState",error)
    })

  }

  const [formData, setFormData] = useState({

  })

  useEffect(()=>{
    
    getAccountState()
  },[])
     
  const [missing, setMissing] = useState({})


  const [step, setstep] = useState(1);
  // handling form input data by taking onchange value and updating our previous form data state

      
  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    setstep(step + 1);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setstep(step - 1);
  };


  const handleInputData = input => e => {

        // input value from the form
        const {value } = e.target;

        //updating for data state taking previous state and then adding new value to create new object
        setFormData(prevState => (
          {
        ...prevState,
        [input]: value
    }));

    }
    // javascript switch case to show different form in each step
  switch (step) {
    // case 1 to show stepOne form and passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
    case 1:
      return (
      <div className="relative pt-14 min-h-screenlg:px-8">
            <header className="absolute inset-x-0 top-0 z-50">
                <UserSideBar  open={open} setOpen={setOpen} />
            </header>

            <div className="container mx-auto px-4 mt-5">

            <div className="bg-white py-5 md:py-12 sm:py-10">

              <div md={{ span: 6, offset: 3 }} className="custom-margin col">
                <StepIEB nextStep={nextStep} handleFormData={handleInputData} values={formData} setFormData={setFormData} />
              </div>
            </div>
          </div>
        </div>
      );
    case 2:
      return (
      <div className="relative pt-14 min-h-screenlg:px-8">
            <header className="absolute inset-x-0 top-0 z-50">
                <UserSideBar  open={open} setOpen={setOpen} />
            </header>

            <div className="container mx-auto px-4 mt-5">

            <div className="bg-white py-5 md:py-12 sm:py-10">

              <div md={{ span: 6, offset: 3 }} className="custom-margin col">
                <OwnershipOne nextStep={nextStep} handleFormData={handleInputData} values={formData} setFormData={setFormData} />
              </div>
            </div>
          </div>
        </div>
      );
    case 3:
      return (
        <div className="relative pt-14 min-h-screenlg:px-8">
        <header className="absolute inset-x-0 top-0 z-50">
            <UserSideBar  open={open} setOpen={setOpen} />
        </header>

        <div className="container mx-auto px-4 mt-5">

        <div className="bg-white py-5 md:py-12 sm:py-10">

          <div md={{ span: 6, offset: 3 }} className="custom-margin col">
            <StepOneSelfie context={'ownership'} nextStep={nextStep} values={formData} setFormData={setFormData} />
          </div>
        </div>
      </div>
    </div>
    );

    case 4:
      return (
        <div className="relative pt-14 min-h-screenlg:px-8">
          <header className="absolute inset-x-0 top-0 z-50">
              <UserSideBar  open={open} setOpen={setOpen} />
          </header>
          <div className="container mx-auto px-4 mt-5">
            <div className="bg-white py-5 md:py-12d:py-12d:py-12 sm:py-10">

              <div md={{ span: 6, offset: 3 }} className="col custom-margin">
                <OwnershipTwo nextStep={nextStep} prevStep={prevStep} handleFormData={handleInputData} values={formData} />
              </div>
            </div>
          </div>
        </div>
      );
          // Only formData is passed as prop to show the final value at form submit
    case 5:
      return (
        <div className="relative pt-14 min-h-screenlg:px-8">
        <header className="absolute inset-x-0 top-0 z-50">
            <UserSideBar  open={open} setOpen={setOpen} />
        </header>
          <div className="container mx-auto px-4 mt-5">
            <div className="bg-white py-5 md:py-12 sm:py-10">
              <div className="col   custom-margin"  md={{ span: 6, offset: 3 }}>
                <FF values={formData} />
              </div>
            </div>
          </div>
        </div>
      );
    // default case to show nothing
    default:
      return (
        <div className="App">
        </div>
      );
  }
}


const FF = (values) => {

    return (
        
      <div className="relative">
        <div className="border-t-4 border-green-500 text-emerald-700 p-4 rounded-xl" role="alert">
            <h3 className="font-bold">Solicitud Recibida</h3>
          <p>
            En breve confirmaremos tu solicitud. Mientras tanto podés:
          </p>
          <ul>
            {values.NiveldeRiesgo == 0 &&
            <li>Validar tu perfil de riesgo</li>
            }
            <li>
              <Link to="/useraccount/bancos-y-transferencias">
                    <button className="m-3 flex rounded-md bg-white-600 px-3 py-2 text-center text-sm font-semibold text-indigo shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z" />
                        </svg>
                        <span className="pl-3">Registrar tu CBU para poder ingresar y retirar dinero de tu cuenta</span>
                    </button>
                  </Link>
            </li>
          </ul>
        </div>
      </div>

    )


}


export default Onboarding

import './App.css';
import React from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { io } from "socket.io-client";

import Home from './business/Home';
import ProfilePage from './business/ProfilePage';

import Access from './components/Access';
import YourBanks from './components/banks/YourBanks';
import HomeUser from './components/HomeUser';
import Register from './components/Register';
import Reset from './components/Reset';
import Regain from './components/Regain';
import Onboarding from './components/onboarding/Onboarding';
import Ownership from './components/onboarding/Ownership';
import useToken from './context/Profile/useToken';
import YourAssets from './components/assets/YourAssets';

import BackendUser from './components/backend/User';
import BackendUsers from './components/backend/Users';
import BackendOrders from './components/backend/Orders';
import BackendCalls from './components/backend/Calls';

import YourOrders from './components/orders/YourOrders'

import PricesState from "./context/Prices/PricesState";
import PricesContext from "./context/Prices/PricesContext";
import ProfileContext from "./context/Profile/ProfileContext"
import ProfileState from './context/Profile/ProfileState';
import AssetsContext from "./context/Assets/AssetsContext"
import AssetsState from './context/Assets/AssetsState';
import OrdersContext from "./context/Orders/OrdersContext"
import OrdersState from './context/Orders/OrdersState';

import OperateBuy from './components/operate/OperateBuy';
import OperateSell from './components/operate/OperateSell';

class App extends React.Component    
    { constructor(props) {
      super(props);
      this.state = {
        props: props
      };
      this.socket = null;
      this.userEvent = null;
      this.document = true;

    }

    componentDidMount() {
      document.addEventListener('visibilitychange', this.handleVisibilityChange);
      this.socket = io(process.env.REACT_APP_SOCKET_PATH, 
        { auth: { token: localStorage.getItem('token') },
        reconnect: true,
        cors: { origin: "*" }, transports: ["websocket"],
        query: {
          token: localStorage.getItem('token')
        }})      
        this.socketConnect();
        
      this.props.getProfile();
      this.props.getAssets();
      this.props.getOrders();
        // setInterval(() => {
        //   if (!document.hidden) {
        //     this.props.getProfile();
        //   }
        // }, 60000);

      this.props.getPrices();
        setInterval(() => {
          if (!document.hidden) {
            this.props.getPrices();
          }
          // else {
          //   console.log("tab is hidden for prices")
          // }
        }, 15000);
  
    }

    handleVisibilityChange() {
      // if (!document.hidden) {
      //   console.log("handle handleVisibilityInactive", document.hidden)
      //   //socket.emit('ping');
      //   if (this.intervalId) {
      //     clearInterval(this.intervalId);
      //   }
      // } else {
      //   console.log("handle handleVisibilityActive from tab change", document.hidden)
      //   this.intervalId = setInterval(() => {
      //     console.log("checking when fails failed from tab change". this.props)
      //     this.props.getPrices();
      //   }, 15000);
      // }
    };


    componentDidUpdate(prevProps, prevState) {
      // if (prevState.lastUpdate !== this.state.lastUpdate) {
      //   console.log("lastUpdate from app.js >>", this.state.lastUpdate);
      // }
    }
  
    componentWillUnmount() {
      document.removeEventListener('visibilitychange', this.handleVisibilityChange);
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      this.socketDisconnect()
    }

    socketConnect() {
        
      // this.socket?.on('connect', () => { console.log("connected") });

      // this.socket?.on('disconnect', () => {console.log("socke disconnected")});
      
      // this.socket?.on('connect_error', () => {console.log("socke disconnected")}); 

      this.socket?.on('message', () => {console.log("message")}); 

      this.socket?.on('hook', (data) => { 

        console.log("hook", data)

        this.props.getProfile();
        this.props.getAssets();
        this.props.getOrders();
        
        // if (data?.hook == 'user_profile') {
        //   console.log("hook user_profile", data)
        //   this.props.getProfile();
        //   this.props.getAssets();
        // }
          
        //   if (['get_profile'].includes(data?.hook)) {
        //     console.log("hook get_profile", data)
        //     this.props.getProfile();
        //     this.props.getAssets();
        //   }

        //   if (['user_assets'].includes(data?.hook)) {
        //     console.log("hook user_assets", data)
        //     this.props.getProfile();
        //     this.props.getAssets();
        //   }

        //   if (['purchase_power'].includes(data?.hook)) {
        //     console.log("hook purchase_power", data)
        //     this.props.getProfile();
        //   }

        //   if (['orders_update'].includes(data?.hook)) {
        //     console.log("hook orders_update", data)
        //     this.props.getOrders();
        //     this.props.getAssets();
        //     this.props.getProfile();
        //   }

      })

        // this.userevent = (data) => {
        //   console.log("userevent", data)
        //   this.socket.emit("userevent", data)
        // }

    }

    socketDisconnect() {
      this.socket.disconnect();
    }

    // Define methods like  getProfile, getPrices, logMeOut here
  
    render() {

      const { validToken, userProfile, fees, prices, marketOpen, getPrices, assets, opOrders, mepOrders } = this.props;
      return (
        <BrowserRouter>
            <PricesContext.Provider value={{prices, marketOpen, getPrices}}>
              {!validToken() ?
                <Routes>
                  <Route path="*" element={<Home />}></Route>
                  <Route exact path="/useraccount/" element={<Home />} />
                  <Route exact path="/useraccount/*" element={<Home />} />
                  <Route exact path="/useraccount/acceso" element={<Access />} />
                  <Route exact path="/useraccount/reestablecer_acceso" element={<Regain />} />
                  <Route exact path="/useraccount/registro" element={<Register />} />
                  <Route exact path="/useraccount/crear_cuenta_comitente" element={<Access />} />
                  <Route exact path="/useraccount/panel" element={<Access />} />
                  <Route exact path="/useraccount/reset_validation/:resetToken" element={<Reset />} />
                  <Route exact path="/useraccount/tus-activos" element={<Access />} />
                  <Route exact path="/useraccount/tus-ordenes" element={<Access />} />
                  <Route exact path="/useraccount/bancos-y-transferencias" element={<Access />} />
                  <Route exact path="/useraccount/abrir-cuenta" element={<Onboarding />} />
                </Routes>
                :
                <ProfileContext.Provider value={{userProfile, fees}}>
                  <AssetsContext.Provider value={{assets}}>
                    <OrdersContext.Provider value={{opOrders, mepOrders}}>
                      <Routes>
                          <Route exact path="/*" element={<HomeUser />} />
                          <Route exact path="/useraccount/" element={<HomeUser />} />
                          <Route exact path="/useraccount/*" element={<HomeUser />} />
                          <Route exact path="/useraccount/acceso" element={<HomeUser />} />
                          <Route exact path="/useraccount/registro" element={<Access />} />
                          <Route exact path="/useraccount/reset_validation/:resetToken" element={<Reset />} />
                          <Route exact path="/useraccount/tu_cuenta" element={<ProfilePage />} />
                          <Route exact path="/useraccount/crear_cuenta_comitente" element={<Onboarding />} />
                          <Route exact path="/useraccount/vincular_cuenta_comitente" element={<Ownership />} />
                          <Route exact path="/useraccount/panel" element={<HomeUser />} />
                          <Route exact path="/useraccount/operar_dolar" element={<HomeUser />} />
                          <Route exact path="/useraccount/operar_dolar" element={<HomeUser />} />
                          <Route exact path="/useraccount/comprar_dolar/:source" element={<OperateBuy />} />
                          <Route exact path="/useraccount/vender_dolar/:source" element={<OperateSell />} />
                          <Route exact path="/useraccount/tus-activos" element={<YourAssets />} />
                          <Route exact path="/useraccount/tus-ordenes" element={<YourOrders />} />
                          <Route exact path="/useraccount/bancos-y-transferencias" element={<YourBanks />} />
                          {/* <Route exact path="/useraccount/upload" element={<Uploadfile/>} /> */}
                          <Route exact path="/useraccount/backend/users" element={<BackendUsers />} />
                          <Route exact path="/useraccount/backend/userdetail/:user_hash" element={<BackendUser />} />
                          <Route exact path="/useraccount/backend/orders" element={<BackendOrders />} />
                          <Route exact path="/useraccount/backend/calls" element={<BackendCalls />} />
                      </Routes>
                    </OrdersContext.Provider>
                  </AssetsContext.Provider>
                </ProfileContext.Provider>
              }

            </PricesContext.Provider>
          </BrowserRouter>
          );
   }
}

const withToken = Component => {
  return props => {
    const { validToken, logMeOut } = useToken();
    const {prices, marketOpen, getPrices}  = PricesState()
    const {userProfile, fees, getProfile}  = ProfileState()
    const {assets, getAssets}  = AssetsState()
    const {opOrders, mepOrders, getOrders}  = OrdersState()

    return <Component {...props}                  
                      logMeOut={logMeOut}
                      validToken={validToken}
                      userProfile={userProfile}
                      fees = {fees}
                      getProfile={getProfile}
                      assets={assets}
                      getAssets={getAssets}
                      opOrders={opOrders}
                      mepOrders={mepOrders}
                      getOrders={getOrders}
                      prices={prices}
                      marketOpen={marketOpen}
                      getPrices={getPrices} />;
  };
};

export default withToken(App);
import React, {useContext, useState} from "react";

import Footer from './Footer';
import UserSideBar from './UserSideBar';
import ProfilePanel from './ProfilePanel'
import OnboardingHook from '../components/onboarding/OnboardingHook.js'
import RibbonStats from '../components/fields/RibbonStats.js'

import ProfileContext from "../context/Profile/ProfileContext.js";

const ProfilePage = () => {
    const {userProfile} = useContext(ProfileContext)

    const [open, setOpen] = useState(false)

    console.log("userProfile in profile page", userProfile)
    return (
        <div>
            <div className="relative pt-14 min-h-screen">
                <header className="absolute inset-x-0 top-0 z-50">
                    <UserSideBar  open={open} setOpen={setOpen} />
                </header>

                    {userProfile?.has_account === false ?
                            <OnboardingHook />
                        :
                            <RibbonStats pp={userProfile?.pp} />
                    }
                    

                    <div className="max-w-lg mx-auto flex flex-col justify-between p-5">
                        <ProfilePanel userProfile={userProfile}/>
                    </div>
                </div>
            <Footer logedIn={true}/>
        </div>
    )

}


export default ProfilePage
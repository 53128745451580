import React, { useState, useReducer } from "react";
import axios from "axios";

import ProfileReducer from "./ProfileReducer";

import { GET_PROFILE } from "../types";

const ProfileState = () => {

    const initialState = {
        name: '',
        last_name: '',
        email:'',
        has_account: false,
        hasTESTs: null,
        arsCBU: null,
        usdCBU: null,
        history: [{
            id:"",
            date:"",
            side:"",
            ticker:"",
            P:0,
            Q:0,
            fees:0,
            status:''
        }],
        pp:
        {
            total_posicion: null,
            dolarcable_1:  null,
            dolarcable_2:  null,
            dolarcable_3:  null,
            dolarmep_0:  null,
            dolarmep_1:  null,
            dolarmep_2:  null,
            dolarmep_3:  null,
            pesos_0:  null,
            pesos_1:  null,
            pesos_2:  null,
            pesos_3:  null,
            plazo_0: "Contado",
            plazo_1: "24 Hs.",
            plazo_2: "48 Hs.",
            plazo_3: "72 Hs.",
            retiro_pesos: 0,
            retiro_mep: 0,
            retiro_ccl: 0
            
        }
      }

    const [fees, setFees ] = useState({"normal":0.0041,"side_1":0.0041,"side_2":0.0041,"total":0.0041})

    const [state, dispatch] = useReducer(ProfileReducer, initialState);

    function getProfile() {

        let token = localStorage.getItem('token')

        if (!token) {
          return false
        }

        axios({
          method: "GET",
          url:"/broker/api/profile",
          headers: {
            Authorization: 'Bearer ' + token
          }
        })
        .then((response) => {
          //response.data.access_token && setToken(response.data.access_token)
          dispatch({ type: GET_PROFILE, payload: response.data });

          if (response.data.fees) {
            setFees(response.data.fees)
          }
        }).catch((error) => {
          // if (error.response.status == 401) {
          //   console.log('desconecta por inactividad', error.response.data)
          // }
  
          // setUserProfile(initialProfile)
          // FETCH_DATA_FAILURE
          //   setUserProfile(profTemplate)
          // 
        }) 
      
      }


    return (
        {
            userProfile: state,
            fees: fees,
            getProfile,
        }
    );
};

export default ProfileState;
import React from "react";
import { Link } from 'react-router-dom'

const DolarPrice = ({prices, marketOpen}) => {

    return (

        <div className="-mt-2 lg:mt-0 w-full md:w-1/2 lg:flex-shrink-0 py-8">
              <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center py-8" 
              style={{maxWidth:'600px',width:'100%',margin:'auto'}}>
                <div className="mx-auto px-8">
                  <h2 className="text-base font-semibold text-gray-600">Dólar {prices?.label}</h2>

                  <div>

                    {
                      marketOpen ?

                      <div data-testid="dolar.price.market.open" className="flex-column md:flex w-full">
                        <div className="w-3/4 p-5 text-4xl text-green-600 font-bold	mx-auto md:ml-auto flex sm:flex-row md:flex-col">

                          <div><h2 data-testid="dolar.price.market.bidMep">{prices?.bidMep?.toFixed(2)}</h2></div>
                         
                          <div>
                            <Link to="/useraccount/acceso">
                              <button className="bg-lime-200 md:mt-10 block w-full rounded-md  mx-2 px-3 py-2 text-center text-sm font-semibold shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600 text-slate-900" >
                              Vender
                            </button>
                            </Link>
                          </div>
                          
                        </div>
                        <div className="w-3/4 p-5 text-4xl text-green-600 font-bold	mx-auto md:mr-auto flex sm:flex-row md:flex-col">

                          <div>                      
                            <h2 data-testid="dolar.price.market.askMep">{prices?.askMep?.toFixed(2)}</h2>
                          </div>

                          <div>
                            <Link to="/useraccount/acceso">                          
                              <button className="bg-lime-200 md:mt-10 block w-full rounded-md  mx-2 px-3 py-2 text-center text-sm font-semibold shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600 text-slate-900" >
                                Comprar
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    :
                    <div className="w-full p-5 text-4xl text-green-600 font-bold" data-testid="dolar.price.market.closed">
                        {prices?.lastMep &&
                          <>
                            <h2 data-testid="dolar.price.market.lastMep">{prices?.lastMep?.toFixed(2)}</h2>
                            <p className="mt-6 text-xs leading-5 text-gray-600">
                              Precio de Cierre
                            </p>
                          </>
                        }
                        <Link to="/useraccount/acceso">                          
                          <button className="bg-lime-200 mt-10 block w-full rounded-md  px-3 py-2 text-center text-sm font-semibold shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600 text-slate-900" >
                            Comprar
                          </button>
                          </Link>
                        
                    </div>
                    }

                  </div>


                  <ul>
                    {(prices == '') &&
                        (<li key={prices.ticker} className="mt-4">
                        <span className="text-m font-semibold leading-6 tracking-wide text-gray-600 mr-3">{prices.ticker}</span>
            
                        <span className="text-3xl font-bold tracking-tight text-gray-900">{prices?.bid?.toFixed(2)} - {prices?.ask?.toFixed(2)}</span>
                        </li>)
                      
                      }
                  </ul>

                  <p className="mt-6 text-xs leading-5 text-gray-600">
                    Horario de 11 a 16.15
                  </p>
                </div>
              </div>
        </div>

    )

}

export default DolarPrice
import React, { useState, useEffect } from "react"

import axios from "axios";

import { Link } from 'react-router-dom'


import BackendSideBar from './BackendSideBar.js'

const BackendUsers = () => {

    const [open, setOpen] = useState(false) 

    const [users, setUsers] = useState([])

    const [authorizer, setAuthorizer] = useState('')

    const [context, setContext] = useState(null)

    const getUsers = (setAuthorizer) => {

        axios({
            method: "POST",
            url:"/broker/api/backend/users/all",
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token')
            },
            data: {}
          })
          .then((response) => {setUsers(response.data.data); setAuthorizer(response.data.authorizer)})
          .catch((error)=> { 

        })

    }



    const brokerHooks = (hook, user_hash) => {

        axios({
            method: "POST",
            url: hook,
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token')
            },
            data: {
                'authorizer':authorizer,
                "user_hash":user_hash
            }
          })
          .then((response) => {
            setContext({COLOR:"red",MESSAGE:response.data.msg})
          })
          .catch((error)=> { 
        })

    }

    return (
        <div className="relative pt-14 min-h-screenlg:px-8">

            
            <header className="absolute inset-x-0 top-0 z-50">
                <BackendSideBar open={open} setOpen={setOpen}/>
            </header>
            <h1>Listado de Usuarios y de IEB+ Account People</h1>

            <hr></hr>

                {context &&
                    <div className="my-5 flex flex-row" >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-12 h-12">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z" />
                    </svg>
    
                        <div style={{ color: context.COLOR }}>{context.MESSAGE}</div>
                    </div>        
                }

            <hr></hr>

                <button onClick={()=> getUsers(setAuthorizer)} className="mt-10"> Get updated list of users</button>

            <table  className=" w-full mt-10 table-auto">
                <tbody>
                {users.map((u)=> { return (
                    <tr className="border-spacing-x-1.5 mt-10	" key={u[0]}>
                        <td>
                        <Link to={"/useraccount/backend/userdetail/"+u[3]}>
                            {u[0]}
                        </Link>
                        
                        </td>
                        <td>{u[1]}</td>
                        <td>{u[2]}</td>
                        <td>
                            <button 
                                className="block rounded-md bg-gray-500 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                                onClick={()=> {brokerHooks("/broker/hook/user_assets",u[3])}}>
                                User Assets
                            </button>
                        </td>
                        <td>
                            <button
                                className="block rounded-md bg-gray-500 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                                onClick={()=> {brokerHooks("/broker/hook/purchase_power",u[3])}}>
                                Purchase Power
                            </button>
                        </td><td>
                            <button 
                                className="block rounded-md bg-gray-500 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                                onClick={()=> {brokerHooks("/broker/hook/user_orders",u[3])}}>
                                User Orders
                            </button>
                        </td>
                        <td>
                            <button 
                                className="block rounded-md bg-gray-500 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                                onClick={()=> {brokerHooks("/broker/hook/user_tables",u[3])}}>
                                User Tables
                            </button>
                        </td>
                        <td>
                            <button 
                                className="block rounded-md bg-gray-500 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                                onClick={()=> {brokerHooks("/broker/api/backend/userinspect",u[3])}}>
                                switch
                            </button>
                        </td>
                        <td>{u[4]}</td>
                    </tr>
                )})}
                </tbody>
            </table>
        </div>
    )
}

export default BackendUsers
import React, {useContext} from "react"

import { Link } from 'react-router-dom'

import ProfileContext from "../../context/Profile/ProfileContext.js";

const OnboardingHook = () => {

    const { userProfile } = useContext(ProfileContext)
    switch (userProfile?.account_progress) {
        case "NO_PROGRESS":
            return (

                <div className="w-full mx-auto py-2 bg-orange-100 border-b-2 border-amber-300"  style={{minHeigth:"70px"}}>
                <div className="max-w-2xl mx-auto text-center">
                <div className="text-black-700 max-w-3xl flex flex-col text-center" role="alert">
                    <p className="font-bold mx-auto flex flex-col">
                        Para operar necesitás...
                        </p>
                        <Link to="/useraccount/crear_cuenta_comitente" className='m-2'>
                                <span className="mt-3 rounded-md bg-green-400 text-blask px-3 py-2 text-left text-sm font-semibold text-indigo shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-1 focus-visible:outline-emerald-600">
                                    Cuenta Comitente (CNV)
                                </span>
                        </Link>

                        {/*<Link to="/useraccount/vincular_cuenta_comitente">
                            <button className="relative text-sm w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-black after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center">
                                (Ya tengo cuenta en IEB+)
                            </button>
                        </Link>*/}
                </div></div></div>
            )
            case "DATA_GATHERING":
                return (
                    
                <div className="w-full mx-auto py-2 bg-orange-100 border-b-2 border-amber-300">
                <div className="max-w-2xl mx-auto text-center">
                    <div className="flex flex-row">
                        <Link to="/useraccount/crear_cuenta_comitente" className="mx-auto"> 
                            <button className="relative text-xl w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-black after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center">
                                Continuar con la creación de la cuenta comitente
                            </button>
                        </Link> 
                </div></div></div>
                )
                case "CREATION_IN_PROGRESS":
                    return (
                        
                    <div className="w-full mx-auto py-2 bg-orange-100 border-b-2 border-amber-300">
                    <div className="max-w-2xl mx-auto text-center">
                        <div className="flex flex-row text-center">
                            <p className="mx-auto">Estamos validando tus datos para abrir tu cuenta.</p>
                    </div></div></div>
                    )

                case "OWNERSHIP_IN_PROGRESS":
                return (
                    
                <div className="w-full mx-auto py-2 bg-orange-100 border-b-2 border-amber-300">
                <div className="max-w-2xl mx-auto text-center">
                    <div className="flex flex-row">
                        <Link to="/useraccount/crear_cuenta_comitente" className="mx-auto"> 
                            <button className="relative text-xl w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-black after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center">
                                Vincular tu cuenta comitente
                            </button>
                        </Link> 
                </div></div></div>
                )
        default:
        return (
            <></>
        );
    }
}

export default OnboardingHook
import { useState, useEffect } from 'react'
import { RadioGroup } from '@headlessui/react'

import { Transition } from '@headlessui/react'


import step1 from './../../images/step1.jpeg'
import step2 from './../../images/step2.jpeg'
import step3 from './../../images/step3.jpeg'
import step4 from './../../images/step4.jpeg'

const preloadImages = [step1, step2, step3, step4];

preloadImages.forEach((src) => {
  const img = new Image();
  img.src = src;
});


const flows = [
  {
    head: '1| Crea tu cuenta',
    step: '',
    explain: 'Registrate y crea tu Cuenta Comitente CNV.',
    note: '',
    img: step1,
    tab: 1,
  },
  {
    head: '2| Acreditá fondos',
    step: '',
    explain: 'Ingresa tus fondos por transferencia bancaria.',
    note: '',
    img: step2,
    tab: 2,
  },
  {
    head: '3| Comprá tus dólares',
    step: '',
    explain: 'Cargá la orden siguiendo los precios estimativos.',
    note: '',
    img: step3,
    tab: 3,
  },
  {
    head: '4| Retirá tus dólares',
    step: '',
    explain: 'Cuando están los dólares acreditados, pedís el retiro a tu Banco.',
    note: '',
    img: step4,
    tab: 4,
  }
]

export default function ShowWebD() {
  const [selected, setSelected] = useState(flows[0])
  const [imgOB, setImgOB] = useState(step1)
  const heightFix = () => { return '500px'}
  useEffect(()=> {
    setImgOB(selected.img)
  },[selected])

 

  return (
    <div className="w-full px-4 py-10">

            <div className='mx-auto  text-center '>
              <h1 className="my-4 mx-auto text-4xl leading-none tracking-tight text-gray-900">Seguí estos <span className="text-green-600">simples pasos</span></h1>
            </div>
            <div className="pt-5">
                <RadioGroup value={selected} onChange={setSelected}>
                <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
                <div className="space-y-2">
                    {flows.map((flow) => (
                    <RadioGroup.Option
                        key={flow.head}
                        value={flow}
                        className={({ active, checked }) =>
                        `${
                            active
                            ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-emerald-300'
                            : ''
                        }
                        ${
                            checked ? 'bg-emerald-900 bg-opacity-75 text-white' : 'bg-white'
                        }
                            relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
                        }
                    >
                        {({ active, checked }) => (
                        <>
                            <div className="flex w-full items-center justify-between">
                            <div className="flex items-center">
                                <div className="text-sm">
                                <RadioGroup.Label
                                    as="p"
                                    className={`font-medium  ${
                                    checked ? 'text-white' : 'text-lime-900'
                                    }`}
                                >
                                    {flow.head}
                                </RadioGroup.Label>

                                </div>
                            </div>
                            {checked && (
                                <div className="shrink-0 text-white">
                                <CheckIcon className="h-6 w-6" />
                                </div>
                            )}
                            </div>
                        </>
                        )}
                    </RadioGroup.Option>
                    ))}
                </div>
                </RadioGroup>
            </div>
            <div className="w-full pt-5 min-h-500" >
              <div className="px-auto">
                {flows.map(flow => {
                  return(
                  <>
                    <Transition
                        key={flow.step}
                        show={imgOB == flow.img}
                        appear={true}
                        //className="w-full"
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="opacity-0 translate-y-0"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in-out duration-300 transform absolute"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 -translate-y-0"
                        //beforeEnter={() => heightFix()}
                        unmount={false}                     
                    >
                    
                      <p className="text-center pb-5">{flow.explain}</p>
                      <img src={imgOB} className="rounded mx-auto border-solid border-2 border-green-600" style={{maxWidth:"200px"}}></img>
                    </Transition>
                  </>)
                  })}
              </div>
            </div>

    </div>
  )
}

function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

import React, { useState,  useContext } from "react";
import useToken from '../../context/Profile/useToken';

import CloseButton from '../fields/CloseButton'

import { Link } from 'react-router-dom'
import Logo from '../../business/Logo'

import ProfileContext from "../../context/Profile/ProfileContext.js";

export default function BackendSideBar({ open, setOpen }) {

    const { userProfile } = useContext(ProfileContext)
    const { logMeOut } = useToken();
    const [processing, setProcessing] = useState(false)

    const logMeOutOfsite = () => {
        if (processing) {
            return true
        }
        setProcessing()
        logMeOut()
    }

    const [toogle, setToogle] = useState({ cbupesos: 'none', aliaspesos: 'none', cbudolar: 'none', aliasdolar: 'none' })

    return (

        <div className='bg-white py-3 fixed top-0 left-0 right-0 shadow-md'>
            <div className='block flex flex-row'>

                <button className='ml-4' onClick={() => setOpen('profile')}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke={userProfile?.email == '' ? 'black' : 'green'} className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                </button>

                <div className="max-w-screen-xl flex flex-wrap items-center justify-between ml-auto mr-5 w-50 ">
                    <div className="sm:mx-auto sm:w-full sm:max-w-sm  content-center">
                        <Link to="/useraccount/">
                            <Logo classLogo="text-2xl flex items-center" />
                        </Link>
                    </div>
                </div>

                <div className={`${!open && "hidden"} bg-gray-600/50 min-h-screen w-full fixed top-0 left-0 right-0 backdrop-blur-sm`} onClick={() => setOpen(false)}></div>

                <div className={`${open ? "w-400" : "w-0"} bg-zinc-50 min-h-screen fixed top-0 left-0 transition-all duration-300`}>
                    <div className={`${!open && "hidden"} pt-3`} style={{ minWidth: '400px' }}>
                        <button className='ml-4 text-black mb-14' onClick={() => setOpen(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>


                        <div className="my-4">


                            <Link to="/useraccount/backend/calls">
                                <button href="/bancos-y-transferencias" onClick={() => setOpen(false)} className="m-3 flex rounded-md bg-white-600 px-3 py-2 text-center text-sm font-semibold text-indigo shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600">
                                    <span className="pl-3">Api Calls</span>
                                </button>
                            </Link>

                            <Link to="/useraccount/backend/orders">
                                <button onClick={() => setOpen(false)} className="m-3 flex rounded-md bg-white-600 px-3 py-2 text-center text-sm font-semibold text-indigo shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600">
                                    <span className="pl-3">Orders</span>
                                </button>
                            </Link>

                            <Link to="/useraccount/backend/users">
                                <button onClick={() => setOpen(false)} className="m-3 flex rounded-md bg-white-600 px-3 py-2 text-center text-sm font-semibold text-indigo shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600">
                                    <span className="pl-3">Users</span>
                                </button>
                            </Link>


                            <hr></hr>

                            <div className="mt-6 flex items-center justify-end gap-x-6 mr-6 pr-5 ">

                                <CloseButton text={"Cerrar Sesión"} fieldaction={logMeOutOfsite} processing={processing}>

                                </CloseButton>

                            </div>


                        </div>




                    </div>
                </div>
            </div>
        </div>
    )
}


const DFINDissclaimer = () => {
    return (
        <div className="max-w-2xl mx-auto p-4 mt-5">
            <div className="mx-auto">
                <p>La compra del Dólar Financiero o Dólar MEP representa la compra de un bono en pesos y su posterior venta en dólares en el mercado financiero. Debido a regulaciones del Banco Central la venta debe hacerse al día hábil siguiente de la compra. Esto lleva a que los precios sean estimativos y que solo se conozcan una vez cerrada la operación. En el caso de la venta de dólares, aplican restricciones en el plazo de la operación. Al operar en Dólar Financiero el usuario le da mandato al AP para que opere en cuenta y orden bajo las condiciones informadas.</p>
            </div>
        </div>
    )
}

export default DFINDissclaimer
import YourOrdersBuyDetail from './YourOrdersBuyDetail.js'
import YourOrdersSellDetail from './YourOrdersSellDetail.js'

const YourOrdersMep = ({mepOrders}) => {


    console.log("mepOrders", mepOrders, mepOrders.length, mepOrders > 0)

    return (
        <>

                {!mepOrders.length > 0 ? 
                <div>

                    <h2>No has realizado ninguna operación</h2>

                    <div className="w-full mx-auto">
                    <div className="py-5 lg:mt-0 w-full lg:flex-shrink-0">
                    <div className="animate-pulse rounded-2xl p-10 bg-gray-50 py-10 text-left ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center py-16">
                        <hr></hr>
                    </div>
                    </div>
                    </div>
                
                </div>

                :
                <div>
                
                    <div className="w-full mx-auto mt-10 ">

                    {mepOrders.map(o => {

                        return (

                        <div className="py-5 lg:mt-0 w-full lg:flex-shrink-0" key={o.order_hash}>

                          <div className={"bg-"+o.mep_order_analytics.mep_order_status.color +" rounded-2xl px-3 md:p-10 bg-gray-50 py-10 text-left ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center"}>

                              <h2 className="text-base font-semibold text-gray-600">{o.operation_side} DÓLAR {o.ticker}</h2> 

                              <p>Orden {o.order_hash}</p>

                              <p>Plazo: {o.settlement_side_2 } </p>

                              { o.operation_side == 'VENTA' && <YourOrdersSellDetail order={o} ></YourOrdersSellDetail> }

                              { o.operation_side == 'COMPRA' && <YourOrdersBuyDetail order={o} ></YourOrdersBuyDetail> }

                          </div>

                        </div>
                        )

                    })}

                    </div>

                </div>}

        </>
    )
}

export default YourOrdersMep;
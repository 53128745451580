import React, { useState, useEffect } from "react"

import axios from "axios";

import BackendSideBar from './BackendSideBar.js'

const OrderDetail = ({o,setContent,authorizer, getBackendOrders, setContext}) => {
    
    const updateOrder = (o) => {
        
        getBackendOrders()
        
        axios({
            method: "POST",
            url:"/broker/api/process_mep_orders/" + o.mep_order_id,
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token')
            },
            data: {
                'authorizer':authorizer
            }
          })
          .then((response) => {
                setContext({COLOR:"red",MESSAGE:response.data.msg})
          }).catch((error) => {
                setContext({COLOR:"red",MESSAGE:error.data.msg})
          })

    }

    const clearOrder = (o) => {

        axios({
            method: "POST",
            url:"/broker/api/backend/orders/clear",
            data: {
                ieb_order_id:o.ieb_order_id,
                mep_order_id:o.mep_order_id
            },
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token')
            }
          })
          .then((response) => {
                setContext({COLOR:"red",MESSAGE:response.data.msg})
          }).catch((error) => {
                setContext({COLOR:"red",MESSAGE:error.data.msg})
          })

    }

    const cancelOrder = (o) => {

        axios({
            method: "POST",
            url:"/broker/api/backend/orders/cancel",
            data: {
                mep_order_id:o.mep_order_id
            },
            headers: {
              Authorization: 'Bearer '+localStorage.getItem('token')
            }
          })
          .then((response) => {
                setContext({COLOR:"red",MESSAGE:response.data.msg})
          }).catch((error) => {
                setContext({COLOR:"red",MESSAGE:error.data.msg})
          })

    }

    const [show, setShow] = useState(null)


    return ( <>{show ?
        <>
            <tr>
                <td>{o.mep_order_id} {o.ieb_order_id}</td>
                <td>{o.operation_side} : {o.mep_order_progress}</td>
                <td>.</td>
                <td>
                    <button onClick={()=>setShow(null)}>Hide</button>
                </td>
            </tr>
            <tr>
                <td colSpan={3}>
                    {JSON.stringify(o.ieb_order, null, 2) }
                </td>
                <td>
                    {(o.mep_order_progress == "ORDER_SIDE_1_PARKING" && o.operation_side == "COMPRA")&& 
                        <div className="flex">
                            <button 
                                className="rounded-md bg-emerald-600 flex px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                                onClick={() => clearOrder(o)}>CLEAR ORDER
                            </button>
                        </div>
                    }


                    {(o.mep_order_progress == "ORDER_SIDE_2_SENT_AWAIT" && o.operation_side == "VENTA")&& 
                        <div className="flex">
                            <button 
                                className="rounded-md bg-emerald-600 flex px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                                onClick={() => clearOrder(o)}>CLEAR ORDER
                            </button>
                        </div>
                    }

                    {
                        (o.mep_order_progress != "ORDER_FULLFILLED" &&
                            <div className="flex">
                                <button 
                                    className="rounded-md bg-emerald-600 flex px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                                    onClick={() => cancelOrder(o)}>CANCEL ORDER
                                </button>
                            </div>
                        )
                    }
                </td>
            </tr>
            <tr>
                <td colSpan={3}>
                    {JSON.stringify(o.ieb_order, null, 2) }
                </td>
                <td>
                    <button 
                        className="rounded-md bg-emerald-600 flex px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                        onClick={() => updateOrder(o)}>UPDATE ORDER
                    </button>

                    {(o.mep_order_progress == "ORDER_SIDE_1_PARKING" && o.operation_side == "COMPRA")&& 
                        <div className="flex">
                            <button 
                                className="rounded-md bg-emerald-600 flex px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                                onClick={() => clearOrder(o)}>CLEAR ORDER
                            </button>
                        </div>
                    }


                    {(o.mep_order_progress == "ORDER_SIDE_2_SENT_AWAIT" && o.operation_side == "VENTA")&& 
                        <div className="flex">
                            <button 
                                className="rounded-md bg-emerald-600 flex px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                                onClick={() => clearOrder(o)}>CLEAR ORDER
                            </button>
                        </div>
                    }
                </td>
            </tr>
        </>
            
    :

        <tr>
            <td>{o.mep_order_id} - {o.ieb_order_id} - {o.mep_order_id}</td>
            <td>{o.operation_side}</td>
            <td>{o.mep_order_progress}</td>
            <td><button onClick={()=>setShow(true)}>show</button></td>
        </tr>
        }
    </>
)

}



const BackendOrders = () => {

    const [open, setOpen] = useState(false)
    const [content, setContent] = useState({data:[]})
    const [context, setContext] = useState(null)


    const [jsonMessage, setJsonMessage] = useState({})

    const pingConnection = () => {
        

        axios({
            url:"/broker/api/backend/ping_server/",
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token')
            }
          })
          .then((response) => {
                setJsonMessage(response.data)
          }).catch((error) => {
                setJsonMessage(error.response.data)
          })

    }

    const pingDollar = () => {
        axios({
            url:"/broker/api/ieb_dollar_price",
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token')
            }
          })
          .then((response) => {
                setJsonMessage(response.data)
          }).catch((error) => {
                setContext({COLOR:"red",MESSAGE:error.response.data.msg})
                setJsonMessage(error.response.data)
          })

    }

    const [authorizer, setAuthorizer] = useState('')
    function getBackendOrders() {
        axios({
          method: "GET",
          url:"/broker/api/backend/orders/all",
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        })
        .then((response) => {

            if (response?.data) {
                setContent(response.data)
                setAuthorizer(response.data.authorizer)
            }
  
        }).catch((error) => {
            setContent([])
        })
      
      }

      useEffect(() => {
        
        getBackendOrders()
        pingDollar()
        },[])

    return (
        <div className="relative pt-14 min-h-screenlg:px-8">
            <header className="absolute inset-x-0 top-0 z-50">
                <BackendSideBar open={open} setOpen={setOpen}/>
            </header>
            <h1>BackendOrders</h1>
            

            <div className="relative pt-14 min-h-screenlg:px-8">

                <hr></hr>
                    <div className="flex flex-row">
                        <button
                            className="mt-10 block rounded-md bg-gray-500 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                        onClick={() => pingDollar()}>Get Dollar</button> | 
                        <button 
                            className="mt-10 block rounded-md bg-gray-500 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                        onClick={() => pingConnection()}>Ping Token</button>
                    </div>
                    <p>
                        {JSON.stringify(jsonMessage,null,2)}
                    </p>
                    <hr></hr>

                    {context &&
                        <div className="my-5 flex flex-row" >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-12 h-12">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z" />
                        </svg>

                            <div style={{ color: context.COLOR }}>{context.MESSAGE}</div>
                        </div>        
                    }

                    <hr></hr>

                <table className="w-full">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>STATUS</th>
                        <th></th>
                        <th>ACTIONS</th>
                    </tr>
                </thead>
                <tbody>
                        {content.data.map(order => {return (<OrderDetail authorizer={authorizer} key={order.id} o={order} setContent={setContent} getBackendOrders={getBackendOrders} setContext={setContext}></OrderDetail>)})}
                </tbody>

                </table>
            </div>
        </div>)
}

export default BackendOrders
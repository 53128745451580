const FIELD_ENUMS = { 
    BINARIO: {
        SELECCIONAR:{
            id:-1,
            label:"Seleccionar opción"
        },
        SI: { 
            id:1,
            label: "Sí"
        },
        NO: { 
            id:0,
            label: "NO"
        },
    },
    ESTADO_CIVIL: {
        SELECCIONAR:{
            id:-1,
            label:"Seleccionar opción"
        },
        SOLTERO: { 
            id:0,
            label: "Soltero"
        },
        CASADO: { 
            id:1,
            label: "Casado"
        },
        VIUDO: { 
            id:2,
            label: "Viudo"
        },
        DIVORCIADO: { 
            id:3,
            label: "Divorciado"
        },
    },
    GENERO: {
        SELECCIONAR:{
            id:-1,
            label:"Seleccionar opción"
        },
        MASCULINO: { 
            id:0,
            label: "Masculino"
        },
        FEMENINO: { 
            id:1,
            label: "Femenino"
        },
        OTRO: { 
            id:2,
            label: "Otro"
        },
    },
    PROFESION: {
        SELECCIONAR:{
            id:-1,
            label:"Seleccionar opción"
        },
        JUBILADO_PENSIONADO: { 
            id:1,
            label: "Jubilado / Pensionado"
        },
        AMA_DE_CASA: { 
            id:2,
            label: "Ama de casa"
        },
        ESTUDIANTE: { 
            id:3,
            label: "Estudiante"
        },
        DEPENDENCIA: { 
            id:4,
            label: "Empleado en relación de  dependencia"
        },
        PUBLICO: { 
            id:5,
            label: "Empleado Público"
        },
        PROFESIONAL: { 
            id:6,
            label: "Actividades profesionales liberales"
        }
    },
    PARENTESCO_PEP: {
        SELECCIONAR:{
            id:-1,
            label:"Seleccionar opción"
        },
        PARENTESCO: { 
            id:0,
            label: "Parentesco"
        },
        PROPIO: { 
            id:1,
            label: "Propio"
        },
        NINGUNO: { 
            id:2,
            label: "Ninguno"
        }
    },
    PERFIL_INVERSOR: {
        SIN_CALIFICAR:{
            id:0,
            label:"Sin Calificar"
        },
        ARRIESGADO:{
            id:1,
            label:"Arriesgado"
        },
        MODERADO:{
            id:2,
            label:"Moderado"
        },
        CONSERVADOR:{
            id:3,
            label:"Conservador"
        }

    },
    CONDICION_IVA:{
        RESP_INSCRIPTO: {
            id:0,
            label:"Resp. Inscripto"
        },
        NO_RESPONSABLE: {
            id:1,
            label:"No Responsable"
        },
        CONSUMIDOR_FINAL: {
            id:2,
            label:"No Responsable"
        },
        MONOTRIBUTO: {
            id:3,
            label:"Monotributo"
        },
        EXENTO: {
            id:4,
            label:"Exento"
        },
        NO_CATEGORIZADO: {
            id:5,
            label:"No Categorizado"
        },
    },
    PROVINCIA: {
        SELECCIONAR:{
            id:-1,
            label:"Seleccionar opción"
        },
        CIUDAD_DE_BUENOS_AIRES: {
            id: 1,
            label: "CIUDAD DE BUENOS AIRES"
        },
        BUENOS_AIRES: {
            id: 2,
            label: "BUENOS AIRES"
        },
        CATAMARCA: {
            id: 3,
            label: "CATAMARCA"
        },
        CORDOBA: {
            id: 4,
            label: "CORDOBA"
        },
        CORRIENTES: {
            id: 5,
            label: "CORRIENTES"
        },
        CHACO: {
            id: 6,
            label: "CHACO"
        },
        CHUBUT: {
            id: 7,
            label: "CHUBUT"
        },
        ENTRE_RIOS: {
            id: 8,
            label: "ENTRE RIOS"
        },
        FORMOSA: {
            id: 9,
            label: "FORMOSA"
        },
        JUJUY: {
            id: 10,
            label: "JUJUY"
        },
        LA_PAMPA: {
            id: 11,
            label: "LA PAMPA"
        },
        LA_RIOJA: {
            id: 12,
            label: "LA RIOJA"
        },
        MENDOZA: {
            id: 13,
            label: "MENDOZA"
        },
        MISIONES: {
            id: 14,
            label: "MISIONES"
        },
        NEUQUEN: {
            id: 15,
            label: "NEUQUEN"
        },
        RIO_NEGRO: {
            id: 16,
            label: "RIO NEGRO"
        },
        SALTA: {
            id: 17,
            label: "SALTA"
        },
        SAN_JUAN: {
            id: 18,
            label: "SAN JUAN"
        },
        SAN_LUIS: {
            id: 19,
            label: "SAN LUIS"
        },
        SANTA_CRUZ: {
            id: 20,
            label: "SANTA CRUZ"
        },
        SANTA_FE: {
            id: 21,
            label: "SANTA FE"
        },
        SANTIAGO_DEL_ESTERO: {
            id: 22,
            label: "SANTIAGO DEL ESTERO"
        },
        TIERRA_DEL_FUEGO: {
            id: 23,
            label: "TIERRA DEL FUEGO"
        },
        TUCUMAN: {
            id: 24,
            label: "TUCUMAN"
        },
        NONE: {
            id: 25,
            label: "."
        }
    },
    PAIS: {
        SELECCIONAR:{
            id:-1,
            label:"Seleccionar opción"
        }
        ,ARGENTINA:{id:1,label:'Argentina'}
        ,BOLIVIA:{id:2,label:'Bolivia'}
        ,BRASIL:{id:3,label:'Brasil'}
        ,CANADA:{id:4,label:'Canada'}
        ,COLOMBIA:{id:5,label:'Colombia'}
        ,COSTA_RICA:{id:6,label:'Costa Rica'}
        ,CUBA:{id:7,label:'Cuba'}
        ,CHILE:{id:8,label:'Chile'}
        ,ECUADOR:{id:9,label:'Ecuador'}
        ,EL_SALVADOR:{id:10,label:'El Salvador'}
        ,EEUU:{id:11,label:'EE.UU.'}
        ,GUATEMALA:{id:12,label:'Guatemala'}
        ,HAITI:{id:13,label:'Haiti'}
        ,HONDURAS:{id:14,label:'Honduras'}
        ,MEXICO:{id:15,label:'Mexico'}
        ,NICARAGUA:{id:16,label:'Nicaragua'}
        ,PANAMA:{id:17,label:'Panama'}
        ,PARAGUAY:{id:18,label:'Paraguay'}
        ,PERU:{id:19,label:'Peru'}
        ,PUERTO_RICO:{id:20,label:'Puerto Rico'}
        ,REPUBLICA_DOMINICANA:{id:21,label:'Republica Dominicana'}
        ,URUGUAY:{id:22,label:'Uruguay'}
        ,OTROS_PAISES_AMERICANOS:{id:29,label:'Otros Países Americanos'}
        ,ALEMANIA:{id:31,label:'Alemania'}
        ,AUSTRIA:{id:32,label:'Austria'}
        ,BELGICA:{id:33,label:'Belgica'}
        ,REPUBLICA_CHECA:{id:34,label:'República Checa'}
        ,DINAMARCA:{id:35,label:'Dinamarca'}
        ,ESPANA:{id:36,label:'España'}
        ,FRANCIA:{id:37,label:'Francia'}
        ,GRAN_BRETANA:{id:38,label:'Gran Bretaña'}
        ,GRECIA:{id:39,label:'Grecia'}
        ,HOLANDA:{id:40,label:'Holanda'}
        ,HUNGRIA:{id:41,label:'Hungria'}
        ,IRLANDA:{id:42,label:'Irlanda'}
        ,ITALIA:{id:43,label:'Italia'}
        ,NORUEGA:{id:44,label:'Noruega'}
        ,POLONIA:{id:45,label:'Polonia'}
        ,PORTUGAL:{id:46,label:'Portugal'}
        ,RUMANIA:{id:47,label:'Rumania'}
        ,SUECIA:{id:48,label:'Suecia'}
        ,SUIZA:{id:49,label:'Suiza'}
        ,RUSIA:{id:50,label:'Rusia'}
        ,SERBIA_Y_MONTENEGRO:{id:51,label:'Serbia y Montenegro'}
        ,OTROS_PAISES_EUROPEOS:{id:59,label:'Otros Países Europeos'}
        ,CHINA:{id:61,label:'China'}
        ,FILIPINAS:{id:62,label:'Filipinas'}
        ,ISRAEL:{id:63,label:'Israel'}
        ,JAPON:{id:64,label:'Japon'}
        ,LIBANO:{id:65,label:'Líbano'}
        ,SIRIA:{id:66,label:'Siria'}
        ,TURQUIA:{id:67,label:'Turquia'}
        ,AUSTRALIA:{id:68,label:'Australia'}
        ,ANGUILA:{id:69,label:'Anguila'}
        ,ANTIGUAY_BARBUDA:{id:70,label:'Antiguay Barbuda'}
        ,ANTILLAS_HOLANDESAS:{id:71,label:'Antillas Holandesas'}
        ,ARUBA:{id:72,label:'Aruba'}
        ,ASCENCION:{id:73,label:'Ascencion'}
        ,BAHAMAS:{id:74,label:'Bahamas'}
        ,BARBADOS:{id:75,label:'Barbados'}
        ,BELICE:{id:76,label:'Belice'}
        ,BERMUDAS:{id:77,label:'Bermudas'}
    },
    MONEDA: {
        ARS:{id:"PESOS",label:'Cuenta en Pesos'},
        USD:{id:"DOLARES",label:'Cuenta en Dólares'}
    }
}
export default FIELD_ENUMS
const Terms = ({showTerms, setShowTerms}) => {
    return (
    
        showTerms && 
        <div className="my-5 px-3 rounded-2xl bg-gray-50 text-left ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center w-full">
          <h1 className="font-bold">Términos y Condiciones</h1>

          <div className="max-h-60 overscroll-y-auto overflow-scroll border border-slate-300 p-3 rounded-sm">
          Términos y Condiciones
          Términos y Condiciones de la Apertura de Cuenta y uso de la plataforma IEB+- Convenio entre el/los 
          titulares de las cuentas (en adelante, “EL CLIENTE”) e Invertir en Bolsa S.A. (en adelante, “EL 
          AGENTE”)
          Por medio de la presente, EL CLIENTE solicita a EL AGENTE la apertura de una cuenta que operará 
          bajo los siguientes términos y condiciones que se detallan a continuación. Los siguientes términos y 
          condiciones aplican tanto al acceder a la web de Invertir en Bolsa S.A (“IEB”), como a la aplicación para 
          dispositivos móviles, operando en ambos casos bajo la plataforma denominada IEB+ (“IEB+”):
          I- OBJETO
          EL AGENTE, en su calidad de Agente de Liquidación y Compensación Integral y Agente de Colocación 
          y Distribución de Fondos Comunes de Inversión, proveerá a EL CLIENTE los servicios de 
          intermediación en los mercados de valores autorizados y de colocación de fondos comunes de inversión, 
          junto con todos los demás servicios previstos en la normativa aplicable para el ejercicio de su actividad.
          II- TRATAMIENTO DE DATOS
          Los datos personales brindados en estos Términos y Condiciones y los que A - DATOS PERSONALES
          en el futuro brinde a Invertir en Bolsa S.A son de carácter facultativo y son recogidos para el 
          cumplimiento de la relación contractual. Estos datos, su cotejo, sus datos biométricos y aquellos referidos 
          a su persona que sean obtenidos por vías lícitas, serán incorporados a los registros de Invertir en Bolsa S.
          A y podrán ser objeto de tratamiento por parte de Invertir en Bolsa S.A, las empresas que integren su 
          Grupo Económico. 
          Los datos personales del Comitente recolectados por B - Transferencia internacional de datos personales.
          Invertir en Bolsa S.A podrán ser transferidos para los fines anteriormente descritos a los proveedores de 
          Invertir en Bolsa S.A. las empresas que integren su Grupo Económico y/o sus proveedores, ya sea a 
          países pertenecientes a la Unión Europea o a otros países. Teniendo en cuenta que estos países pueden 
          tener normativa distinta a vigente en Argentina, Invertir en Bolsa S.A. se compromete a implementar las 
          medidas que garanticen un nivel adecuado de protección de los datos personales en las transferencias 
          internacionales. 
          Por medio del presente, el Solicitante en su carácter de titular de los datos Consentimiento Informado.
          presta su consentimiento para que Invertir en Bolsa S.A. en su carácter de cesionario confronte sus datos 
          personales que se indican en párrafo siguiente con la base de datos del Registro Nacional de las Personas 
          (RENAPER) y/o cualquier base de datos pública y/o privada, conforme a las especificaciones que a 
          continuación se detallan. El presente consentimiento para el tratamiento de sus datos personales alcanza a 
          los incluidos en su Documento Nacional de Identidad (incluyendo datos biométricos de huella dactilar y 
          de reconocimiento facial en confronte con lo que informa el web service del RENAPER). Información 
          sobre el tratamiento. Los datos serán tratados con la exclusiva finalidad de validar su identidad y verificar 
          la vigencia de su Documento Nacional de Identidad para los procesos vinculados a la solicitud de 
          Apertura de cuenta y/o cualquier otro referido a la relación comercial que mantenga con Invertir en Bolsa 
          S.A. Los datos confrontados serán almacenados una vez verificada la validez del Documento Nacional de 
          Identidad y validada la misma. Los datos son facilitados con carácter obligatorio, por cuanto es 
          imprescindible identificar fehacientemente al titular, para asegurar el correcto proceso de identificación. 
          El titular de los datos podrá ejercer los derechos de acceso, rectificación y supresión de sus datos en 
          cualquier momento y a su sola solicitud ante el RENAPER y/o Invertir en Bolsa S.A. En cumplimiento 
          de la Resolución AAIP Nº 14/2018, le hacemos saber que la Agencia de Acceso a la Información 
          Pública, en su carácter de Órgano de Control de la Ley N° 25.326, tiene la atribución de atender las 
          denuncias y reclamos que interpongan quienes resulten afectados en sus derechos por incumplimiento de 
          las normas vigentes en materia de protección de datos personales.
          III- CONDICIONES PARTICULARES
          Por medio del presente, el Solicitante en su A - DERECHOS Y OBLIGACIONES DEL AGENTE:
          carácter de titular de los datos presta su consentimiento para que Invertir en Bolsa S.A. en su carácter de 
          cesionario confronte sus datos personales que se indican en párrafo siguiente con la base de datos del 
          Registro Nacional de las Personas (RENAPER) y/o cualquier base de datos pública y/o privada, 
          conforme a las especificaciones que a continuación se detallan. El presente consentimiento para el 
          tratamiento de sus datos personales alcanza a los incluidos en su Documento Nacional de Identidad 
          (incluyendo datos biométricos de huella dactilar y de reconocimiento facial en confronte con lo que 
          informa el web service del RENAPER). Información sobre el tratamiento. Los datos serán tratados con la 
          exclusiva finalidad de validar su identidad y verificar la vigencia de su Documento Nacional de Identidad 
          para los procesos vinculados a la solicitud de Apertura de cuenta y/o cualquier otro referido a la relación 
          comercial que mantenga con Invertir en Bolsa S.A. Los datos confrontados serán almacenados una vez 
          verificada la validez del Documento Nacional de Identidad y validada la misma. Los datos son facilitados 
          con carácter obligatorio, por cuanto es imprescindible identificar fehacientemente al titular, para asegurar 
          el correcto proceso de identificación. El titular de los datos podrá ejercer los derechos de acceso, 
          rectificación y supresión de sus datos en cualquier momento y a su sola solicitud ante el RENAPER y/o 
          Invertir en Bolsa S.A. En cumplimiento de la Resolución AAIP Nº 14/2018, le hacemos saber que la 
          Agencia de Acceso a la Información Pública, en su carácter de Órgano de Control de la Ley N° 25.326, 
          tiene la atribución de atender las denuncias y reclamos que interpongan quienes resulten afectados en sus 
          derechos por incumplimiento de las normas vigentes en materia de protección de datos personales. 
          1- EL CLIENTE acepta y entiende que las B - DERECHOS Y OBLIGACIONES DE EL CLIENTE:
          operaciones están siempre sujetas a riesgos propios del mercado financiero, y pueden tener resultados 
          negativos que signifiquen la pérdida total o parcial del capital y/o la no obtención de sus objetivos de 
          rentabilidad. EL AGENTE no asegura rendimientos de ningún tipo o cuantía. Los riesgos de las 
          Operaciones incluyen enunciativamente la posibilidad de efectos adversos por cambios 
          macroeconómicos (por ejemplo, tasa de interés, nivel general de precios o inflación, devaluación o 
          apreciación del valor de una moneda, recesión), de circunstancias del mercado (por ejemplo, falta de 
          liquidez) o particulares a los activos adquiridos (por ejemplo, disminución o pérdida de solvencia del 
          emisor). También las decisiones de autoridades públicas (nacionales o extranjeras) o de terceros por los 
          cuales la Sociedad no responde ni puede controlar, son susceptibles de afectar adversamente los 
          resultados de las operaciones, aun cuando no constituyan supuestos que califiquen legalmente como 
          fuerza mayor. Especialmente, EL CLIENTE declara comprender y aceptar las circunstancias que 
          implican realizar operaciones con entidades financieras y/o terceros dentro de la República Argentina, y a 
          la reglamentación a la que se está sujeto, aceptando especialmente la eventual posibilidad de que las 
          operaciones puedan o no ser realizadas y/o liquidadas en virtud de la normativa vigente. Como 
          consecuencia de lo indicado, EL CLIENTE entiende y acepta que ni EL AGENTE, ni sus accionistas, 
          directores o sociedades vinculadas garantizan en modo alguno un rendimiento o resultado determinado 
          por su gestión o intervención en la ejecución de las operaciones. Asimismo, EL CLIENTE entiende y 
          acepta que EL AGENTE en ningún caso brinda asesoramiento legal o impositivo sobre las operaciones, 
          2-Las Operaciones podrán realizarse bajo las modalidades de: (i) Instrucciones específicas de EL 
          CLIENTE, las que deberán tener los contenidos mínimos exigidos por las Normas (actualmente: especie
          /instrumento, cantidad, precio o rango de precio, incluida la referencia a “precio de mercado” para 
          operaciones a cursarse por segmentos con interferencia de oferta con prioridad precio tiempo, y/o tasa de 
          rendimiento). La instrucción impartida o la confirmación específica tendrá validez diaria, salvo que las 
          Normas autoricen otro plazo y ello sea reflejado en la instrucción de EL CLIENTE; (ii) Por actuación 
          parcialmente discrecional de EL AGENTE en la administración de la cartera de inversión, cuando EL 
          CLIENTE otorgue un mandato expreso y comparta con EL AGENTE las decisiones de inversión en los 
          términos que allí se indiquen; y (iii) Por actuación totalmente discrecional de EL AGENTE en la 
          administración de la cartera de inversión, cuando EL CLIENTE otorgue un mandato expreso, y en los 
          términos que allí se indiquen, 3- Para los fondos líquidos de la cuenta de EL CLIENTE, se aplicará el 
          siguiente tratamiento: (i) Salvo instrucción expresa en contrario, los saldos líquidos disponibles 
          correspondientes a operaciones vinculadas con instrucciones específicas o con administración 
          parcialmente discrecional de la cartera de EL CLIENTE, podrán ser libremente utilizados y sin devengar 
          interés alguno, por EL AGENTE; o (ii) Cuando los fondos líquidos se vinculen con administración 
          totalmente discrecional de la cartera de EL CLIENTE, y bajo criterios de razonabilidad ostenten un 
          importe significativo, serán invertidos en beneficio de EL CLIENTE o, a requerimiento expreso, ser 
          transferidos a una cuenta bancaria de su titularidad. Será admisible la inversión en Fondos Comunes de 
          Inversión administrados por EL AGENTE; 4- Las operaciones que implican débitos de fondos (Compras, 
          titulares de opciones, suscripciones, cauciones y/o pases colocadores, licitaciones, prorrateo de 
          suscripciones, etc.) serán ejecutadas por EL AGENTE, siempre que existan en la cuenta de EL 
          CLIENTE, fondos necesarios y de libre disponibilidad para abonarlas, 5- EL CLIENTE acepta que para 
          el supuesto de ejecución de saldos deudores autoriza a EL AGENTE a vender los valores negociables 
          depositados a su nombre y/o debitar la suma necesaria de su cuenta, sin necesidad de notificación ni 
          intimación previa, asimismo EL AGENTE tendrá expedita la vía ejecutiva en los términos de lo 
          establecido por el Código de Proc. la Nación Argentina o aquella normativa que lo reemplace 6- Las 
          operaciones que implican créditos (ventas, cauciones y/o pases tomadores, rentas, amortizaciones, etc.) 
          serán ejecutadas por EL AGENTE, siempre que, a la fecha correspondiente, los títulos se encuentren 
          depositados a su nombre en la Caja de Valores S.A. o se trate de una venta en corto, conforme la 
          normativa aplicable a dichas operaciones. En su defecto, y en caso de que EL CLIENTE no hubiese 
          entregado la especie negociada, EL AGENTE queda facultado para la recompra de los títulos faltantes, 
          imputando en la cuenta corriente la diferencia de precios, gastos y comisiones correspondientes, 7- EL 
          CLIENTE reconoce y acepta que EL AGENTE ejecutará las órdenes durante los días y horarios 
          habilitados para el funcionamiento de los mercados locales y del exterior, y dentro de las condiciones de 
          plaza al momento de la efectiva ejecución, 8- Solo podrán realizarse transferencias y/o depósitos 
          bancarios desde/hacia cuentas bancarias pertenecientes a EL CLIENTE. EL AGENTE podrá recuperar 
          todos los gastos bancarios que estos originen mediante débitos en la cuenta, 9- EL CLIENTE declara y 
          garantiza que: (i) tiene plena capacidad para obligarse por la presente y para transferir la propiedad de los 
          activos y realizar las Operaciones, (ii) en caso de ser un ente o persona jurídica, opera válidamente y 
          funciona bajo las leyes que rigen su creación y/o constitución y existencia, (iii) cada documento (sea en 
          soporte físico o digital) probará una obligación válida y vinculante de su parte, exigible de conformidad 
          con sus términos, y no sujeta a repudio o desconocimiento, y (iv) que ha proporcionado y proporcionará 
          en el futuro de manera cabal y completa la información necesaria para que EL AGENTE elabore su perfil 
          de riesgo, lo encuadre o no como inversor calificado y lo identifique en virtud de las normas de 
          prevención del lavado de activos y financiamiento del terrorismo, 10- EL CLIENTE garantiza que 
          resguarda y resguardará adecuadamente las claves o información de acceso para el contacto no presencial 
          con EL AGENTE, haciéndose responsable de cualquier uso no autorizado. Las firmas y/o conformidades, 
          autorizaciones y datos registrados en el convenio de apertura de cuenta se consideran válidos y 
          plenamente vigentes hasta tanto EL AGENTE no haya recibido notificación escrita de la modificación, 
          aun cuando las mismas hayan sido publicadas y/o registradas en los organismos pertinentes. EL 
          CLIENTE se obliga a notificar en forma fehaciente a EL AGENTE toda modificación a los datos 
          aportados al momento de la apertura de la cuenta, 11- EL CLIENTE acepta expresamente que en el caso 
          de otorgamiento de un mandato a favor de un tercero, el ejercicio del mismo, dentro de las facultades 
          conferidas, lo obliga sin restricciones y continuará en su pleno vigor mientras EL AGENTE no haya sido 
          expresa, especial e indubitablemente notificado de su revocación o vencimiento y hubiese acuse de 
          recibo de la notificación. Los mismos requisitos regirán para cualquier modificación que se introduzca a 
          dichos mandatos, 12-EL CLIENTE se hace responsable de informar los cambios de domicilio, los cuales 
          serán gestionados por EL AGENTE ante la Caja de Valores S.A. a modo de evitar el bloqueo de la 
          cuenta. Los cambios de domicilio no informados oportunamente, deberán ser gestionados por EL 
          CLIENTE en forma personal ante la Caja de Valores S.A., con la excepción de que EL CLIENTE haya 
          generado su usuario y clave del Portal Único de Contacto (PUC), en cuyo caso, podrá solicitar que el 
          resumen trimestral se le envíe por correo electrónico constituyendo domicilio en la dirección de correo 
          electrónico declarada en la apertura de la cuenta, 13-EL AGENTE pondrá a disposición de EL CLIENTE 
          a través de la Página Web o en IEB+, salvo requerimiento en contrario de EL CLIENTE para que se le 
          remita por correo electrónico, un estado de cuenta que contenga los requisitos previstos en las Normas de 
          la Comisión Nacional de Valores y los distintos Mercados en los que opera. No será necesario que EL 
          CLIENTE acuse recibo del estado de cuenta, bastando para ello la constancia de EL AGENTE de haberlo 
          puesto a disposición. No obstante, lo enunciado, EL AGENTE enviará al cierre de la jornada las 
          operaciones realizadas a EL CLIENTE, actualizará diariamente los movimientos en la Página Web y en 
          IEB+, 14- Asimismo, mediante la suscripción de la presente, se incluye la aceptación expresa al acuerdo 
          de reciprocidad que Invertir en Bolsa S.A. e IEB S.A. hayan celebrado y/o celebren en el futuro a los 
          fines de compartir toda la documentación que conforma el legajo de EL CLIENTE. 
          1- EL AGENTE abrirá una o más cuentas a nombre C - DEL FUNCIONAMIENTO DE LA CUENTA:
          de EL CLIENTE. En caso de ser las cuentas de titularidad de dos o más personas con orden indistinta, las 
          cantidades de: efectivo, títulos valores, y/o cuotapartes, entre otros valores, que depositen en ella o 
          adquieran cualesquiera de los titulares, podrán ser retiradas indistintamente por cualesquiera de los 
          cotitulares, aun en el caso de fallecimiento o incapacidad sobreviniente de cualesquiera de ellos, siendo 
          suficiente la aceptación/instrucción de uno solo de estos para liberar a EL AGENTE de toda 
          responsabilidad. Igualmente, cada uno de los cotitulares será responsable por los saldos deudores que 
          arroje la cuenta, por cuanto la responsabilidad que surge de dichas cuentas es solidaria con relación a EL 
          AGENTE, 2- A los efectos de las operaciones, las órdenes podrán ser emitidas por los medios que EL 
          AGENTE tenga habilitados al efecto por la Comisión Nacional de Valores. Para el caso que EL 
          AGENTE habilitará el acceso web y/o en la plataforma IEB+ a EL CLIENTE, se aplicará especialmente 
          lo indicado a continuación: (i) EL CLIENTE deberá modificar por primera vez, en forma obligatoria, su 
          clave de acceso, y considerar por razones de seguridad modificaciones periódicas. La clave de acceso 
          será enviada al correo electrónico informado por EL CLIENTE, (ii) EL CLIENTE entiende que es el 
          único responsable por cualquier uso del acceso web y/o la plataforma IEB+, y que el servicio tendrá los 
          términos y condiciones indicados en el sitio web y/o en la plataforma IEB+,, (iii) EL CLIENTE se 
          compromete a comunicar a EL AGENTE acerca de cualquier uso no autorizado de la clave o nombre de 
          usuario, (iv) EL CLIENTE reconoce la importancia, a los fines de mantener la confidencialidad de la 
          clave de acceso, el asegurar el cierre de la sesión, (v) EL CLIENTE entiende que EL AGENTE, sin 
          incurrir en responsabilidad, podrá bloquear el acceso web o a la plataforma IEB+ si considera que existe 
          alguna actividad u operatoria fuera de lo común o que contradiga los términos y condiciones del servicio, 
          (vi) EL CLIENTE entiende que EL AGENTE no será responsable por fallas técnicas del acceso web y/o 
          en la plataforma IEB+, y (v) EL CLIENTE asume plena responsabilidad por cualquier error, 
          intercepción, mala calidad de transmisión, falta de recepción o alteración de la transmisión cursada por 
          modalidades no presenciales, 3- Las Operaciones podrán referirse a cualquier valor negociable o activo 
          financiero habilitado por las Normas, y en lo pertinente, por las reglamentaciones de los mercados en los 
          que actúe la Sociedad (los “Mercados”). A modo de ejemplo, se incluyen acciones, obligaciones 
          negociables, cheques y pagarés negociables en mercados de valores, títulos públicos, fideicomisos 
          financieros, fondos comunes de inversión, derivados, operaciones de préstamo, pase o caución. Para el 
          caso que EL CLIENTE decida realizar operaciones de préstamo de títulos valores, tanto tomador como 
          colocador, opciones, e índices, manifiesta conocer y aceptar la reglamentación vigente para dichas 
          operaciones, 4- EL CLIENTE podrá realizar operaciones de opciones sobre títulos locales, para lo cual 
          declara y manifiesta conocer lo reglamentado para dichas operaciones, 5- EL CLIENTE podrá realizar 
          todo tipo de operaciones bursátiles con Cheques de Pago Diferido, incluyendo compra, venta y depósito 
          en la Caja de Valores S.A. para su posterior negociación en los Mercados autorizados a tal efecto. Para 
          ello, EL CLIENTE declara y manifiesta conocer la reglamentación aplicable y se obliga a acatar la 
          misma y toda otra norma que en el futuro la modifique y/o complemente. Asimismo, acepta que el 
          Mercado Argentino de Valores S.A. y su Directorio poseen potestades exclusivas y absolutas en la 
          aplicación de las Circulares 808 y 820. Habida cuenta de ello, por medio de la presente: (i) Renuncia a 
          toda acción o derecho contra el Mercado Argentino de Valores S.A., sus directores y funcionarios 
          derivada del ejercicio de las facultades regladas en las mencionadas Circulares, (ii) Reconoce, acepta y 
          asume que las operaciones tienen un riesgo propio, ajeno a cualquier conducta del Mercado Argentino de 
          Valores S.A. o del sistema informático que lo instrumenta, (iii) Conoce y acepta el riesgo que las 
          mencionadas operaciones entrañan, especialmente el relacionado al no pago del cheque adquirido por 
          falta de fondos del librador del mismo cuando éste se ha negociado en el segmento no Garantizado, (iv) 
          Decide que todos los cheques que entregue para su negociación bursátil podrán ser negociados según lo 
          previsto en las circulares antes mencionadas en/los segmento/s Garantizado/ Warrants/ Aplicado No 
          Garantizado/ No Garantizado, (v) Acepta y consiente expresamente que sus datos personales entregados 
          en relación a toda negociación bursátil sean almacenados y tratados por EL AGENTE así como también 
          por el Mercado Argentino de Valores S.A., (vi) Deja constancia que acepta las potestades del Mercado 
          Argentino de Valores S.A. y de su Directorio para disponer conforme las previsiones de las circulares 
          modificadas, (vii) Por lo tanto, renuncia a toda acción o derecho contra el Mercado Argentino de Valores 
          S.A. derivado del ejercicio de las facultades regladas en la mencionada normativa. En caso de decidir 
          cambiar lo manifestado anteriormente lo notificará de igual modo en cada caso concreto,(viii) El 
          Comitente, por la presente declara que conoce, entiende y acepta el eventual riesgo que implica la 
          compra de cheques de pago diferido en el segmento no garantizado previsto en el punto b.4) y ñ) de la 
          Circular N° 820 del Mercado Argentino de Valores S.A., ante el no pago del cheque por falta de fondos 
          del librador, (ix) De esta manera, el Comitente declara conocer y entender que los valores así adquiridos 
          en el segmento no garantizado, no cuentan con ninguna garantía y que la única fuente de pago es el 
          librador. Acepta que el Mercado Argentino de Valores SA no requiere dentro del mencionado segmento 
          garantía alguna al vendedor, (x) Consecuentemente, el Comitente comprador de un cheque en el 
          segmento no garantizado, acepta que ante el no pago del cheque por cualquier causa o razón, recibirá de 
          su Agente el cartular o documento emitido por la entidad de custodia o depósito, siendo dicha entrega 
          suficiente para cancelar la operación bursátil registrada en MAV. Realizada la entrega por MAV al 
          Agente comprador o su comitente, nada más tendrán que reclamar al Mercado. La eventual iniciación del 
          juicio correspondiente para el cobro de sus acreencias contra el librador será responsabilidad exclusiva 
          del comitente comprador o quien éste indique, (xi) Por consiguiente, el Comitente declara que ante un 
          incumplimiento nada tiene que reclamar al Mercado Argentino de Valores S.A. y renuncia a toda acción 
          o derecho contra el Mercado Argentino de Valores S.A., sus directores y funcionarios derivada del 
          ejercicio de las facultades regladas en la Circular N° 820 u operación antes mencionada, (xii) El 
          Comitente declara, acepta y consiente expresamente que los datos personales declarados en relación a 
          toda negociación bursátil sean almacenados y tratados por los Agentes así como también por el Mercado 
          Argentino de Valores S.A. y 6- EL AGENTE abonará al agente de custodia que corresponda, los 
          importes que le entregue en el futuro derivados de las suscripciones de las cuotapartes que efectúe EL 
          CLIENTE, y percibirá el importe que corresponda al rescate de las cuotapartes suscriptas e integradas, ya 
          sea en forma parcial o total, depositándose los fondos resultantes en la entidad bancaria en la cual se 
          encuentre abierta la cuenta operativa de EL AGENTE, para que éste proceda a su pago a EL CLIENTE. 
          EL CLIENTE declara haber recibido de EL AGENTE un ejemplar del texto vigente de los reglamentos 
          de gestión de los fondos comunes de inversión alcanzados por la presente. 3 - Operaciones en el Exterior 
          Actuación de IEB. Habilitación de la operatoria (i). Si IEB tuviera un convenio vigente con 
          intermediarios o entidades del exterior en las condiciones previstas por las Normas, podrá, a su sola 
          discreción, habilitar la realización de Operaciones en el exterior (“Operaciones Exterior”). (ii). A las 
          Operaciones Exterior se le aplicarán particularmente estas disposiciones, adicionalmente a lo previsto en 
          las Condiciones Generales. (iii). Las Operaciones Exterior serán la compra y/o venta en el exterior de 
          valores negociables o instrumentos financieros que cuenten con autorización por parte de Comisiones de 
          Valores u organismos similares, que correspondan a países incluidos en el listado previsto en el inciso b) 
          del art. 2 del decreto 589/13, pertenezcan a jurisdicciones que no sean consideradas no cooperantes o de 
          Alto Riesgo por el Grupo de Acción Financiera Internacional y estén autorizados para su 
          comercialización en esas jurisdicciones a inversores acorde su perfil de riesgo. Si las Normas autorizaren 
          otras operaciones, IEB podrá extender las Operaciones Exterior conforme las disposiciones 
          reglamentarias. (iv). Las Operaciones Exterior solo podrán ser habilitadas para quienes sean inversores 
          calificados según la definición de las Normas, o para quienes, al cursar una instrucción o confirmación 
          específica manifiesten de manera particular conocer y aceptar los riesgos específicos de las Operaciones 
          Exterior. 3.1. Riesgos de las Operaciones Exterior (i). En los casos en que la operatoria fuere habilitada 
          por IEB y utilizada por el Cliente, se entenderá el pleno conocimiento del Cliente de la existencia, 
          además de los riesgos generales indicados en los presentes términos y condiciones, de riesgos 
          particulares, que incluyen: Normas legales que pueden ofrecer menor protección que la disponible para 
          las Operaciones en la Argentina, incluyendo la relación contractual del Cliente con el intermediario o 
          entidad del exterior, de la que no será parte IEB. Dificultades y potenciales costos significativos para 
          hacer valer los derechos en países geográficamente lejanos, con idioma, usos y costumbre diferentes a los 
          de la Argentina. 3.2 Riesgos vinculados a la ejecución de las Operaciones Exterior, que son ajenos a IEB. 
          (i). EL CLIENTE ENTIENDE Y ACEPTA QUE IEB NO SERÁ RESPONSABLE POR LA 
          EJECUCIÓN DE LAS OPERACIONES EXTERIOR, NI ESTÁ OBLIGADA A REALIZAR 
          GESTIONES PARA LA DEFENSA DE LOS DERECHOS DEL CLIENTE POR LAS OPERACIONES 
          EXTERIOR. (ii). EL CLIENTE ENTIENDE Y ACEPTA QUE LAS OPERACIONES EXTERIOR NO 
          CUENTAN CON LA GARANTÍA DE IEB, NI DE MERCADOS AUTORIZADOS POR LA CNV, NI 
          LES RESULTAN APLICABLES LAS NORMAS DE TUTELA DE LOS INVERSORES DE LA 
          ARGENTINA. (iii). EL CLIENTE ENTIENDE Y ACEPTA QUE IEB PODRÁ DISCONTINUAR, SIN 
          DERECHO A RECLAMO ALGUNO, LA OPERATORIA EN EL EXTERIOR, CON UN PREAVISO 
          DE 15 DÍAS. 3.3 Información al Cliente (i). IEB cumplirá con el régimen informativo de las Operaciones 
          Exterior que dispongan las Normas (incluyendo la conservación de la documentación respaldatoria 
          pertinente), y registrará las Operaciones Exterior según lo allí establecido. (ii). En el caso que IEB 
          hubiere celebrado un convenio con intermediarios o entidades del exterior que fueren parte de un grupo 
          económico integrado por IEB, dicha circunstancia será notificada mediante el sitio web de IEB, correo 
          electrónico y/o el resumen de cuenta comitente enviado periódicamente al Cliente. 
          Invertir en Bolsa S.D - COMUNICACIÓN, RESUMEN DE CUENTA E INFORMACIÓN GENERAL:
          A. tendrá el estado de su cuenta a disposición en todo momento a través de su Página Web y en la 
          aplicación IEB+. Al darse de alta la cuenta se le proporcionará un usuario y contraseña de acceso al mail 
          declarado al momento de realizar la apertura de cuenta. Queda bajo su exclusiva responsabilidad el uso 
          de la misma, así como su debida activación, siguiendo las instrucciones recibidas en el momento de la 
          apertura. El estado de su cuenta se actualiza diariamente pudiendo verificar en todo momento la 
          ejecución de las operaciones y el resultado de las mismas, con la periodicidad que estime conveniente. 
          Para aquellos casos que opten por la administración discrecional de cartera, a través del mismo acceso 
          podrán visualizar el reporte trimestral de la misma con el detalle de su rendimiento. IEB podrá informar a 
          los Clientes cualquier cuestión relativa al uso de IEB+ y sus efectos, por medio de un aviso general de 
          IEB+, mensaje de correo electrónico a su dirección de correo electrónico registrada en la información del 
          Cliente. 
          EL AGENTE se reserva el derecho de aceptar y/o rechazar a su E - ORIGEN Y LICITUD DE FONDOS:
          solo criterio, la entrega y custodia de determinados fondos y/o títulos valores que considere que pudieran 
          provenir de fuentes no licitas, en cumplimiento de las pautas fijadas por la normativa vigente en la 
          materia y toda otra norma dictada por cualquier autoridad competente que la modifique y/o 
          complemente. EL AGENTE queda facultado para solicitar a EL CLIENTE toda la información que sea 
          necesaria a fin de cumplir con las disposiciones sobre prevención de lavado de dinero proveniente de 
          actividades ilícitas, sobre la base de la normativa aplicable. EL CLIENTE se responsabiliza ante EL 
          AGENTE y los futuros tenedores legítimos de los títulos y las cuotapartes de fondos comunes de 
          inversión, por su origen y por toda oposición, adulteración, falsificación, embargo, gravamen y/o traba de 
          cualquier naturaleza que los afecten, debiendo en su caso proceder a solo requerimiento de EL AGENTE, 
          al reemplazo de los mismos. 
          1- EL AGENTE, se compromete a mantener con carácter confidencial toda F - CONFIDENCIALIDAD:
          la información relacionada con EL CLIENTE, el Portfolio y demás información adquirida como 
          consecuencia de la presente, en tanto que tal información no le haya sido requerida por autoridad 
          competente como la Comisión Nacional de Valores, BYMA, Unidad de Información Financiera, 
          autoridades judiciales o cualquier otro organismo público con facultades para hacerlo, o exista por algún 
          motiva obligación legal de informar, 2- EL CLIENTE es el único responsable por la confidencialidad y el 
          uso de su contraseña para acceder a la cuenta, EL AGENTE no será responsable en ningún caso y bajo 
          ningún concepto por las pérdidas o daños y perjuicios que pudiere sufrir EL CLIENTE, sean estos 
          actuales, potenciales o futuros, provocados por el uso indebido o sin autorización de la cuenta por parte 
          de terceros, 3- EL CLIENTE entiende y acepta que su información personal y de las Operaciones está 
          sujeta a deberes de reporte o información de conformidad con las Normas, u otras regulaciones 
          nacionales e internacionales (incluyendo, enunciativamente, la regulación FATCA -Foreign Account Tax 
          Compliance Act-, y las de implementación del régimen de intercambio de información OCDE). 
          EL AGENTE se reserva el derecho de modificar los términos y condiciones de G - MODIFICACIONES:
          la presente, en cuyo caso notificará mediante comunicación a través de su Página Web o por medio de la 
          plataforma IEB+ a EL CLIENTE para que en el término de treinta (30) días manifieste su conformidad o 
          su decisión de cerrar la cuenta. Si dentro de dicho plazo EL CLIENTE no observa por medio fehaciente 
          las modificaciones, las mismas se considerarán aceptadas y plenamente vigentes. La instrucción de 
          Operaciones posterior a dicha comunicación se considerará una aceptación expresa y definitiva de las 
          modificaciones. 
          EL CLIENTE y/o EL AGENTE de forma unilateral podrán dar por H - DEL CIERRE DE LA CUENTA:
          terminado el acuerdo comercial que los une, con la simple manifestación en ese sentido, mediando 
          notificación escrita en forma fehaciente a la otra parte, a los domicilios electrónicos aquí consignados. En 
          el caso que EL CLIENTE sea quien rescinda unilateralmente el presente, los efectos se producirán a 
          partir de las setenta y dos (72) horas de recibida la notificación por EL AGENTE. Se exceptúa del plazo 
          consignado a aquellas inversiones inmovilizadas o con plazo de vencimiento posterior al mencionado, en 
          cuyo caso los efectos se producirán a partir de las setenta y dos (72) horas de la expiración del plazo de la 
          respectiva inversión. No obstante, lo expuesto, y en cualquier caso, EL CLIENTE podrá solicitar, a su 
          costo y cargo, y de ser materialmente posible, la cancelación anticipada de las inversiones/operaciones 
          pendientes de vencimiento, circunstancia en la cual la rescisión producirá efectos a las setenta y dos (72) 
          horas de ejecutada la cancelación anticipada. En el caso que la recisión sea dispuesta por EL AGENTE, 
          la notificación deberá ser remitida con una antelación no menor a treinta (30) días de la fecha dispuesta 
          para el cese mencionado, período luego del cual la recisión producirá sus efectos. En ambos casos, a 
          partir del momento en que se haga efectiva la terminación de la relación comercial, EL AGENTE deberá 
          poner a disposición de EL CLIENTE y dentro del plazo máximo de veinticuatro (24) horas, los títulos 
          valores, cuotapartes de fondos comunes de inversión y los fondos disponibles en la Cuenta, deducidos los 
          gastos, comisiones y cualquier otra suma adeuda por el CLIENTE. 
          1- EL CLIENTE podrá canalizar cualquier consulta I - JURISDICCIÓN - NORMATIVA APLICABLE:
          o reclamo que posea en el marco del presente al Responsable de Relaciones con el Público designado por 
          EL AGENTE. Él será el encargado de darle curso a la consulta o reclamo e informarle del mismo al 
          Directorio de EL AGENTE. Sin perjuicio de ello, cualquier divergencia o reclamo de EL CLIENTE con 
          relación a EL AGENTE, podrá ser formulado directamente ante la Comisión Nacional de Valores 
          mediante la presentación de una nota en mesa de entradas de esta Entidad, por teléfono o por correo 
          electrónico. El reclamo iniciado ante el mencionado organismo no reemplaza la vía judicial, quedando 
          abierta la posibilidad de realizar el planteo ante la justicia, tanto para EL CLIENTE como para la 
          Comisión Nacional de Valores, 2- Con relación a cualquier acción o procedimiento judicial que surgiera 
          de o en relación con la presente solicitud, EL CLIENTE y EL AGENTE se someten en forma irrevocable 
          a la jurisdicción de los tribunales ordinarios de la Ciudad Autónoma de Buenos Aires, 3- A todos los 
          efectos se entenderá por Normativa Aplicable toda legislación, regulación, reglamentación aplicable en la 
          Argentina y/o en el exterior para la compra, venta y/o celebración de transacciones sobre títulos valores, 
          participaciones o cuotapartes de fondos comunes de inversión, derivados, y/u otros productos financieros, 
          de inversión y/o de mercado de capitales, en especial, por la ley 26.831 y sus normas modificatorias y 
          complementarias, las Normas de la Comisión Nacional de Valores y, en lo que corresponda, a los 
          estatutos, reglamentos, códigos y demás normativa que regule los mercados y/o Cámaras respectivos, 4- 
          EL AGENTE y EL CLIENTE someten su relación, en las partes que corresponda, a la legislación vigente 
          y las que en el futuro se dicten y a las reglamentaciones emanadas de la Comisión Nacional de Valores, 
          los mercados de valores, la Caja de Valores S.A., la Unidad de Información Financiera y demás 
          entidades que regulen en el presente y/o en el futuro la relación objeto de la presente, 5- Toda la 
          normativa que rige la materia, así como las relaciones, derechos y obligaciones de las partes 
          intervinientes podrá encontrarse en los siguientes sitios: CNV: www.cnv.gov.ar , ROFEX: www.rofex.
          com.ar , BYMA: www.byma.- com.ar , Caja de Valores www.cajval.sba.com.ar , MAV http://www.mav-
          sa.com.ar , MAE http://www.mae.- com.ar y Argentina Clearing: www.argentinaclearing.com.ar , 6- La 
          apertura de la Cuenta y la emisión de instrucciones a IEB para la realización de cualquier inversión y/o 
          desinversión implica para el Cliente el conocimiento y la aceptación de todas las normas vigentes 
          relativas a valores negociables y sus derivados, incluyendo toda norma emanada de cualquier autoridad 
          competente, así como los usos y costumbres en los mercados donde IEB ejecute tales operaciones. 
          Los mercados y las cámaras J- FONDO DE GARANTÍA PARA RECLAMOS DE CLIENTES:
          compensadoras en los que el Agente opere deberán constituir Fondos de Garantía destinados a hacer 
          frente a los compromisos no cumplidos por los agentes miembros de dichos mercados o cámaras 
          compensadoras y originados en operaciones garantizadas. A tal efecto, el Agente deberá integrar aportes 
          a los Fondos de Garantía correspondientes, exclusivamente a requerimiento de los mercados. Dichos 
          Fondos de Garantía serán administrados por los mercados o cámaras compensadoras. En caso de 
          incumplimiento del Agente, la formulación de reclamos y la utilización de los Fondos de Garantía 
          (incluyendo, sin limitación, los supuestos que serán atendidos con los Fondos de Garantía, así como el 
          monto máximo a afectar por reclamo y/o por cliente), deberán efectuarse según lo establezca la CNV y la 
          reglamentación que emitan los mercados y las cámaras compensadoras. 
          K - MANDATO DE ADMINISTRACIÓN DE CARTERA A (solo aplica en caso de que el cliente opte 
          1- Por medio del presente, EL CLIENTE constituye una cartera de por la administración de cartera)
          inversiones y otorga a EL AGENTE, un mandato de carácter general, a fin de que, actúe, adoptando las 
          decisiones de inversión, en forma PARCIALMENTE discrecional, en su nombre e interés, gestionando 
          órdenes y/o ejecutando operaciones sin necesidad de requerir orden o instrucción específica e individual 
          o consentimiento previo de EL CLIENTE, y de esta manera, realice la administración del dinero, valores 
          y/o instrumentos financieros que conforman dicha cartera, 2- A efectos de cumplir con el presente, EL 
          CLIENTE otorga amplias facultades a EL AGENTE para que este, actuando en forma directa o a través 
          de terceros, pueda realizar con los valores y activos que componen la cartera de EL CLIENTE, todo tipo 
          de operaciones, incluyendo vender, suscribir o adquirir toda clase de activos al contado, y/o a futuro, 
          realizar operaciones de venta en descubierto, a plazo, de plazo firme, pase, caución y cualquier otra 
          comprendida dentro de la normativa vigente, en especial las Normas de la CNV y de los Mercados 
          autorizados, con acciones, cupones, debentures, letras de tesorería, bonos, títulos, warrants, certificados 
          de participación y cualquier otra clase de título representativo de deuda o títulos valores mobiliarios 
          (incluyendo cupones), públicos o privados, y derivados (incluyendo swaps, opciones, futuros y forwards) 
          sobre activos financieros (incluyendo índices de bolsas o mercados, tasas de interés y monedas o divisas), 
          operaciones de pase y/u otros activos financieros, incluyendo sin limitación cancelación anticipada. En el 
          caso que la recisión sea dispuesta por EL AGENTE, la notificación deberá ser remitida con una 
          antelación no menor a treinta (30) días de la fecha dispuesta para el cese mencionado, período luego del 
          cual la recisión producirá sus efectos. En ambos casos, a partir del momento en que se haga efectiva la 
          terminación de la relación comercial, EL AGENTE deberá poner a Caja de Valores S.A., las Cámaras 
          compensadoras, en su caso, y demás normativa aplicable incluyendo todas aquellas normas que la 
          modifiquen y/o complementen, 3- En el ejercicio de su actividad, EL AGENTE deberá: a) procurar 
          obtener y cobrar las remesas de dividendos, ganancias de capital y/o cualquier otra utilidad o retorno de 
          las inversiones; b) cobrar y pagar, por cuenta y orden de EL CLIENTE, los tributos, tasas, 
          contribuciones, comisiones y/o cualquier otro costo que incida sobre los activos integrantes del portfolio, 
          su negociación o sus respectivos rendimientos y/o sobre la administración que por la presente se solicita; 
          c) proveer toda la documentación relativa a las operaciones realizadas, presentando asimismo 
          información sobre la posición del portfolio; d) percibir a sus vencimientos los dividendos que 
          correspondan a los activos del portfolio, promoviendo la reinversión de los mismos; e) mantener la 
          custodia de los activos integrantes del portfolio en cuentas a nombre de EL CLIENTE en cualquier 
          entidad autorizada y designada al efecto por EL AGENTE. En el ejercicio de su gestión, EL AGENTE, 
          adoptará sus decisiones conforme a las normas técnicas y administrativas adecuadas a las operaciones del 
          mercado bursátil, corriendo por cuenta y riesgo de EL CLIENTE los resultados de todas las operaciones 
          realizadas, 4- EL AGENTE manejará por separado la cartera de EL CLIENTE con honestidad, 
          imparcialidad, profesionalidad, diligencia y lealtad para el mejor interés de EL CLIENTE, de 
          conformidad con lo establecido en las normas aplicables a tal efecto, 5- EL CLIENTE es el único 
          propietario del dinero y los títulos valores que conforman la cartera administrada por EL AGENTE, 6- El 
          portfolio de EL CLIENTE variará en su composición de acuerdo al resultado de las operaciones de 
          inversión que realice EL AGENTE, con los distintos activos que lo componen y con las sumas que se 
          acrediten o debiten del portfolio como resultado de las mismas, incluyendo el pago de impuestos (de 
          corresponder), aranceles, diferencias de precio y las comisiones devengadas por EL AGENTE, así como 
          cualquier otro gasto derivado de las inversiones. En cualquier momento, durante la vigencia del presente, 
          EL CLIENTE podrá incorporar nuevos activos a su portfolio, 7- A los fines del presente, EL AGENTE 
          podrá realizar operaciones y transacciones con entidades o instituciones financieras, bursátiles, agentes 
          de mercado abierto y/o corredores localizados tanto en el País como en el exterior. EL AGENTE podrá 
          solicitar a EL CLIENTE en cualquier momento, todo otro tipo de autorización, permiso, carta poder y/o 
          poderes que a su criterio puedan resultar necesarios para representarlo en cualquier acto relacionado con 
          la administración del portfolio, 8- Los criterios de diversificación de la cartera administrada serán 
          determinados por EL AGENTE, en el ejercicio de sus funciones. Para dicho efecto deberá evaluar el 
          comportamiento del mercado de valores actual y sus perspectivas en el corto, mediano y el largo plazo, la 
          liquidez de los activos, los resultados económico-financieros de los emisores de los valores a ser 
          adquiridos y los que se mantienen en la cartera administrada, las calificaciones de riesgo de los emisores 
          (de ser el caso), y todos aquellos factores que le permitan actuar con la mayor diligencia para el 
          cumplimiento del presente mandato, 9- El presente mandato se considera de plazo indeterminado, sin 
          perjuicio de las adendas, actualizaciones y/o ratificaciones que se puedan o deban realizar en el futuro 
          por modificaciones en la normativa aplicable. Asimismo, el mismo podrá ser resuelto en cualquier 
          momento por EL CLIENTE, notificando fehacientemente su voluntad a EL AGENTE, considerando que 
          para el caso que EL CLIENTE mantenga operaciones o valores que por su naturaleza o vencimiento 
          requieran del cumplimiento de procedimientos especiales de acuerdo con la normativa vigente, deberá 
          dar a EL AGENTE las instrucciones necesarias de como desea proceder con dichos activos. EL 
          AGENTE tiene la obligación de, conforme solicitud de EL CLIENTE, transferir los títulos valores o 
          instrumentos financieros a nombre de EL CLIENTE, o bien liquidar inversiones y entregar el producto en 
          dinero, una vez terminado el presente, 10- EL AGENTE podrá cobrar una remuneración por concepto de 
          administración de cartera de EL CLIENTE, la cual se devengará y calculará con frecuencia mensual y su 
          cobranza podrá realizarse mensualmente, dentro de los cinco (5) días hábiles siguientes del cierre de cada 
          mes, con debito a la cuenta de EL CLIENTE por el servicio prestado. Asimismo, cualquier otro tributo 
          que grave estas remuneraciones también se adicionara sobre las mismas, 11- EL CLIENTE reconoce que 
          EL AGENTE le ha explicado el sistema de remuneración que será aplicado y declara su aceptación. 
          Asimismo, las partes entienden que estas remuneraciones pueden ser modificadas durante la vigencia del 
          presente, en cuyo caso EL CLIENTE deberá manifestar por escrito su consentimiento, 12- EL CLIENTE 
          declara, reconoce y acepta: a) que las operaciones que EL AGENTE realice en virtud del presente 
          conllevan para él la asunción del riesgo económico regulación de los mercados argentinos y extranjeros 
          respecto de las operaciones a realizarse, así como sus riesgos, y e) los aspectos impositivos aplicables a 
          las operaciones que serán efectuadas por su cuenta y riesgo para los distintos productos en los que ha 
          aceptado invertir u operar y que EL AGENTE no es responsable ante y por cualquier variación que surja 
          y provoque una modificación en los rendimientos esperados, 13- EL CLIENTE reconoce como válida 
          cualquier operación ejecutada por EL AGENTE, en su calidad de agente de liquidación y compensación 
          y agente de negociación, agente de colocación y operaciones tendientes a procurar a EL CLIENTE 
          cobertura contra riesgos de oscilaciones en los precios o cotizaciones de los activos, en moneda argentina 
          o extranjera, en entidades o instituciones financieras, bursátiles, agentes de mercado abierto o corredores 
          localizados en la República Argentina o en el exterior, permitidas actualmente o en el futuro por la Ley 
          26.831, las normas de la Comisión Nacional de Valores, la reglamentación de los Mercado de Valores 
          autorizados, la distribución de fondos comunes de inversión, y de agente de administración de fondos 
          comunes de inversión que el mismo realice dentro de las facultades conferidas por el presente, 14- EL 
          AGENTE informará a EL CLIENTE, a través de los medios de comunicación acordados, con 
          periodicidad trimestral dentro de los quince (15) días corridos de finalizado cada trimestre, un reporte de 
          la cartera administrada con el detalle del retorno neto de comisiones, detalle de las comisiones percibidas 
          de terceros y de EL CLIENTE, diferencias de precios, aranceles y demás gastos aplicados. Se presumirá 
          conformidad del informe remitido si dentro de los sesenta (60) días de comunicado, EL CLIENTE no 
          hubiera formulado reclamo alguno a EL AGENTE. La periodicidad de la comunicación de las 
          inversiones u operaciones y sus características, que se acuerdan con EL CLIENTE, podrán modificarse 
          con posterioridad previa notificación, 15- Queda expresamente establecido que el presente mandato no 
          asegura rendimientos de ningún tipo y cuantía, ni tampoco liquidez del portfolio administrado. EL 
          CLIENTE manifiesta tener conocimiento de la naturaleza de las operaciones que realiza y de los riesgos 
          inherentes a cada una de las mismas. Asimismo, declara y asume que las inversiones a ser realizadas 
          están sujetas a múltiples variaciones en sus precios o cotizaciones incluyendo condiciones de mercado, 
          riesgo económico financiero de las emisoras de los títulos, condiciones micro y macroeconómicas locales 
          e internacionales, etc. Consecuentemente, EL CLIENTE asume los riesgos originados en las inversiones 
          que se realicen quedando expresamente aclarado que EL AGENTE no garantiza la solvencia de los 
          deudores bajo las mismas ni el rendimiento de las inversiones, ni responde por la existencia, legitimidad y
          /o evicción debida de los enajenantes de tales inversiones, y declara haber leído, entendido, analizado y 
          aceptado todos y cada uno de los términos del presente, 16- En cualquier momento EL AGENTE podrá 
          modificar lo establecido en cualquiera de los apartados precedentes. En tal caso, antes de que se hagan 
          efectivas tales modificaciones, EL AGENTE deberá notificárselas por escrito en forma fehaciente a EL 
          CLIENTE, a fin de que este, dentro del plazo de treinta (30) días corridos, manifieste su parecer sobre las 
          mismas. Si dentro de dicho plazo EL CLIENTE no observa por escrito las modificaciones realizadas, 
          estas se considerarán aceptadas, 17- Para la validez de todas las comunicaciones y notificaciones, con 
          motivo de la ejecución del presente, las partes señalan como sus domicilios electrónicos, el de EL 
          CLIENTE en la dirección de correo electrónico informada en la apertura de su cuenta con EL AGENTE, 
          de la cual el presente conforma un Anexo, y el de EL AGENTE en la dirección de correo electrónico 
          compliance@ieb.com.ar, 18- El cambio de domicilio de cualquiera de las partes surtirá efecto desde la 
          fecha de comunicación del mismo a la otra parte, por cualquier medio fehaciente, 19- A todos los efectos 
          derivados del presente, EL CLIENTE y EL AGENTE se someten a los Tribunales Ordinarios de la 
          Ciudad Autónoma de Buenos Aires, con expresa renuncia a todo otro fuero o jurisdicción que pudiera 
          corresponderles, constituyendo domicilios especiales en los indicados precedentemente donde se tendrán 
          por validas todas las notificaciones que allí se cursen. Salvo indicación expresa en contrario, todos los 
          plazos se computarán en días hábiles bursátiles. 
          El AGENTE no podrá conceder financiamiento ni otorgar L - PROHIBICIÓN DE FINANCIAMIENTO:
          préstamos al COMITENTE no quedando comprendidos en tal prohibición: a) los contratos de 
          Underwriting celebrados en el marco de colocaciones primarias bajo el régimen de la oferta pública, b) 
          los adelantos transitorios con fondos propios del Agente, a los fines de cubrir eventos de descalce en las 
          liquidaciones de operaciones y demoras en la transferencia de fondos, y/o anticipo de operaciones ya 
          concertadas pero no liquidadas, en la medida que se trate de operaciones realizadas en segmentos 
          garantizados, previo acuerdo con el cliente. En caso de arancelar el saldo deudor, la tasa de interés a 
          aplicar por el Agente -considerando comisiones, tasas y gastos y, transformada a la tasa de interés 
          equivalente, no podrá superar a la fecha de inicio del saldo deudor, la tasa de interés establecida para las 
          operaciones de caución a SIETE (7) días. A tales fines, no se considerará como financiamiento a clientes 
          al saldo deudor originado por comisiones y gastos provenientes de la operatoria.
          </div>
      </div>
      )
}
export default Terms
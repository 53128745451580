

const FormDefault = ({ value, tag, handleFormData }) => {

    return(
        <input
              type="hidden"
              name={tag}
              defaultValue={value}
              onChange={handleFormData(tag)}
          />
    )
    }
    
    export default FormDefault;
import React, { useReducer } from "react";
import axios from "axios";

import PricesReducer from "./PricesReducer";

import { GET_PRICES, GET_MARKET_STATUS } from "../types";

const PricesState = () => {

    const initialState = {
        prices: {},
        marketStatus: false,
    };

    const [state, dispatch] = useReducer(PricesReducer, initialState);

    const getPrices = async () => {
        try {
            const res = await axios.get("/broker/api/md/dollar_bonistas");
            dispatch({ type: GET_PRICES, payload: res.data.prices });
            dispatch({ type: GET_MARKET_STATUS, payload: res.data.market_open });
        } catch (error) {
        }
    };


    return (
        {
            prices: state.prices,
            marketOpen: state.marketStatus,
            getPrices,
        }
    );
};

export default PricesState;
import React, { useState, useReducer, useEffect } from "react";

import FormField from "./FormField"
import FormSelect from "./FormSelect"

import axios from "axios";

// creating functional component ans getting props from app.js and destucturing them
const StepTwo = ({ nextStep, prevStep, handleFormData, values }) => {

  //creating error state for validation
  const [error, setError] = useState(false)

  const validateState = async() => {

    axios({
      method: "POST",
      url:"/broker/api/onboarding_address",
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      },
      data:{
        Calle:values.Calle,
        Numero:values.Numero,
        Piso:values.Piso,
        Depto:values.Depto,
        CodigoPostal:values.CodigoPostal,
        Localidad:values.Localidad,
        Provincia:values.Provincia,
        Pais:values.Pais
      }
    })
    .then((response)=>{
      nextStep();
    })
    .catch((error)=>{
    })



  }

  const submitFormData = async() => {


    if (
      (values.Calle.length > 0) &&
      (values.Numero.length > 0) &&
      (values.Depto.length > 0)&&
      (values.CodigoPostal > 0)&&
      (values.Localidad.length > 0)&&
      (values.Provincia !== null && values.Provincia > -1) &&
      (values.Pais !== null && values.Provincia > -1) // || (values.civil_state) || (values.date_of_birth)
    ) {
        setError(false);
        validateState()
    } else {
      setError(true);
    }
  }

  return (
    
    <div className="relative">
    <div className="border-t-4 border-green-500 text-emerald-700 p-4 " role="alert">
          <h3 className="font-bold">Domicilio y datos de Residencia</h3>

        <p>
          {error ? (<div style={{ color: "red" }}>Todos los campos son requeridos</div>) : ("")}
        </p>
      </div>
        
      <div className="p-4 grid grid-cols-1 " style={{minHeight:"500px"}}>
          <div className="gap-x-6 gap-y-8">
            <FormField title={'Calle'} value={values.Calle} tag='Calle' handleFormData={handleFormData} error={error}/>

            <FormField title={'Número'} value={values.Numero} tag='Numero' handleFormData={handleFormData} error={error}/>

            <FormField title={'Piso'} value={values.Piso} tag='Piso' handleFormData={handleFormData} error={error}/>

            <FormField title={'Departamento'} value={values.Depto} tag='Depto' handleFormData={handleFormData} error={error}/>

            <FormField title={'Código Postal'} value={values.CodigoPostal} tag='CodigoPostal' handleFormData={handleFormData} error={error}/>

            <FormField title={'Localidad'} value={values.Localidad} tag='Localidad' handleFormData={handleFormData} error={error}/>

            <FormSelect 
                title={'Provincia'}
                value={values.Provincia}
                tag={'Provincia'}
                enum_tag={'PROVINCIA'}
                handleFormData={handleFormData}
                error={error}/>

              <FormSelect 
                title={'País'}
                value={values.Pais}
                tag={'Pais'}
                enum_tag={'PAIS'}
                handleFormData={handleFormData}
                error={error}/>

            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6 mr-6">
              
              <button variant="primary" type="submit" className="mt-10 block rounded-md bg-gray-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                onClick={prevStep}
                >
                Regresar  
              </button>

              <button variant="primary" type="submit" className="mt-10 block rounded-md bg-emerald-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
              onClick={submitFormData}
              >
              Confirmar
            </button>

            </div>
        </div>
      </div>
  );
};

export default StepTwo;
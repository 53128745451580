import React, { useState, useEffect, useContext } from "react"

import DFINDissclaimer from './operate/DFINDissclaimer.js'

import HomeRowBuy from './HomeRowBuy.js'
import HomeRowSell from './HomeRowSell.js'

import OnboardingHook from './onboarding/OnboardingHook.js'
import RibbonStats from './fields/RibbonStats.js'
import UserSideBar from '../business/UserSideBar.js'

import Footer from '../business/Footer';

import PricesContext from "../context/Prices/PricesContext.js";
import ProfileContext from "../context/Profile/ProfileContext.js";

import toast, { Toaster } from 'react-hot-toast';


const HomeUser = () => {

    const {userProfile} = useContext(ProfileContext)
    const {prices, marketOpen, getPrices} = useContext(PricesContext)

    const notify = (message) => toast(message);

    const [open, setOpen] = useState(false)

    const [fees, setFees] = useState(userProfile?.fees)

    const [showModal, setShowModal] = useState(false)

    return (
        <div className="relative min-h-screen">
            <div className="relative pt-14 min-h-screen">
                <header className="absolute inset-x-0 top-0 z-50">
                    <UserSideBar  open={open} setOpen={setOpen} />
                </header>
                <div>
                    <Toaster
                        position="top-center"
                        reverseOrder={false}
                    />
                </div>

                {showModal && 
                
                <div onClick={()=>setShowModal(false)} className="min-h-screen  overflow-x-hidden overflow-y-auto fixed inset-0 z-99 outline-none focus:outline-none bg-gray-600/50 backdrop-blur-sm">
                <div className="min-h-screen relative w-auto my-6 mx-auto max-w-3xl flex justify-center items-center content-center">
                    <div className="w-[90%] mx-auto border-0 rounded-lg shadow-lg relative flex flex-col max-w-md p-10 bg-white outline-none focus:outline-none border-lime-300">
                        
                        <div className="mb-5 flex flex-row">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mx-1">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <span>
                            Operamos días hábiles de 11.00 a 16.15hs.
                        </span>
                        </div>
                         
                        <button onClick={()=>setShowModal(false)} className="mt-70 block rounded-md bg-gray-500 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600">Cerrar</button>


                    </div>
                    </div>
                </div>
                
                }

                {userProfile?.has_account === false ?

                    <OnboardingHook />

                    :


                    <RibbonStats pp={userProfile?.pp} />
                }

                <>
                    <div className="w-full mx-auto pt-12 pb-5 bg-gray-50 border-b-2 border-gray-300">
                        <div className="max-w-lg mx-auto flex flex-col justify-between p-5">

                            <div>
                                <h2 className="font-semibold text-green-700 mb-4">Comprar Dólar</h2>
                            </div>

                        { userProfile?.pp &&
                            <div className="w-full grid grid-cols-3 border-solid border-2 border-lime-600 rounded-md">
                                <div className="mr-auto ml-3 flex max-w-xs flex-col ">
                                    <dt className="text-left w-55 leading-7 text-gray-600">
                                        <span className="text-left mr-auto">Saldo</span>
                                    </dt>
                                    <dd className=" text-1xl font-semibold tracking-tight text-gray-900 sm:text-lg">
                                    <span className="text-left mr-auto ">ARS</span>
                                    </dd>
                                </div>

                                <div className="ml-auto flex max-w-xs flex-col pr-2">
                                    <dt className="text-right ml-auto w-55 leading-7 text-gray-600">
                                        <span className="ml-auto" alt={userProfile?.pp.plazo_0}>Inmediato</span>
                                    </dt>
                                    <dd className="text-right  text-1xl ml-auto font-semibold tracking-tight text-gray-900 sm:text-lg">
                                        <span className=" ml-auto">{userProfile?.pp.pesos_0}</span>
                                    </dd>
                                </div>
                                {/* <div  className="mx-auto flex max-w-xs flex-col ">
                                <dd className=" text-1xl font-semibold tracking-tight text-gray-900 sm:text-lg">
                                    <span className="">{userProfile?.pp.pesos_1}</span>
                                </dd>
                                <dt className="text-base w-55 leading-7 text-gray-600">
                                    <span alt={userProfile?.pp.plazo_0}>24hs</span>
                                </dt>
                            </div> */}
                                <div className="ml-auto flex max-w-xs flex-col pr-2">
                                    <dt className="text-right w-55 leading-7 text-gray-600">
                                        <span alt={userProfile?.pp.plazo_0}>48hs</span>
                                    </dt>
                                    <dd className="text-right  text-1xl ml-auto font-semibold tracking-tight text-gray-900 sm:text-lg">
                                        <span className="">{userProfile?.pp.pesos_2}</span>
                                    </dd>
                                </div>
                            </div>
                        }
                            <table className="my-10 text-left text-xs font-normal	">
                                <thead>
                                    <tr>
                                        <th>Bono</th>
                                        <th className="text-right" style={{ minWidth: "110px" }}>Entrega Dólar</th>
                                        <th className="text-right" >Precio estimativo</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(prices).map((key, index) =>
                                    (
                                        <HomeRowBuy  key={key} asset={prices[key]} marketOpen={marketOpen} setShowModal={setShowModal}>
                                        </HomeRowBuy>
                                    )
                                    )}
                                </tbody>

                            </table>

                        </div>

                    </div>


                    <div className="w-full mx-auto pt-12 pb-5 bg-gray-50 border-b-2 border-gray-300">
                        <div className="max-w-lg mx-auto flex flex-col justify-between p-5">

                            <div>
                                <h2 className="font-semibold text-red-700 mb-4">Vender Dólar</h2>
                            </div>

                            { userProfile.pp &&

                            <div className="w-full grid grid-cols-3 border-solid border-2 border-red-600 rounded-md">
                                <div className="mr-auto ml-3 flex max-w-xs flex-col ">
                                    <dt className="text-left w-55 leading-7 text-gray-600">
                                        <span className="text-left mr-auto">Saldo</span>
                                    </dt>
                                    <dd className=" text-1xl font-semibold tracking-tight text-gray-900 sm:text-lg">
                                    <span className="text-left mr-auto ">USD</span>
                                    </dd>
                                </div>
                                <div className="ml-auto flex max-w-xs flex-col pr-2">
                                    <dt className="text-right ml-auto w-55 leading-7 text-gray-600">
                                        <span className="ml-auto" alt={userProfile?.pp.plazo_0}>Inmediato</span>
                                    </dt>
                                    <dd className=" text-1xl ml-auto font-semibold tracking-tight text-gray-900 sm:text-lg">
                                        <span className="text-right  ml-auto">{userProfile?.pp.dolarmep_0}</span>
                                    </dd>
                                </div>
                                {/* <div  className="mx-auto flex max-w-xs flex-col ">
                                <dd className=" text-1xl font-semibold tracking-tight text-gray-900 sm:text-lg">
                                    <span className="">{userProfile?.pp.dolarmep_1}</span>
                                </dd>
                                <dt className="text-base w-55 leading-7 text-gray-600">
                                    <span alt={userProfile?.pp.plazo_0}>24hs</span>
                                </dt>
                            </div> */} 
                                <div className="ml-auto flex max-w-xs flex-col pr-2">
                                    <dt className="text-right ml-auto w-55 leading-7 text-gray-600">
                                        <span alt={userProfile?.pp.plazo_0}>48hs</span>
                                    </dt>
                                    <dd className="text-right  text-1xl ml-auto font-semibold tracking-tight text-gray-900 sm:text-lg">
                                        <span className="text-right ">{userProfile?.pp.dolarmep_2}</span>
                                    </dd>
                                </div>
                            </div>
                            

                            }

                            <table className="my-10 text-left text-xs font-normal">
                                <thead>
                                    <tr>
                                        <th>Bono</th>
                                        <th className="text-right"  style={{ minWidth: "110px" }}>Entrega Pesos</th>
                                        <th className="text-right" >Precio estimativo</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(prices).map((key, index) =>
                                    (
                                        <HomeRowSell key={key} asset={prices[key]} pp={userProfile?.pp} fees={fees} marketOpen={marketOpen} setShowModal={setShowModal}></HomeRowSell>
                                    )
                                    )}
                                </tbody>
                            </table>

                        </div>


                    </div>

                    </>

            </div>
            <Footer logedIn={true} />
        </div>



    )
}

export default HomeUser
const Consent = ({showTerms, setShowTerms}) => {
    return (
      showTerms && 
      <div className="text-left lg:flex lg:flex-col lg:justify-center w-full py-5">
        <h1 className="font-bold">Consentimiento Informado</h1>
        <div className="max-h-60 overscroll-y-auto overflow-scroll border border-slate-300 py-5 rounded-sm">
            <p>
              El presente consentimiento para el tratamiento de mis datos personales alcanza a los incluídos en mi Documento Nacional de Identidad (INCLUYENDO DATOS BIOMÉTRICOS DE HUELLA DACTILAR Y DE RECONOCIMIENTO FACIAL) en confronte con lo que informa el web service del REGISTRO NACIONAL DE LAS PERSONAS.              
            </p>
            <h1 className="font-bold">Datos Autorizados</h1>
            <p>
              Por medio del presente, en mi carácter de TITULAR DE LOS DATOS presto mi CONSENTIMIENTO para que 'IEB' en su carácter de CESIONARIO confronte mis datos personales que se indican en las siguientes pantallas con la base de datos de RENAPER conforme a las ...
            </p> 
        </div>
      </div>
      )
}
export default Consent
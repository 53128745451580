import React, {useState} from "react";
import useToken from '../context/Profile/useToken';

import axios from "axios";

import AccessInput from './fields/AccessInput';
import AccessButton from './fields/AccessButton';
import Logo from '../business/Logo'
import { Link } from 'react-router-dom'


export default function Register() {

  const { saveToken } = useToken();

  const [context, setContext] = useState(false)

  const [registered, setRegistered] = useState(false)

  const [processing, setProcessing] = useState(false)

  const [registerForm, setRegisterForm] = useState({
    'email':'',
    'name':'',
    'last_name':'',
    'cellphone':'',
  })

  const registerMe = async() => {

    setProcessing(true)
    
    axios({
      method: "POST",
      url:"/broker/api/create_user",
      data:{
        ... registerForm
       }
      })
    .then((response) => {

      if (response.data.access_token) {
        saveToken(response.data.access_token)
      }
      setContext(false)
      ////window.location.replace('/useraccount/Home-user');
      // getProfile()
      setRegistered(true)
    }).catch((error)=>{
      setProcessing(null)
      if (error.response.data?.hook) {
        if (error.response?.data?.msg) {
          setContext({
              KIND:'ALERT',
              COLOR:'RED',
              MESSAGE:error.response?.data?.msg
          }
            
        )} 

      }})




  }

  function handleChange(event) { 
    setContext(null)
    const {value, name} = event.target
    setRegisterForm(prevNote => ({
        ...prevNote, [name]: value})
    )}

    return (
      <>
        {/*
          This example requires updating your template:
  
          ```
          <html className="h-full bg-white">
          <body className="h-full">
          ```
        */}
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 lg:px-8 py-5 md:py-12 ">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm  content-center">
            
            <div className="sm:mx-auto sm:w-full sm:max-w-sm  content-center">
              <Link to="/useraccount/">
                <Logo classLogo="text-2xl flex items-center"/>
              </Link>
            </div>

            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Creación de Cuenta
            </h2>
          </div>

          <div className="sm:mx-auto sm:w-full sm:max-w-sm  content-center">
            {context &&
              <p className="my-5">
                <div style={{ color: context.COLOR }}>{context.MESSAGE}</div>
              </p>        
            }
          </div>

          {( !registered? 
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Dirección de Correo
                </label>
                <div className="mt-2">
                    <AccessInput
                      tag={'email'}
                      text={registerForm.email}  
                      ftype={'email'}
                      fieldaction={handleChange} 
                      processing={processing}
                    />
              </div>
              </div>

              <div>
                <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                  Nombre completo
                </label>
                <div className="mt-2">
                  <AccessInput
                      tag={'name'}
                      text={registerForm?.name}  
                      ftype={'text'}
                      fieldaction={handleChange} 
                      processing={processing}
                    />
                </div>
              </div>

              <div>
                <label htmlFor="last_name" className="block text-sm font-medium leading-6 text-gray-900">
                  Apellido
                </label>
                <div className="mt-2">
                    <AccessInput
                      tag={'last_name'}
                      text={registerForm.last_name}  
                      ftype={'text'}
                      fieldaction={handleChange} 
                      processing={processing}
                    />
                </div>
              </div>

              <div>
                <label htmlFor="cellphone" className="block text-sm font-medium leading-6 text-gray-900">
                  Celular
                </label>
                <div className="mt-2">
                    <AccessInput
                      tag={'cellphone'}
                      text={registerForm.cellphone}  
                      ftype={'cellphone'}
                      fieldaction={handleChange} 
                      processing={processing}
                    />
                </div>
              </div>
  
              <div>
                  <AccessButton
                    text={'Registrar'}
                    fieldaction={registerMe}
                    processing={processing}
                    >
                  </AccessButton>
              </div>

              <p className="mt-10 text-center text-sm text-gray-500">
              ¿Ya estás registrado?{' '}
              <Link to="/useraccount/acceso">
                <span className="font-semibold leading-6 text-emerald-600 hover:text-green-500">
                  Acceder
                </span>
              </Link>
            </p>
          </div>
          :
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">

            <h1>Creación de cuenta iniciada</h1>

            <p>Enviamos un email a tu casilla de correo para validar tu email y para que puedas crear tu password.</p>

          </div>
          )}
        </div>
      </>
    )
  }
        
 


const FormField = ({ title, value, tag, handleFormData, error }) => {

return(
    <div className="sm:col-span-4">
    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
      {title}
    </label>
    <div className="mt-2">
      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
        
        <input
          type="text"
          name={tag}
          defaultValue={value}
          value={value}
          onChange={handleFormData(tag)}
          placeholder={title}
          autoComplete={tag}
          className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
          style={{ border: (error && value.length == 0) ? "2px solid red" : "" }}
      />
      </div>
    </div>
    </div>
)
}

export default FormField;